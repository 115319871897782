.admin-assignment {
    padding-top: 5rem;
    padding-left: 5rem;
    padding-right: 5rem;
}

.assignment-list-heading {
    display: flex;    
}

.assignment-list-heading h3 {
    width: 80%;
}

.assignment-list-heading button {
    margin-top: 1.5rem;
    background-image: linear-gradient(to right, #f84f20 0%, #044c74 120%, #C04848 100%);
    cursor: pointer;
    width: 20%;
}