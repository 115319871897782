.profile-select-field {
    width: 200px;
    height: 47px;
    margin-right: 10px;
    padding: 8px 12px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-sizing: border-box;
  }
  
  .profile-select-field::after {
    border-color: var(--tertiary-color);
  }

  .profile-custom-select {
    position: relative;
    display: inline-block;
    width: 200px;
    height: 45px;
    border: 1px solid #ccc;
    margin-right: 20px;
    border-radius: 8px;
  }
  
  .profile-select-header {
    cursor: pointer;
    height: 100%;
    padding: 12px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
  }

  .profile-select-header-disabled{
    opacity: 0.8;
    background-color: #f0f0f0;
  }
  
  .profile-select-header.open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .profile-options-container {
    position: absolute;
    margin-top: 10px;
    max-height: 200px;
    overflow-y: scroll;
    top: 100%;
    left: 0;
    right: 0;
    border-top: none;
    padding: 5px 0;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    background-color: #fff;
    z-index: 2;
  }
  
  .profile-option {
    cursor: pointer;
    padding: 12px 8px;
    background-color: #fff;
  }

  .profile-option:hover {
    background-color: #F9FAFB;
  }
  
  .profile-option:last-child {
    border-bottom: none;
  }
  
  .profile-option.selected {
    background-color: #F9FAFB;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  