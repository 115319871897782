/* <div className="loading">
            <div className="loading-ring"></div>
        </div> */
/* in-btn loading */

.loading {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.loading-ring {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 3px solid #ffffff;
  border-color: #ffffff #ffffff #ffffff transparent;
  animation: loading-ring 0.5s linear infinite;
}

@keyframes loading-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


