.signup-authentication-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 5rem;
    background: #eef6fc;
}

.signup-authentication {
    width: 100%;
    text-align: center;
  }
  
  .signup-Buttons {
    padding-top: 1rem;
    padding-bottom: 1rem;
    background: #eef6fc;
    text-align: center;
    padding-left: 25%;
    padding-right: 25%;
    text-align: center;
  }
  
  .signup-radio-div{
    display: flex;
    margin-top: -1rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  
  .signup-radio-1{
  
    flex: 1;
    text-align: center;
    display: flex;
  }
  
  .signup-radio-1 p{
    color: #12133f;
    margin-right: 1.5rem;
    font-size: 18px;
    font-weight: bold;
  }
  
  .signup-exploring-info-btn{
  
    padding: 0.2rem 0.7rem;
    border: 1px solid #12133f;
    border-radius: 3px;
    margin-left: 0.4rem;
    cursor: pointer;
    font-weight: 200;
    background: #223792;
    color: white;
    text-decoration: none;
   
  }
  
  .signup-mylogin-title{
     margin-top: 0rem;
     /* font-size: 28px;
     color: rgb(48, 48, 119); */
     margin-bottom: 1.5rem;
  }
  
  .signup-authentication form {
    border-radius: 8px;
    margin-left: 23%;
    margin-right: 23%;
    padding-left: 1%;
    padding-right: 2%;
    padding-top: 1%;
    padding-bottom: 2%;
    background: rgba(246, 251, 252, 0.7);
  }
  
  .signup-form-class1 {
    padding: 0 1rem;
   width: 50%;
   text-align: left !important;
  }
  
  .signup-auth-form .form-control {
    width: 100%;
  }
  
  .signup-password-input {
    padding: 12px !important;
  }
  
  .signup-signin-title{
     margin-top: 2rem;
     margin-bottom: 0rem;
  }
  
  .signup-auth-box button {
    width: 12rem;
    align-items: center;
    /* background: linear-gradient(to right, #f84f20 0%, #044c74  120%, #C04848  100%); */
  }
  
  .signup-Buttons {
    display: flex;
  }
  
  .signup-Buttons button{
    width: 14rem;
    align-items: center;
    background: linear-gradient(to right, #f84f20 0%, #044c74  120%, #C04848  100%);
  }
  
  
  @media (max-width: 500px) {
  
  
    .signup-authentication {
      padding-top: 7rem;
      padding-left: 0%;
      padding-right: 0%;
    }
    
    .signup-exploring-info-btn{
    
      padding: 0.2rem 0.7rem;
      border: 1px solid #12133f;
      border-radius: 3px;
      margin-left: 0.4rem;
      cursor: pointer;
      font-weight: 200;
      background: #223792;
      color: white;
      text-decoration: none;
     
    }
    
    .signup-mylogin-title{
       margin-top: 0rem;
       /* font-size: 28px;
       color: rgb(48, 48, 119); */
       margin-bottom: 1.5rem;
    }
    
    .signup-authentication form {
      margin-left: 12%;
      margin-right: 12%;
    }
    
    .signup-form-class1 {
     margin: 1rem;
    }
   
  }
  
  .signup-form-row-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  
  .interview-enroll-custom-select-textlabel{
    font-weight: bold;
    color: #12133f;
    margin-bottom: 1rem;
  }