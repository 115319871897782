.skillbox {
    padding-top: 5rem;
    padding-left: 5rem;
    padding-right: 5rem;
    padding-bottom: 2rem;
    background: #eef6fc;
}

.main-heading{
    font-weight: 600;
    font-size: 24px;
    font-style: italic;
    /* transform: skew(-10deg); */
    background-image: linear-gradient(to right, #f84f20 0%, #044c74  120%, #C04848  100%);
    color: #fff;
    padding: 10px;
    line-height: 1.7em;
    box-shadow: 0 2px 10px 0 #cac9c9;
    text-align: left;
    user-select: none;
    cursor: pointer;
    border-left: 20px solid #044c74;
    /* max-width: fit-content; */
}

.courses-grid{
    margin-top: 2rem;
    display: grid;
    grid-template-rows: repeat(1, 16.3rem);
    grid-template-columns: repeat(auto-fit, minmax(11rem, 17rem));
    grid-row-gap: 4rem;
    grid-column-gap: 5rem;
    grid-auto-rows: 16.3rem;
    padding-bottom: 4rem;
}

.grid-div{
    border-radius: 6px;
    cursor: pointer;
    box-shadow: rgba(29, 23, 23, 0.35) 0px 5px 15px;
}

.grid-div h3{
    margin-left: 5%;
    margin-right: 2%;
    font-size: 17px;
    margin-bottom: 0rem;
    font-weight: 500;
}

.grid-div p{
    /* margin-left: 5%;
    margin-right: 2%; */
    margin-top: 10%;
    text-align: center;
    font-size: 15px;
    font-weight: 500;
    margin-top: 0.4rem;
    color: #044c74;
}

.grid-card-img{
    width: 100%;
    height: 9rem;
}

.grid-card-img img{
    width: 100%;
    border-radius: 6px 6px 0px 0px;
    height: 100%;
}
