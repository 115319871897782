.sk-st-cl-video {
  width: 100%;
  height: 100%;
  /* padding: 20px; */
  box-sizing: border-box;
}

.sk-st-cl-video-box {
  overflow: hidden;
}

.sk-st-cl-module-flex2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding: 20px; */
  /* background-color: #f5f5f5; */
}

.sk-st-cl-video-player-div {
  position: relative;
  width: 100%;
  padding-top: 56.25%; 
  overflow: hidden;
}

.sk-st-cl-react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.sk-st-cl-play-button-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.sk-st-cl-play-button {
  background-color: #3498db;
  color: #fff;
  font-size: 24px; 
  padding: 10px;
  cursor: pointer;
  border: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
