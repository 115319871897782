.loading-interview-question-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px;
}

.loading-interview-question-video-container {
  margin-bottom: 20px;
  max-width: 500px;
}

.loading-interview-question-video {
  width: 300px;
}

.loading-interview-question-subheading {
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  color: var(--primary-color);
}
