.popup {
  position: fixed;
  z-index: 11;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup__content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

.popup__content h2 {
  margin-top: 0;
}

.popup__content p {
  margin-bottom: 20px;
}

.close-btn {
  position: relative;
  float: right;
  top: -60px;
  cursor: pointer;
}

.popup__content input,.popup__content textarea{
  width: 90%;
}

.popup__content label{
  display: block;
  margin-top: 20px;
}

.popup__content button{
  margin-top: 20px;
}