.sk-interview-instructions {
    /* padding: 24px;
    max-width: 800px;
    margin: 0 auto; */
    width: 100%;
    /* padding: 16px; */
    border: 1px solid #e0e0e0;
    border-radius: 8px;
}

.sk-interview-instructions h2 {
    color: var(--primary-color);
    text-align: center;
    font-size: 20px;
    padding-bottom: 16px;
    border-bottom: 1px solid #e0e0e0;
    margin: 16px;
}

.sk-interview-instructions-list {
    max-height: 400px;
    overflow-y: auto;
    margin-bottom: 24px;
    padding: 0 24px;
}

.sk-interview-instruction-item {
    display: flex;
    margin-bottom: 16px;
    line-height: 1.5;
}

.sk-interview-instruction-number {
    color: var(--primary-color);
    font-weight: bold;
    margin-right: 8px;
    min-width: 24px;
}

.sk-interview-instruction-text {
    color: #333;
}

.sk-interview-instructions-checkbox {
    margin: 24px 0;
    text-align: center;
}

.sk-interview-instructions-checkbox label {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    cursor: pointer;
}

.sk-interview-instructions-checkbox input[type="checkbox"] {
    width: 18px;
    height: 18px;
    cursor: pointer;
}

.sk-interview-instructions-buttons {
    display: flex;
    justify-content: center;
    gap: 16px;
    margin: 24px 0;
    padding: 0 16px;
}

.sk-interview-instructions-button {
    padding: 10px 24px;
    margin: 0;
    border-radius: 5px;
    border: none;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    font-family: inherit;
}

.sk-interview-instructions-button.primary {
    background-color: #FA1B00;
    color: white;
}

.sk-interview-instructions-button.primary:disabled {
    background-color: #fa190087;
    cursor: not-allowed;
}

.sk-interview-instructions-button.primary:not(:disabled):hover {
    background-color: #ab1100;
}

.sk-interview-instructions-button.secondary {
    background-color: #e0e0e0;
    color: #333;
}

.sk-interview-instructions-button.secondary:hover {
    background-color: #d5d5d5;
}
