  .form-class1 {
   margin-top: 1rem;
   margin-bottom: 2rem;
    margin-right: 7%;
    margin-left: 5%;
  }

  .adduser-container{
    margin-top: 2rem;
    width: 50%;
    margin-left: 25%;
  }
  
  @media (max-width: 500px) {    
    .form-class1 {
     margin-top: 1rem;
     margin-bottom: 2rem;
      margin-right: 7%;
      margin-left: 5%;
    }
   
  }
  
  .users {
    padding-top: 5rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  
  .users-heading {
    margin-left: 2rem;
    display: flex;
    /* height: 50px; */
  }
  
  .users-heading h3 {
    text-align: left;
    width: 80%;
  }
  
  .users-heading a {
    margin-top: 1rem;
    width: 20%;
  }
  
  .users-heading a button {
    background-image: linear-gradient(to right, #f84f20 0%, #044c74 120%, #C04848 100%);
    cursor: pointer;
  }
  
  .users-back{
    text-decoration: none;
  }

  .module-table{
    width: 100%;
  }