.practicecontent-page {
  padding-top: 5rem;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 2rem;
  background: #eef6fc;
}

.practicecontent-page h3{
  color: #12123a;
  font-size: 28px;
}

.theory-outer{
    
   padding-top: 2rem;
}

.theory-topic-heading{
    font-size:36px;
    font-weight:bold;
    color: #241f55;
    margin-bottom:3%;
}

.theory-topic-para{
    font-size:19px;
    font-weight:400;
    color: #252422;
    margin-bottom:4%;
    
}

.subTopics-content{
    margin-bottom:4%;
}


.subTopics-heading{
    font-size:28px;
    font-weight:600;
    color: #040405;
    margin-bottom:3%;
}


.subTopics-para{
    
    margin-left: -2rem;
    font-size: 28px;
    
    
}

.th-text-data{
    margin-bottom: 2%;
    line-height: 1.2;
    
    font-weight: 400;
    color: #1D1D34;
}

.th-text-points{
    margin-left:2%;
   
    
}

.th-text-para{
    font-size: 20px !important;
    
}

.mjx-chtml{
    font-size: 20px !important;
    font-weight: 400 !important;
    color: #1D1D34 !important;
    margin-bottom: 1%;;
}

.th-text-normal .th-text-para{
    list-style: none;
}

.practice-test h1{
    border-bottom: solid 8px;
    /* inline-size: fit-content; */
    margin-left: 7%;
    margin-right: 7%;
    margin-bottom: 4%;
}

.option-design{
    /* font-family: Arial, Helvetica, sans-serif !important; */
    font-size: 30px;
}

.practice-test{
    font-family: MJXc-TeX-math-I,MJXc-TeX-math-Ix,MJXc-TeX-math-Iw !important;
}

.design-newr{
    font-family: MJXc-TeX-math-I,MJXc-TeX-math-Ix,MJXc-TeX-math-Iw !important;
    font-size: 25px;
}

.question-box{
    box-shadow: none;
    width: 100%;
    margin-left: -1rem;
    background-color: transparent;
}

.ques-no {
    display: flex;
    flex-direction: row;
    
   
}

.ques-no temp{
    /* color: #034B71; */
    color: #1f1f4e;
    font-size: 25px;
    
}

.ques-text{
    /* color: #5a7784; */
    color: #171744;
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 2%;
}

.ques-options{
    margin-left:2%;
}



.optionValue{
    color: #f34213;
    font-size: 18px;
    line-height: 2.8;
    font-weight: 600;
}

.option-text{
    margin-left:3%;
    color: #F5653D;
    font-weight: 500;
}

.open-answer{
    margin-top:3%;
}

.button-style-css{
    padding: 4px 10px;
    font-size: 16px;
    font-weight: bolder;
    /* border: solid 0.01px transparent; */
    border-radius: 6px;
    /* background: #034B71; */
    background: white;
    border-color: rgb(219, 82, 19);
    color: orangered;
    margin-top:2%;
    margin-left:1.6%;
    cursor:pointer;
}





.options-answer-lines{
    margin-left:1.5%;
    margin-top:1%;
    margin-bottom: 0%;
}

.answer-line{
    color: #2E2E3A;
    font-size: 18px;
    /* margin-left:7.5%; */
    line-height: 2.5;
    /* margin-bottom: 2%; */
}

.answer-text{
    margin-top:0%;
    margin-bottom: 2%;
}

.correct-option{
    color: #034b71;
    font-size: 18px;
    font-weight:700;
    /* margin-left:7.5%; */
    line-height: 2;
    /* padding:2%; */
    border:solid 5px #034b71;
    border-radius:5px;
    inline-size: fit-content;
    padding-left: 1%;
    padding-right: 1%;
    margin-bottom: 3%;
    margin-top: 5%;
}

