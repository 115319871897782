.modal {
  z-index: 100;
  position: fixed;
  top: 22vh;
  left: 10%;
  width: 80%;
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 8px;
}

.modal__header {
  width: auto;
  padding: 1rem 0.5rem;
  background: white;
  border-radius: 8px 8px 0px 0px;
}

.modal__header h2 {
  margin: 0.08rem;
  text-align: center;
  color:white;
  font-weight: 500;
  font-size: 20px;
}

.modal__content {
  padding: 2%;
}

.modal__footer {
  padding: 2%;
  text-align: center;
}

.modal__footer button {
  background: linear-gradient(
    to right,
    red,
    rgba(247, 90, 17, 0.904),
    rgb(238, 130, 8)
  );
  border-radius: 4px;
  border-color: transparent;
  font-weight: bold;
  color: white;
}

@media (min-width: 768px) {
  .modal {
    left: calc(50% - 20rem);
    width: 40rem;
  }
}

.modal-enter {
  transform: translateY(-10rem);
  opacity: 0;
}

.modal-enter-active {
  transform: translateY(0);
  opacity: 1;
  transition: all 200ms;
}

.modal-exit {
  transform: translateY(0);
  opacity: 1;
}

.modal-exit-active {
  transform: translateY(-10rem);
  opacity: 0;
  transition: all 200ms;
}
