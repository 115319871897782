.custom-pathway-container {
    background-color: #f5f5f5;
}

.custom-pathway-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.pathway-info {
    padding-right: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 70%;
}

.pathway-info h1 {
    margin-bottom: 10px;
}

.pathway-image img {
    max-width: 350px;
    height: auto;
    margin: 40px 0 0 0;
    padding-left: 10px;
}

.modules-section {
    display: flex;
    flex-direction: column;
    width: 70%;
}

.modules-section h2 {
    margin-bottom: 10px;
}

ul {
    list-style: none;
    padding: 0;
    width: 100%;
}

li {
    margin-bottom: 8px;
    display: flex;
    align-items: center;
}

.module-lock-icon {
    margin-right: 8px;
}

/* .module-number {
    margin-right: 8px;
}

.module-list{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.module-name {
    width: 70%;
}

.module-button {
    width: 30%;
} */


.module-number {
    font-size: 1.2em; 
    font-weight: bold;
    color: #333; 
    margin-right: 8px;
    margin-bottom: 8px;
}

.module-list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #dcdbdb;
    padding: 0 20px;
    margin-bottom: 20px;
    border-radius: 8px;
}

.module-name {
    width: 100%;
    padding: 12px;
    font-size: 1.2em;
    color: #333;
    margin-bottom: 8px; 
}

.module-button {
    width: 30%;
    padding: 8px;
    background-color: #4CAF50; 
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.module-button:hover {
    background-color: #45a049;
}
