.modal-primary-c {
    position: fixed;
    display: flex;
    padding: 20px 10px;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999999;
    background: rgba(0, 0, 0, 0.2);
    overflow: auto;
  }
  
  .modal-primary1 {
    display: flex;
    flex-direction: column;
    min-height: 100px;
    height: fit-content;
    flex: 1;
    box-shadow: rgba(83, 74, 74, 0.35) 0px 5px 15px;
    padding: 28px 40px;
    border-radius: 15px;
    background: white;
    position: relative;
    max-width: 500px;
  }
  
  .search-modal1,
  .user-role-modal1 {
    position: relative;
    top: 80px;
  }
  
  .search-modal1 h1 {
    margin-left: 6%;
    color: black;
    text-align: left;
    font-size: 30px;
  }
  
  .form-live-class{
    
    margin-top: -0.8rem;
    display: flex;
    flex-direction: column;
    
  }

  .search-modal1 p{
    margin-top: -0.5rem;
    font-size: 20px;
    margin-left: 6%;
    margin-right: 6%;
  }

  

  .curriculum-form-input-div{
  
    margin-bottom: -1rem;
    margin-left: 2rem;
    margin-right: 3rem;
  }

  .curriculum-form-input-div input{
    margin-bottom: 1rem;
  }

  .curriculum-form-input-div label{
    margin-left: 0.7rem;
  }

  .form-div-demo{
    margin-top: 1rem;
  }
  
  
  .form-live-class button {
    margin-top: 1.5rem;
    align-self: center;
    padding: 6px;
    
    padding-left: 26px;
    padding-right: 26px;
    font-size: 16px;
    font-weight: bolder;
    border-radius: 5px;
    background: linear-gradient(
      to right,
      red,
      rgba(247, 90, 17, 0.904),
      rgb(238, 130, 8)
    );
    /* background: url('../../assets/1040275_dc43a.gif'); */
    /* background: url('../../assets/tenor.gif'); */
    /* background: url('../../assets/teeenor.gif'); */
    /* background-color: #f15c22; */
    border-color: transparent;
    color: white;
  }


  
 
  
  .modal-close-button1 {
    position: absolute;
    right: 20px;
    top: 15px;
    background:white;
    color: orangered;
    padding: 10px;
    z-index: 2;
    border: none;
  }
  
  .modal-close-button1 i {
    font-size: 2.2em;
    color: black;
    margin: 0px;
  }
  

@media (max-width:450px){

  .modal-primary-c {
    position: fixed;
    display: flex;
    padding: 20px 10px;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999999;
    background: rgba(0, 0, 0, 0.2);
    overflow: auto;
  }
  
  .modal-primary1 {
    display: flex;
    flex-direction: column;
    min-height: 100px;
    height: fit-content;
    /* flex: 0.65; */
    /* margin-left: -4.3rem; */
    box-shadow: rgba(83, 74, 74, 0.35) 0px 5px 15px;
    padding: 28px 40px;
    border-radius: 15px;
    background: white;
    position: relative;
    max-width: 500px;
  }
  
  .search-modal1,
  .user-role-modal1 {
    position: relative;
    top: 76px;
  }
  
  .search-modal1 h1 {
    margin-left: 6%;
    color: black;
    text-align: left;
    font-size: 24px;
  }
  
  .form-live-class{
    margin-left: -1rem;
    margin-top: -0.8rem;
    display: flex;
    flex-direction: column;
    
  }

  .search-modal1 p{
    margin-top: -0.5rem;
    font-size: 20px;
    margin-left: 6%;
    margin-right: 6%;
  }

  

  .curriculum-form-input-div{
  
    margin-bottom: -1rem;
    margin-left: 2rem;
    margin-right: 3rem;
  }

  .curriculum-form-input-div input{
    margin-bottom: 1rem;
  }

  .curriculum-form-input-div label{
    margin-left: 0.7rem;
  }

  .form-div-demo{
    margin-top: 1rem;
  }
  
  
  .form-live-class button {
    margin-top: 1.5rem;
    margin-left: 2rem;
    align-self: center;
    padding: 6px;
    padding-left: 26px;
    padding-right: 26px;
    font-size: 16px;
    font-weight: bolder;
    border-radius: 5px;
    background: linear-gradient(
      to right,
      red,
      rgba(247, 90, 17, 0.904),
      rgb(238, 130, 8)
    );
    /* background: url('../../assets/1040275_dc43a.gif'); */
    /* background: url('../../assets/tenor.gif'); */
    /* background: url('../../assets/teeenor.gif'); */
    /* background-color: #f15c22; */
    border-color: transparent;
    color: white;
  }


  
 
  
  .modal-close-button1 {
    position: absolute;
    right: 20px;
    top: 15px;
    background:white;
    color: orangered;
    padding: 10px;
    z-index: 2;
    border: none;
  }
  
  .modal-close-button1 i {
    font-size: 2.2em;
    color: black;
    margin: 0px;
  }
  

}