.sk-st-asgnmt_popup-content{
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.sk-st-asgnmt_popup-content-row{
    width: 100%;
    margin-bottom: 10px;
}

.sk-st-asgnmt_popup-content-row-btn{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 10px 0;
}

.sk-st-asgnmt_popup-content-row-btn-dupcn{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: 10px 0;
    gap: 10px;
}

.sk-st-asgnmt_popup-content-row-label{
    color : var(--primary-color);
    font-size: 16px;
    font-weight: bolder;
}

.sk-st-asgnmt_popup-content-row-sp{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-top: 10px;
}

.sk-st-asgnmt_popup-content-row-sp-fl-inp {
    width: 98%;
    cursor: pointer;
    background-color: #ffffff; 
    border-radius: 5px; 
    height: 45px;
    padding: 10px;
    border: 1px solid #dcdcdc;
    box-sizing: border-box;
}

.sk-st-asgnmt_popup-content-row-clear-crs-icn{
    cursor: pointer;
    color: var(--primary-color);
    padding: 5px;
}