.match-score-section {
    border: 1px solid rgba(108, 106, 106, 0.76);
    border-radius: 4px;
    max-height: 250px;
}

.match-list-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    max-height: 120px;
    overflow-y: scroll;
    margin-right: 10px;
}

.match-list {
  display: flex;
  flex-direction: column;
  max-width: 300px;
}

.match-list-item {
  padding: 0px;
}
.match-list-item-value {
  font-size: 16px;
  border-radius: 6px;
  font-weight: 500;
  padding: 0px;
}

.match-title {
    text-align: center;
    font-size: 22px;
    padding: 12px 0;
    font-weight: 700;
    color: rgba(3, 5, 51, 0.785);
}

