.delete_module_popup__content {
  padding: 20px;
  text-align: center;
}

.delete_module_popup__content-row {
  margin-bottom: 15px;
}

.delete_module_popup__content-row-title {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 10px;
}

.delete_module_popup__content-row-value {
  padding: 10px 20px;
  cursor: pointer;
  margin: 0 10px;
}

.delete_module_popup__buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.delete_module_popup__buttons-cancel {
  background-color: #f84f20;
  color: #fff;
  border: none;
  margin-left: 0;
}

.delete_module_popup__buttons_delete {
  background-color: #233f58;
  color: #fff;
  border: none;
}

.delete_module_popup__loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}