.stu-module-cert-card {
    background: white;
    padding: 24px;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0,0,0,0.08);
    transition: all 0.3s ease;
    border: 1px solid #eee;
    max-height: 200px;
}

.stu-module-cert-card:hover {
    transform: translateY(-4px);
    box-shadow: 0 8px 24px rgba(0,0,0,0.12);
}

.stu-module-cert-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.stu-module-cert-header {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
}

.stu-module-cert-title {
    font-size: 18px;
    font-weight: 600;
    color: var(--primary-color);
    flex: 1;
    text-overflow: ellipsis;
    min-height: 26px;
    max-height: 26px;
    overflow: hidden;
}

.stu-module-cert-status {
    display: inline-flex;
    align-items: center;
    padding: 8px 16px;
    border-radius: 24px;
    font-size: 14px;
    font-weight: 500;
    white-space: nowrap;
    transition: all 0.2s ease;
}

.stu-module-cert-status:hover {
    transform: scale(1.05);
}

.stu-module-cert-status.completed {
    background: #e8f5e9;
    color: #2e7d32;
}

.stu-module-cert-status.pending {
    background: #fef6e7;
    color: #b45309;
}

.stu-module-cert-view-btn {
    width: 100%;
}

.stu-module-cert-card.completed {
    border-color: #2e7d32;
}

.stu-module-cert-card.pending {
    border-color: #b45309;
}

.status-icon {
    margin-right: 6px;
    font-size: 16px;
}

.cert-icon {
    margin-right: 8px;
    font-size: 18px;
}

.stu-module-cert-instructions-text {
    font-size: 14px;
    color: #666;
    text-align: center;
}