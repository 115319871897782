.referral-milestones-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 20px;
}

.referral-milestone-step-header {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0px;
}

.referral-milestone-descriptions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin: 10px 0px;
}

.referral-milestone {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.referral-milestone-step {
  font-size: 16px;
  width: 33%;
  color: #777777;
  font-weight: 600;
  text-align: center;
}

.referral-milestone-description {
  margin-top: 10px;
  width: 33%;
  padding: 0 20px;
  font-size: 16px;
  text-align: center;
  color: var(--primary-color);
}

.referral-milestone-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 70%;
  height: 1px;
  background-color: var(--primary-color);
  margin: 10px 20px;
  position: relative;
}

.referral-milestone-circle {
  position: relative;
  top: -2px;
}

.referral-milestone-circle-inner {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: var(--primary-color);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.referral-milestone-circle-outer {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid var(--primary-color);
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
