/* TextArea.css */

.profile-textarea-component-container {
    display: flex;
    flex-direction: column;
  }

  .profile-textarea-field-container-outer{
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  
  .profile-textarea-field-label {
    font-size: 14px;
    color: #434343;
    margin-bottom: 7px;
    font-weight: medium;  
    }
  
  .profile-textarea-field-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;  
    width: 97%;
}
  
  .profile-textarea-field {
    box-sizing: border-box;
    font-size: 14px;
    resize: vertical;
    padding: 8px 12px;
    border-radius: 8px;
    border: 1px solid #ccc;
  }
  
  .profile-textarea-field-err{
    border-color: red !important;
  }

  .profile-textarea-err-desc{
    color: red;
    font-size: 12px;
    margin-top: 0;
  }

  .profile-textarea-char-count-container{
    display: flex;
    justify-content: flex-end;
    padding: 0px 5px;
    font-size: 12px;
    color: #888;
  }
  
  .profile-textarea-char-count-and-error-container {
    display: flex;
    justify-content: space-between;
    padding: 0px 5px;
    font-size: 12px;
    color: #888;
  }

  .profile-textarea-char-count-err{
    color: red;
  }
  