.popup {
  position: fixed;
  z-index: 11;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup__content {
  background-image: linear-gradient(to right, #ee9880 0%, #044c74  120%, #ee9880  100%);
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  width: 50%;
}

.popup__content h2 {
  margin-top: 0;
}

.popup__content p {
  margin-bottom: 20px;
}

.close-btn {
  position: relative;
  float: right;
  top: -60px;
  cursor: pointer;
}

.close-btn-zoom {
  position: relative;
  float: right;
  top: -260px;
  cursor: pointer;
  background: #c73c3c;
}

.popup__content input,
.popup__content textarea {
  width: 90%;
}

.popup__content label {
  display: block;
  margin-top: 20px;
}

.popup__content button {
  margin-top: 2px;
  margin-left: 28%;
  width: 7.5rem;
  height: fit-content;
  background-image: linear-gradient(to right, #f84f20 0%, #044c74  120%, #C04848  100%);
}

.ticker-header {
  display: flex;
}

.popup__content p {
  margin-top: 8px;
  margin-left: 20%;
  /* width: 7.5rem; */
  /* background-image: linear-gradient(to right, #f84f20 0%, #044c74  120%, #C04848  100%); */
}

.message__sender {
  display: flex;
  flex-direction: row-reverse;
  margin-top:10px;
}

.message__sender__text {
  background-color: #ee9880;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
  color: black;
  max-width: 300px;
}

.message__sender p {
  margin: 0;
}

.message__receiver {
  display: flex;
  flex-direction: row;
  margin-top:10px;
}

.message__receiver__text {
  background-color: #3989b4;
  padding: 10px;
  border-radius: 10px;
  padding-bottom: 10px;
  max-width: 300px;
}

.message__content {
  margin: 0;
}

.message__receiver p {
  margin: 0;
}

.message__content__text {
  margin-bottom: 5px;
}

.message__content__time {
  margin: 0;
  font-size: 10px;
  color: black
}

.message-list{
  height: 280px;
  overflow-y: scroll;
}

img.queryImg {
  max-width: 90%;
  border: 5px solid #dedede;
}
