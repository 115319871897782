.admin-skillbox-assignments-program-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  max-height: 220px;
}

.admin-skillbox-assignments-program-card__title {
  font-size: 20px;
  color: #004C73;
  font-weight: bold;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
  width: 100%;
  height: 40px;
  margin-bottom: 10px;
}

.admin-skillbox-assignments-program-card__description {
  font-size: 16px;
  color: #004C73;
  font-weight: bold;
  margin: 20px 0 10px 0;
}

.admin-skillbox-assignments-program-card__tag{
  margin-top: 10px;
}

.admin-skillbox-assignments-program-card__button {
  font-family: inherit;
  font-weight: bold;
  margin: 0;
  margin-top: 10px;
  color: #fff;
  background-color: #004C73;
  border: none;
  border-radius: 5px;
  padding: 10px 10px;
  cursor: pointer;
  width: 50%;
  margin: 10px 0;
}

.admin-skillbox-assignments-program-card__button:hover {
  background-color: #003A54;
}

