.lc-container {
  background: #fff;
}

.lc-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.lc-header-title {
  font-size: 24px;
  font-weight: 600;
  color: #004c73;
}

.lc-body {
  background: #fff;
}

.lc-loading {
  display: flex;
  justify-content: center;
  padding: 40px;
  color: #004c73;
}

.lc-not-found {
  text-align: center;
  padding: 40px;
  color: #666;
  font-size: 16px;
}

.lc-classes-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  max-height: calc(100vh - 230px);
  overflow-y: auto;
  padding-right: 10px;
}

/* Custom scrollbar styling */
.lc-classes-grid::-webkit-scrollbar {
  width: 8px;
}

.lc-classes-grid::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.lc-classes-grid::-webkit-scrollbar-thumb {
  background: #6b7280;
  border-radius: 4px;
}

.lc-classes-grid::-webkit-scrollbar-thumb:hover {
  background: #004c73;
}

.lc-class-card {
  width: 100%;
  background: #f8fafc;
  border-radius: 8px;
  border: 1px solid #e5e7eb;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.lc-card-header {
  padding: 16px;
  border-bottom: 1px solid #e5e7eb;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.lc-header-content {
  flex: 1;
}

.lc-live-indicator {
  display: flex;
  align-items: center;
  gap: 6px;
  background: rgba(220, 38, 38, 0.1);
  padding: 4px 8px;
  border-radius: 16px;
}

.lc-live-dot {
  color: #dc2626;
  font-size: 10px;
  animation: pulse 1.5s infinite;
}

.lc-live-indicator span {
  color: #dc2626;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.5px;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}

.lc-program-name {
  font-size: 18px;
  font-weight: 600;
  color: #004c73;
  margin-bottom: 4px;
}

.lc-pathway {
  color: #6b7280;
  font-size: 14px;
}

.lc-card-content {
  padding: 16px;
}

.lc-detail-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 15px;
}

.lc-detail-item {
  padding: 8px;
  background: #ffffff;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 2px 6px;
}

.lc-detail-label {
  font-size: 12px;
  color: #6b7280;
  font-weight: 600;
  margin-bottom: 4px;
}

.lc-detail-value {
  font-size: 14px;
  color: #1f2937;
}

.lc-instruction {
  text-align: center;
  color: #4b5563;
  font-style: italic;
  margin: 12px 0;
}

.lc-join-btn {
  width: 100%;
  display: flex;
  justify-content: center;
}

.lc-otp-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.lc-otp-popup {
  background: white;
  padding: 24px;
  border-radius: 12px;
  width: 100%;
  max-width: 400px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

.lc-popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  color: #004c73;
  margin-bottom: 20px;
  padding-bottom: 12px;
  border-bottom: 2px solid #e5e7eb;
}

.lc-close-icon {
  font-size: 24px;
  cursor: pointer;
  color: #004c73;
  transition: color 0.2s ease;
}

.lc-close-icon:hover {
  color: #ff0000;
}

.lc-otp-input {
  padding: 12px;
  border: 2px solid #e5e7eb;
  border-radius: 6px;
  font-size: 16px;
  margin-bottom: 16px;
  text-align: center;
  letter-spacing: 4px;
}

.lc-otp-input:focus {
  outline: none;
  border-color: #004c73;
  box-shadow: 0 0 0 2px rgba(0, 76, 115, 0.2);
}

.lc-popup-buttons {
  display: flex;
  justify-content: center;
}

.lc-popup-btn {
  padding: 12px 24px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s ease;
}

.lc-popup-submit {
  background-color: #004c73;
  color: white;
  width: 100%;
}

.lc-popup-submit:hover {
  background-color: #003557;
}

.lc-otp-input.error {
  border-color: #dc2626;
  animation: shake 0.5s;
}

@keyframes shake {
  0%, 100% { transform: translateX(0); }
  25% { transform: translateX(-5px); }
  75% { transform: translateX(5px); }
}
