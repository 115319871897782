.course-management-admin {
    padding-top: 1.5rem;
    padding-left: 2rem;
    padding-right: 4rem;
}
  
  .course-management-admin-title {
    font-size: 1.5em;
    font-weight: bold;
    display: flex;
    align-items: center;
    color: #233f58;
  }

  .course-management-admin-listing-title {
    font-size: 1.5em;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #233f58;
  }

  .course-management-admin-listing-title-text {
    display: flex;
    align-items: center;
  }

  .course-management-admin-title h3 {
    color: #233f58;
  }

  .course-management-admin-listing-title-text h3 {
    color: #233f58;
  }
  
  .course-management-admin-title-icon {
    margin-right: 10px;
  }
  
  .course-management-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .course-management-admin-listing-table {
    margin-top: 0;
  }
  
  .course-management-table-title-icon {
    width: 20px;
    margin-right: 10px;
    margin-left: 0;
  }

  .course-management-admin-listing-title-button-container button {
    width: 200px;
  }
  

  div.sc-hLBatu.sc-ftTHlr.jqhIkI.eSYOkw > button {
    margin: 0;
    padding: 0;
  }

  .course-management-admin-listing-expanded {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    gap: 10px;
    padding: 20px;
    background-color: #f9f9f9;
    /* border: 1px solid #ddd; */
    /* border-radius: 8px; */
  }
  
  .course-management-admin-listing-expanded-button {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    color: #fff;
    background-color: #007bff;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s;
    cursor: pointer;
  }
  
  .course-management-admin-listing-expanded-button:hover {
    background-color: #0056b3;
  }
  
  .course-management-admin-listing-expanded-icon {
    margin-right: 8px;
    font-size: 1.2em;
  }
  
  .admin-free-course-enquiry{
    width: 80%;
    padding-top: 1.5rem;
    padding-left: 2rem;
    padding-right: 4rem;
  }