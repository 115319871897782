.addl-keywords-title {
    font-size: 17.6px;
    font-weight: bold;
    margin-bottom: 10px;
}

.addl-keywords-container {
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 20px;
}

.keyword-bubble {
  background-color: #007bff;
  color: #fff;
  padding: 4px 8px;
  border-radius: 16px;
  font-size: 14px;
}
