.attempted-practice-table-header,
.attempted-practice-table-row {
  display: grid;
  grid-template-columns: 0.5fr 2fr 1fr 1fr 1fr 1fr;
  align-items: center;
}

.attempted-practice-table-header > div{
  font-weight: 600;
  color: var(--primary-color);
}

.attempted-practice-table-body{
  overflow-y: scroll;
}

.attempted-practice-hr-line {
  border: 1px solid #e0e0e0;
  margin: 0 20px 10px 20px;
}

.attempted-practice-table-row:hover {
  cursor: pointer;
  background-color: aliceblue;
}

.attempted-practice-table-header > div,
.attempted-practice-table-row > div {
  padding: 10px;
  margin: 5px 20px;
}


.attempted-practice-header,
.attempted-practice-table-body,
.attempted-practice-table {
  width: 100%; 
}

.attempted-practice-table-el,
.attempted-practice-table-header-el{
  text-align: center;
}

/* score dashboard css */

.attempted-practice-section-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e0e0e0;
}

.attempted-practice-section-header-left {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.attempted-practice-section-header-title {
  font-size: 18px;
  font-weight: 500;
  color: var(--primary-color);
  margin-left: 10px;
}

.attempted-practice-section-header-score {
  font-size: 16px;
  font-weight: 500;
  color: #00AD50;
  margin-left: 10px;
  border: 1px solid #00AD50;
  padding: 5px 10px;
  border-radius: 10px;
  background-color: #E4FFF1;
}

.attempted-practice-section-body{
  display: flex;
  flex-direction: column;
  margin: 0 20px;
}

.attempted-practice-section-body-part-2{
  height: 50vh;
  overflow-y: scroll;
}

.attempted-practice-section-body-part-3{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.attempted-practice-section-body-part-3-navigation{
  color: var(--primary-color);
  font-size: 16px;
  font-weight: 500;
  padding: 0 10px;
  text-decoration: underline;
  cursor: pointer;
}

/* score dashboard 1st css */

.interview-feedback__score-dashboard {
  display: flex;
  flex-direction: column;
  padding: 5px 15px;
}

.interview-feedback__score-dashboard-first-section-1,
.interview-feedback__score-dashboard-first-section-2 {
  flex: 1;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  margin: 10px 0;
  padding: 15px 15px 5px 15px;
}

.interview-feedback__score-dashboard-first-title {
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: 500;
}

.interview-feedback__score-dashboard-first-subtitle {
  font-size: 16px;
  color: #AAAAAA;
  margin-bottom: 10px;
}

.interview-feedback__score-dashboard-first-answer {
  margin-bottom: 10px;
  line-height: 1.2;
}

.interview-feedback__score-dashboard-first-answer-highlighted-text {
  background-color: #FFCACA;
  padding: 2px 5px;
  border-radius: 5px;
}

/* score dashboard 2nd css */
.interview-feedback__score-dashboard-second-section-1,
.interview-feedback__score-dashboard-second-section-2 {
  height: 200px;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  margin: 10px 0;
  overflow: hidden;
}

.interview-feedback__score-dashboard-second-header{
  display: flex;
  background-color: #EDF9FF;
  align-items: center;
  padding: 0 10px;
}

.interview-feedback__score-dashboard-second-title {
  font-size: 18px;
  color: var(--primary-color);
  padding: 10px;
  font-weight: 600;
}

.interview-feedback__score-dashboard-second-subtitle {
  font-size: 16px;
  color: var(--primary-color);
  font-weight: 500;
  margin-bottom: 3px;
}

.interview-feedback__score-dashboard-second-body{
  display: flex;
  flex-direction: row;
  width: 100%;
}

.interview-feedback__score-dashboard-second-body-1{
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 10px;
  overflow-y: scroll;
}

.interview-feedback__score-dashboard-second-body-2{
  border-right: 2px solid #D4D4D4;
  width: 1%;
  height: 200px;
}

.interview-feedback__score-dashboard-second-body-3{
  display: flex;
  flex-direction: column;
  width: 49%;
  padding: 10px;
  overflow-y: scroll;
}

.interview-feedback__score-dashboard-second-body-3-1,
.interview-feedback__score-dashboard-second-body-3-2,
.interview-feedback__score-dashboard-second-body-3-3{
  margin-bottom: 7px;
}

.interview-questions-not-found-section{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 20px;
  font-weight: 500;
  color: #ccc;
}

.attempted-practice-interview-loading{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}