.profile-checkmark-label {
  display: flex;
  align-items: start;
  color: var(--primary-color);
  font-size: 14px;
}

.profile-checkmark-inp {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 16px;
    height: 16px; 
    border: 1px solid #ccc;
    border-radius: 3px;
    margin-right: 8px; 
    margin-top: 1px;
    cursor: pointer;
  }
  
  .profile-checkmark-inp:checked {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
  }
  
  .profile-checkmark-inp:checked::before {
    content: '\2713'; 
    display: block;
    color: #fff; 
    text-align: center;
    line-height: 16px; 
  }

.profile-checkmark-container {
  padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 30px;
}
