.ad-sk-asgnmt_sbms_usr-dtls-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 1px solid #ccc;
    background-color: #fff;
    padding: 20px;
}

.ad-sk-asgnmt_sbms_usr-dtls-sc-1,
.ad-sk-asgnmt_sbms_usr-dtls-sc-2 {
    flex: 1;
}

.ad-sk-asgnmt_sbms_usr-dtls-sc-1{
    border-right: 1px solid #ccc;
    margin-right: 20px;
}
.ad-sk-asgnmt_sbms_usr-dtls-sc-1-row,
.ad-sk-asgnmt_sbms_usr-dtls-sc-2-row {
    color: var(--primary-color);
    font-size: 14px;
    font-weight: 600;
}

.ad-sk-asgnmt_sbms_usr-dtls-hr {
    margin: 20px 0;
    border: none;
    border-top: 1px solid #ccc;
}

.ad-sk-asgnmt_sbms_usr-dtls-sc-row-red {
    color: red;
}