  :root {
    --sidebar-bg-color: #EDF9FF;
    --sidebar-header-bg-color: #34495e; 
    --sidebar-text-color: #434343; 
    --sidebar-hover-text-color: #004C73;
    --sidebar-icon-color: #bdc3c7; 
  }
  
  .sidebar {
    width: 25%;
    background-color: var(--sidebar-bg-color);
    color: var(--sidebar-text-color);
    overflow-y: auto; 
    padding: 40px 0 40px 0;
  }
  
  .sidebar-header {
    padding: 15px 0 40px 0;
    text-align: center;
  }
  
  .sidebar-header-logo {
    max-width: 100%;
    cursor: pointer;
  }
  
  .sidebar-body-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .sidebar-body-list-item {
    display: flex;
    align-items: center;
    padding: 12px 70px;
    cursor: pointer;
    color: var(--sidebar-text-color);
    transition: background-color 0.3s ease;
    margin: 0;
  }

    .sidebar-body-list-item.active {
        border-right: 5px solid var(--sidebar-hover-text-color);
        background-color: #fff;
        color: var(--sidebar-hover-text-color);
        font-weight: 600;
    }
  
  .sidebar-body-list-item:hover {
    background-color: #ffffffde;
    color: var(--sidebar-hover-text-color);
    font-weight: 600;
  }
  
  .sidebar-body-list-item-icon {
    margin-right: 15px;
    padding-top: 1px;
  }
  
  .sidebar-body-list-item-title {
    flex: 1;
  }
  