

.filebase64-select {
    width: 40%;
    float: left;
}

.dropdown-base64 {
    margin-right: 20%;
    position: relative;
    display: inline-block;
}

.save-details {
    margin-left: 1%;
    /* margin-top: -35px; */
    width: 20%;
    color: #f84f20;
    background-color: #044c74;
    float: right;
}

.base64-img {
    height: 20rem;
    width: 80%;
    float: left;
}

.submit {
    float: right;
    margin-top: 10%;
}

.submit button {
    color: #f84f20;
    background-color: #044c74;    
    /* margin-right: 7rem; */
    width: fit-content;
}

.dropdown-content-base64 {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 140px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}

.dropdown-content-base64 a {
    color: black;
    padding: 8px 5px;
    text-decoration: none;
    display: block;
}

.dropbtn-base64 {
    color: #044c74;
    background-color: none;
    width: max-content;
    height: 35px;
    cursor: pointer;
    margin-left: 20px;
}

.dropdown-content-base64 a:hover {background-color: #f1f1f1}
  
.dropdown-base64:hover .dropdown-content-base64 {
    display: block;
}
  
.dropdown-base64:hover .dropbtn-base64 {
    background-color: #0782c5;
    transition: none;
}

.base64-img img {
}
