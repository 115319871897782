.skillbox-dashboard-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.skillbox-dashboard-summary-section {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.skillbox-dashboard-card {
  background: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  cursor: pointer;
}

.skillbox-dashboard-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.skillbox-dashboard-summary-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.skillbox-dashboard-summary-content {
  flex: 1;
}

.skillbox-dashboard-summary-icon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  transition: transform 0.3s ease;
}

.skillbox-dashboard-summary-icon.blue {
  background-color: rgba(0, 136, 254, 0.1);
  color: #0088FE;
}

.skillbox-dashboard-summary-icon.green {
  background-color: rgba(0, 196, 159, 0.1);
  color: #00C49F;
}

.skillbox-dashboard-summary-icon.orange {
  background-color: rgba(255, 187, 40, 0.1);
  color: #FFBB28;
}

.skillbox-dashboard-summary-card:hover .skillbox-dashboard-summary-icon {
  transform: scale(1.1);
}

.skillbox-dashboard-title {
  font-size: 1.2rem;
  margin-bottom: 15px;
  font-weight: 600;
  color: var(--primary-color);
}

.skillbox-dashboard-charts-section {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.skillbox-dashboard-chart-container {
  height: 300px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: transform 0.3s ease;
}

.skillbox-dashboard-chart-container:hover {
  transform: scale(1.02);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .skillbox-dashboard-summary-section,
  .skillbox-dashboard-charts-section {
    grid-template-columns: 1fr;
  }
  
  .skillbox-dashboard-chart-container {
    height: 250px;
  }
}

.skillbox-dashboard-summary-content p {
  transition: color 0.2s ease;
  padding: 4px 0;
}

.skillbox-dashboard-summary-card:hover .skillbox-dashboard-summary-content p {
  color: #0088FE;
}

.skillbox-dashboard-progress-bar {
  height: 8px;
  background: #f0f0f0;
  border-radius: 4px;
  margin-top: 10px;
  overflow: hidden;
}

.skillbox-dashboard-progress-fill {
  height: 100%;
  background: linear-gradient(90deg, #0088FE, #00C49F);
  transition: width 0.5s ease;
}

/* Loading animation for charts */
@keyframes pulse {
  0% { opacity: 0.6; }
  50% { opacity: 1; }
  100% { opacity: 0.6; }
}

.chart-loading {
  animation: pulse 1.5s infinite;
}
