.modal {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    pointer-events: none;
  }
  
  .modal.enter-done {
    opacity: 1;
    pointer-events: visible;
  }
  
  .modal.exit {
    opacity: 0;
  }
  
  .modal-content {
    width: 500px;
    margin-top: 0rem;
    background-color: #fff;
    transition: all 0.3s ease-in-out;
    transform: translateY(-200px);
  }
  
  .modal.enter-done .modal-content {
    transform: translateY(0);
  }
  
  .modal.exit .modal-content {
    transform: translateY(-200px);
  }
  
  .modal-header,
  .modal-footer {
    padding: 10px;
    display: flex;
    flex-direction: row;
  }

  .modal-header h2 {
    margin: 0%;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  
  .modal-title {
    margin: 0;
  }
  
  .modal-body {
    padding: 30px 10px;
    margin: 0.5rem 0rem;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
  }
  
  .modal-body input {
    width: 97%;
    font-size: 16px;
    padding-left: 0.5rem;
    border-radius: 4px;
  }

  .close-button {
    background-image: linear-gradient(to right, #f84f20 0%, #044c74  120%, #C04848  100%);
    float: left;
    width: 5rem;
    font-size: 16px;
    font-family: sans-serif;
    margin-left: 0rem;
    cursor: pointer;
  }

  .save-button {
    background-image: linear-gradient(to right, #044c74 0%, #f84f20  140%, #4d92b8  100%);
    cursor: pointer;
    float: right;
    width: 5rem;
    font-size: 16px;
    font-family: sans-serif;
    margin-left: auto;
  }