.interview-feedback-rating-box{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0px;
    margin: 0px 10px;
    border-bottom: 1px solid #ccc;
}

.interview-feedback-rating-box__title{
    font-size: 16px;
    font-weight: 600;
}

.interview-feedback-rating-box__points{
    font-size: 16px;
    color: var(--primary-color);
    font-weight: 600;
}

.interview-feedback-rating-box__section-1{
    flex: 1;
}

.interview-feedback-rating-box__section-2{
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.interview-feedback-rating-box__rating{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100px;
}

.interview-feedback-rating-box__rating-circle{
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
}

.interview-feedback-rating-box__rating-circle--orange {
    background-color: orange;
}

.interview-feedback-rating-box__rating-circle--yellow {
    background-color: yellow;
}

.interview-feedback-rating-box__rating-circle--green {
    background-color: green;
}

.interview-feedback-rating-box__rating-circle--orange-half {
    background: linear-gradient(to right, orange 50%, #ccc 50%) no-repeat;
}

.interview-feedback-rating-box__rating-circle--yellow-half{
    background: linear-gradient(to right, yellow 50%, #ccc 50%) no-repeat;
}

.interview-feedback-rating-box__rating-circle--green-half{
    background: linear-gradient(to right, green 50%, #ccc 50%) no-repeat;
}
