.progress-bar {
  width: 200px;
  height: 10px;
  border-radius: 5px;
  background-color: #e0e0e0;
}

.progress-bar-fill {
  height: 100%;
  border-radius: 5px;
  padding-top: 4px;
  margin-top: 2px;
  background-color: #4caf50;
  transition: width 0.5s ease-in-out;
}
