:root {
  --primary-color: #004c73;
  --secondary-color: #edf9ff;
  --tertiary-color: #777777;
  --text-color: #434343;
}

.profile {
  display: flex;
  height: 100vh;
}

.sidebar {
  width: 25%;
  background-color: var(--sidebar-bg-color);
  color: var(--text-color);
}

.profile-content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.profile-content-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin: 20px 40px 0 40px;
  border-bottom: 1px solid #ccc;
}

.profile-content-header-dropdown {
  display: flex;
  justify-content: flex-end;
}

.profile-content-header-title {
  font-size: 16px;
  color: var(--tertiary-color);
  margin-top: 10px;
}

.profile-content-header-nav-menu {
  font-size: 14px;
  color: var(--tertiary-color);
  margin-top: 5px;
}

.profile-content-header-nav-menu:hover {
  color: var(--primary-color);
  cursor: pointer;
}

.profile-content-body {
  margin: 15px 40px 15px 40px;
  height: 100%;
  background-color: #fff;
}

/* date form css */
.profile-form-section-date-container {
  padding-right: 10px;
  width: 90%;
}

.profile-form-section-date-container-2 {
  width: 90%;
}

.stu-ts-skillbox-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.stu-ts-skillbox-header-title {
  font-size: 20px;
  color: var(--primary-color);
  font-weight: bold;
}

.stu-ts-skillbox-header-subtitle {
  font-size: 16px;
  color: #777777;
  font-weight: lighter;
  margin-top: 5px;
}
