.aptitude-page {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #f3f3f3; */
  background-color: #fff;
}

.aptitude-content {
  /* background-color: #fff; */
  border-radius: 8px;
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
  padding: 20px;
  text-align: center;
  width: 80%;
  max-width: 600px;
}

.app-grid-content {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  padding-bottom: 25px;
}

.app-grid-div {
  flex: 1;
  padding: 10px;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.error{
  border: 2px solid red;
}

.app-grid-div h3 {
  font-size: 18px;
  margin: 0;
}

.app-grid-div li{
  padding: 10px 0 10px 0;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  margin: 5px 0;
  cursor: pointer;
  transition: background-color 0.2s;
}

.app-grid-div li:hover {
  background-color: #e3e3e3;
}

li.selected {
  background-color: #d8ebf8; 
}

button {
  background-color: #f84f20;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.2s;
}

button:hover {
  background-color: #9c3a1f;
}

.next-button {
  display: flex;
  align-items: center;
  gap: 8px; 
  background: #007bff;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.button-text {
  flex: 1;
}

.error-message{
  color: red;
  font-weight: 500;
}

.instructions {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: left;
}

.instructions h3 {
  font-size: 24px;
  margin: 0;
  color: #333;
}

.instructions hr {
  margin: 10px 0;
  border: none;
  border-top: 1px solid #ccc;
}

.instructions ul {
  list-style: disc;
  padding-left: 20px;
}

.instructions li {
  font-size: 16px;
  margin-bottom: 10px;
  color: #555;
  display: list-item !important;
}

.instructions li:hover{
  background-color: #fff !important;
}

.start-button {
  background-color: #007BFF;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.start-button:hover {
  background-color: #0056b3;
}



@media (max-width: 768px) {
  .app-grid-content {
    flex-direction: column;
  }

  .app-grid-div {
    margin-top: 10px;
  }
}
