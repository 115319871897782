.course-qgt-nav-legends-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  gap: 9px;
  padding: 14px 20px;
  /* margin-bottom: 20px; */
}

.course-qgt-nav-legends-title {
  font-size: 14px;
  font-weight: 600;
  color: var(--primary-color);
}

.course-qgt-nav-legends {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.course-qgt-nav-legends-section-1 {
  display: flex;
  align-items: center;
}

.course-qgt-nav-legends-squares {
  display: flex;
  align-items: start;
  overflow-x: scroll;
  scrollbar-width: none;
}

.course-qgt-nav-legends-square {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  background-color: #fff;
  border: 1px solid #898989;
  color: #898989;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 8px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s, color 0.3s;
}

.course-qgt-nav-legends-square:hover {
  background-color: #d2f0ff;
  color: #004c73;
}

.course-qgt-nav-legends-square-active {
  background-color: #d2f0ff;
  color: #004c73;
  border-color: #004c73;
}

.course-qgt-nav-legends-svgs {
  cursor: pointer;
}
