h3{
    color: #ff9933;
    font-family: cursive;
}
image{
    margin-left: 100px;
}
.under-progress {
    padding-top: 5rem;
    padding-bottom: 2rem;
    padding-left:75px;
    background: #eef6fc;
  }