.interview-start-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.interview-start-video-container {
  background-color: #ccc;
  width: 300px;
  height: 200px;
  border: 2px solid var(--primary-color);
  margin-bottom: 10px;
  position: relative;
}

.interview-start-details {
  margin-top: 10px;
}

.interview-start-total-ques {
  font-weight: 600;
  color: var(--primary-color);
  font-size: 32px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.interview-start-exam-instructions {
  margin-top: 10px;
  font-size: 16px;
  color: #555;
  margin-bottom: 20px;
}

.interview-start-start-exam-btn {
  margin: 10px 0px 10px 0px;
}

.interview-start-media-instruction{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #FFA500;
}

.interview-start-media-instruction > svg {
  margin-right: 5px
}

.interview-start-media-connected{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #00AD50;
}

.interview-start-media-connected > svg {
  margin-right: 5px;
}

.interview-start-media-not-connected{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #FF0000;
}

.interview-start-media-not-connected > svg {
  margin-right: 5px;
}

.interview-start-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}