.reward-history-table-header,
.reward-history-table-row {
  display: grid;
  grid-template-columns: 1.5fr 1fr 2fr 1fr;
  align-items: center;
}

.reward-history-table-header > div{
  font-weight: 600;
  color: var(--primary-color);
}

.reward-history-table-body{
  overflow-y: scroll;
}

.reward-history-hr-line {
  border: 1px solid #e0e0e0;
  margin: 0 20px 10px 20px;
}

.reward-history-table-header > div,
.reward-history-table-row > div {
  padding: 10px;
  margin: 5px 20px;
}


.reward-history-header,
.reward-history-table-body,
.reward-history-table {
  width: 100%; 
}

.reward-history-table-status,
.reward-history-table-date,
.reward-history-table-type,
.reward-history-table-header-status,
.reward-history-table-header-date,
.reward-history-table-header-type{
  text-align: center;
}

.reward-history-status-in-progress {
  color: #B54708;
  background-color: #FFFAEB;
  border-radius: 25px;
}

.reward-history-status-completed {
  color: #027A48;
  background-color: #ECFDF3;
  border-radius: 25px;
}

.referral-history-error-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  color: var(--text-color);
  font-size: 20px;
}