.admin-campus-programme-edit {
    padding-top: 5rem;
    padding-left: 2rem;
    padding-right: 1rem;
    padding-bottom: 2rem;
    background: #ffffff;
}

.admin-campus-programme-edit h2 {
    margin-left: 0.9rem;
    margin-bottom: 2rem;
}

.admin-campus-programme-edit h3 {
    padding: 0%;
    text-align: left;
    margin-left: 1rem;
    margin-top: 0.8rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: #4949b6;
}

/* .admin-campus-programme-edit ul li {
    list-style-type: none;
    text-align: left;
    padding-left: 4rem;
    border-style: ridge;
    width: 50%;
} */

.edit-button {
    /* align-items: right;
    text-align: right; */
    margin-left: 0;
}

.admin-campus-programme-edit table {
    width: 100%;    
}

.module-table{
    padding: 1rem;
}
.module-table thead {
    background-image: linear-gradient(to right, #044c74 0%, #f84f20  120%, #044c74  100%);
}

.module-table td {
    border: 1.8px solid #bbb;
    padding: 7px;
    text-align: center;
}

.module-table th {
    border: 1.8px solid #bbb;
    padding: 14px;
}

.dropbtn {
    color: white;
    padding: 0.6rem 1.1rem;
    font-size: 16px;
    border: none;
    cursor: pointer;
    width: max-content;
    margin-left: auto;
    margin-right: 1rem;
    margin-top: 1.2rem;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    background-color: #056294;
}
  
.dropdown {
    position: relative;
    display: inline-block;
    margin-top: 10px;
}
  
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}
  
.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}
  
.dropdown-content a:hover {background-color: #f1f1f1}
  
.dropdown:hover .dropdown-content {
    display: block;
}
  
.dropdown:hover .dropbtn {
    background-color: #0782c5;
    transition: none;
}

.add-new-module{
    width: auto;
    height: 2.8rem;
    font-family: sans-serif;
    font-size: 17px;
    cursor: pointer;
    padding-top: 0px;
    padding-bottom: 0px;
    margin: 1rem;
    margin-top: 1.5rem;
    font-weight: 600;
    background-image: linear-gradient(to right, #f84f20 0%, #044c74  120%, #C04848  100%);
}

.add-new-module:hover{
    background-color: #d6d6c2;
    border: 2px solid #8a8a5c;
    transition: 0s;
}

button{
    background-image: none;
}

.dropdown-modules{
    position: relative;
    display: inline-block;
    margin-top: 0.3rem;
    margin-right: auto;
    margin-left: 1rem;
}

.dropbtn-modules{
    color: white;
    padding: 0.5rem;
    width: 9rem;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    height: 2.8rem;
    font-size: 18px;
}

.edit-icon {
    background-color: #0782c5;
    width : 2.5rem;
    padding: 0.3rem;
    font-size: 1rem;
    cursor: pointer;
}

.delete-icon {
    background-color: #f84f20;
    width : 2.5rem;
    padding: 0.3rem;
    font-size: 1rem;
    cursor: pointer;
}

.save-icon {
    display: none;
    background-color: #3e8e41;
    width : auto;
    padding: 0.4rem;
    font-size: 0.9rem;
    cursor: pointer;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

input {
    width: 93%;
    height: 1.8rem;
    border: 2px solid rgba(77, 77, 77, .5);
    margin: 0px;
}

td {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

.module-table-seperate {
    height: 10px;
	border: 0;
	box-shadow: 0 10px 10px -10px #8c8b8b inset;
}

.add-title {
    width: 10%;
}

.add-description {
    width: 20%;
}

.add-url {
    width: 64%;
}

.add-visibility {
    width: 13%;
}

.new-row {
    margin-right: 0.6rem;
    border: 1.5px solid rgba(77, 77, 77, .5);
    border-radius: 4px;
    font-size: 16px;
    padding-left: 0.4rem;
}

.modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }
  
  /* Modal Content */
  .modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
  }

  .save-changes {
    background-color: #056294;
    color: white;
    padding: 0.6rem 1.1rem;
    font-size: 18px;
    border: none;
    cursor: pointer;
    width: 19rem;
    height: fit-content;
    margin-left: auto;
    margin-right: 1rem;
    margin-top: 1.7rem;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
  }

  .view-icon {
    background-color: #C04848;
    width : 2.5rem;
    padding: 0.3rem;
    font-size: 1rem;
    cursor: pointer;
  }