.sk-st-fl-upload-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 15px 0;
    gap: 10px;
}

.sk-st-fl-upload-title{
    font-size: 22px;
    color: #0F0F0F;
    font-weight: 700;
}

.sk-st-fl-drop-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 2px dashed #004C735C;
    border-radius: 10px;
    background-color: #004C7308;
    padding: 25px 20px;
    width: 100%;
    text-align: center;
    cursor: grabbing;
    margin: 20px 0;
  }

.sk-st-fl-upload-text{
    display: flex;
    gap: 5px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 16px;
    color: #0F0F0F;
    font-weight: 700;
    margin: 10px;
}

.sk-st-fl-upload-text label{
    color: #004C73;
    font-weight: 700;
    margin: 0px;
    text-decoration: underline;
    cursor: pointer;
}

.sk-st-fl-upload-format-info{
    font-size: 14px;
    color: #676767;
    font-weight: 400;
    margin: 10px 0;
}

.sk-st-fl-upload-instructions{
    font-size: 14px;
    color: var(--primary-color);
    background-color: #DFF4FF;
    font-weight: 400;
    width: fit-content;
    text-align: center;
    padding: 10px 15px;
    margin: 0px;
}

.sk-st-fl-upload-error{
    font-size: 14px;
    color: #FF0000;
    font-weight: 400;
    margin: 10px 0;
}