/* ViewCourse.css */

.module_popup__content {
  font-size: 16px;
  line-height: 1.6;
}

.module_popup__content-row {
  display: flex;
  margin-bottom: 15px;
}

.module_popup__content-row-title {
  flex: 1;
  font-weight: bold;
  margin-right: 10px;
}

.module_popup__content-row-value {
  flex: 2;
}

/* Style links inside the popup content */
.module_popup__content a {
  color: #3498db;
  text-decoration: underline;
  cursor: pointer;
}

.module_popup__content a:hover {
  color: #2980b9;
}

/* Style paragraphs inside the popup content */
.module_popup__content p {
  margin: 0;
}
