.practice-page {
  padding-top: 5rem;
  padding-left: 5rem;
  padding-right: 5rem;
  padding-bottom: 2rem;
  background: #eef6fc;
}

.practice-tabs {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.practice-tabs div {
  padding: 10px 20px;
  font-size: 20px;
  color: #004c73;
  cursor: pointer;
  background-color: #edf9ff;
  border: 1px solid #ccc;
  transition: background-color 0.3s ease;
}

.practice-tabs div:hover {
  background-color: #ddd;
}

.practice-tab-content {
  border: 1px solid #ddd;
  border-radius: 5px;
}

.practice-active-tab {
  background-color: var(--primary-color) !important;
  color: #ffffff !important;
  border-radius: 5px;
}

.practice-loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.practice-loading-vertical-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 100%;
}
