.aptitude-page {
  padding-top: 5rem;
  padding-bottom: 2rem;
  background: #eef6fc;
}

.aptitude-content {
  padding-left: 5%;
  padding-right: 5%;
}

.aptitude-content h3 {
  color: rgb(18, 18, 53);
  font-size: 28px;
  padding-top: 1rem;
  margin-left: 1.5%;
}

.aptitude-content p {
  font-size: 20px;
  margin-left: 1.5%;
}

.app-grid-content {
  padding-top: 1rem;
  padding-bottom: 3rem;
  display: grid;
  justify-content: center;
  grid-template-rows: repeat(1, 13rem);
  grid-template-columns: repeat(auto-fit, minmax(7rem, 23rem));
  grid-row-gap: 2rem;
  grid-column-gap: 2rem;
  grid-auto-rows: 13rem;
}

.app-grid-div {
  
  padding: 1%;
  padding-right: 15%;
}

.app-grid-div h3{
    font-size: 20px;
    margin-top: 0rem;
    padding-top: 0rem;
    margin-bottom: 0rem;
    padding-bottom: 0rem;
}

.app-grid-div li{
    padding-bottom: 0.5rem;
    cursor: pointer;
    
}

.app-grid-div a{
    text-decoration: none;
    color: black;
}
