.attendance-page {
    padding-top: 5rem;
    padding-left: 5rem;
    padding-right: 5rem;
    padding-bottom: 2rem;
    background: #eef6fc;
}

.main-heading{
    font-weight: 600;
    font-size: 24px;
    font-style: italic;
    background-image: linear-gradient(to right, #f84f20 0%, #044c74  120%, #C04848  100%);
    color: #fff;
    padding: 10px;
    line-height: 1.7em;
    box-shadow: 0 2px 10px 0 #cac9c9;
    text-align: left;
    user-select: none;
    border-left: 20px solid #044c74;
}

.attendance-table {
    padding: 1rem;
    width: 100%;
}

.attendance-table td {
    border: 1px solid #bbb;
    padding: 7px;
}

.attendance-table td button {
    background-image: linear-gradient(to right, #f84f20 0%, #044c74  120%, #C04848  100%);
}

.attendance-table thead tr {
    background-image: linear-gradient(to left, #f84f20 0%, #044c74  120%, #C04848  100%);
    padding: 14px;
    color: #fff;
}

.attendance-table th {
    border: 1.8px solid #bbb;
    padding: 14px;
}

.download-attendance-btn{
    position :relative;
    right: 20px;
    top: -70px;
    margin-bottom: -70px;
    width: auto;
    float: right;
    border-radius: 8px;
    cursor: pointer;
}

.attendance-summary-grid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
}

.attendance-summary-card {
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    margin: 20px;
    background-color: #f5f5f5;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  
  .attendance-summary-card h3 {
    font-size: 1.5rem;
    margin: 0;
    color: #333;
  }
  
  .attendance-stats {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    width: 100%;
  }
  
  .stat {
    text-align: center;
    flex: 1;
  }
  
  .stat p:first-child {
    font-weight: 600;
    color: #777;
  }
  
  .stat p:last-child {
    font-size: 1.2rem;
    margin-top: 5px;
    color: #333;
  }
  
  .attendance-summary-btn {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    width: 100%;
  }
  
  .view-button, .download-button {
    background-image: linear-gradient(to right, #f84f20 0%, #044c74  120%, #C04848  100%);
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    font-weight: 600;
    transition: background-color 0.3s;
    margin: 5px 5px;
  }
  
  .view-button:hover, .download-button:hover {
    background-color: #0056b3;
  }

    .total-classes p:first-child {
        font-weight: 600;
        color: #777;
    }

    .attendance-table-stats {
        display: flex;
        justify-content: space-between;
        margin: 10px 20px 0 20px;
        width: 100%;
    }
  
    .attendance-table-stats div {
        text-align: center;
        flex: 1;
    }

  @media (max-width: 768px) {
    .attendance-summary-card {
      padding: 15px;
    }
    
    .attendance-summary-card h3 {
      font-size: 1.2rem;
    }
    
    .attendance-summary-btn button {
      padding: 8px 16px;
    }
  }