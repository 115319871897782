.qcreator-skillbox-page {
  display: flex;
  height: 100vh;
}

.qcreator-skillbox-page-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #F7F7FF;
}

.qcreator-skillbox-page-content-header {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 0 40px;
  border-bottom: 1px solid #D8D8D8;
}

.qcreator-skillbox-page-content-header-nav-menu {
  font-size: 14px;
  color: var(--tertiary-color);
  margin-top: 5px;
}

.qcreator-skillbox-page-content-header-nav-menu:hover {
  color: var(--primary-color);
  cursor: pointer;
}

.qcreator-skillbox-page-content-body {
  margin: 15px 40px 15px 40px;
  height: 100%;
  background-color: #F7F7FF;
  color: var(--text-color);
}

.qcreator-skillbox-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.qcreator-skillbox-header-title {
  font-size: 20px;
  color: var(--primary-color);
  font-weight: bold;
}

.qcreator-skillbox-header-subtitle {
  font-size: 16px;
  color: #777777;
  font-weight: lighter;
  margin-top: 5px;
}

.qcreator-skillbox-main-section {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  height: 70vh;
  overflow-y: auto;
}

.qcreator-skillbox-loader-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  height: 70vh;
}

.qcreator-skillbox-loader-container-for-loader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.qcreator-skillbox-error-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  color: red;
  font-size: 20px;
}
