.coursecontentpage {
  padding-top: 5rem;
  padding-left: 5rem;
  padding-right: 5rem;
  padding-bottom: 2rem;
  background: #eef6fc;
}

.coursecontentpage h2 {
  text-align: center;
}

.coursecontentpage h3 {
  color: rgb(29, 29, 99);
  font-size: 24px;
}

.coursecontentpage p {
  font-size: 24px;
  color: rgb(15, 15, 29);
}

.coursescontent {
  display: flex;
}

.coursescontent-flex1 {
  flex: 2;
}

.coursescontent-flex2 {
  flex: 3.4;
  padding-left: 6rem;
  padding-top: 0rem;
  
}

.course-grid {
  background-color: white;
  margin-bottom: 1.1rem;
  border-radius: 6px;
  display: flex;
}

.course-grid-div {
  flex: 4;
  padding-top: 0.02rem;
  padding-bottom: 0.02rem;
  cursor: pointer;
}

.icon-box {
  flex: 0.01;
  padding-right: 0.5rem;
  text-align: center;
  font-size: 22px;
  align-self: center;
}

.course-grid-div h3 {
  margin-left: 5%;
  flex: 1;
  margin-top: 0.5rem;
  margin-right: 2%;
  font-size: 16px;
  margin-bottom: 0rem;
  font-weight: 500;
}

.course-grid-div h2 {
  margin-left: 5%;
  margin-top: 0.5rem;
  text-align: left;
  margin-right: 2%;
  font-size: 19px;
  margin-bottom: 1rem;
  font-weight: 500;
}

.course-grid-div p {
  margin-left: 5%;
  margin-right: 2%;
  font-size: 15px;
  margin-top: 0.4rem;
  color: rgb(204, 118, 86);
}

.course-grid-div-time {
  margin-left: 5%;
  margin-top: -0.4rem;
}

.progress-bar {
  margin-left: 5%;
  margin-right: 5%;
  padding-bottom: 0.5rem;
}

.progress-bar span {
  font-size: 14px;
  color: rgb(31, 31, 71);
}

.module-dis-div {
  margin-bottom: 2rem;
  padding-left: 0rem;
}

.module-dis-div h2 {
  text-align: center;
  margin-top: 0rem;
  font-size: 24px;
  margin-bottom: 1rem;
  font-weight: bold;
}


.module-flex1-div {
    display: flex;
    padding-top: 0.4rem;
    cursor: pointer;
    border-radius: 7px;
    padding-bottom: 0.5rem;
    margin-bottom: 0.3rem;
}

.flex1-div-content{
    flex: 0.2;
    text-align: center;
    align-self: center;
    
}

.flex1-div-content1{
  flex: 0.2;
  text-align: center;
  margin-top: 2rem;
  
}

.flex2-div-content{
    flex: 1;
    align-self: center;
}

.flex2-div-content h2{
    text-align: left;
    margin-top: 1rem;
    margin-right: 2%;
    font-size: 28px;
    margin-bottom: 1rem;
    font-weight: bold;
}

.flex2-div-content h3{
   color: rgb(16, 16, 41);
   font-size: 16px;
   margin-top: 0.5rem;
    margin-bottom: 0rem;
    font-weight: 490;
}


.flex3-div-content{
  flex: 0.5;
  padding-right: 0.5rem;
  font-size: 19px;
  padding-top: 0.5rem;
}


.progress-bar-module{
    margin-right: 5%;
    margin-top: -0.2rem;
}

.progress-bar-module span{
    font-size: 14px;
    color: rgb(31, 31, 71);
}

.flex1-video-grid ul{
    margin-left: -2.5rem;
    list-style-type: none;
}

.flex1-video-grid li{
    padding-top: 0.3rem;
    padding-left: 0.5rem;
    padding-bottom: 0.3rem;
}

.flex1-video-grid li:hover{
    background-color: rgb(213, 213, 226);
}

@media (max-width: 500px) {

  .coursecontentpage {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  
  }
  
  .coursecontentpage h2 {
    text-align: center;
  }
  
  .coursecontentpage h3 {
    color: rgb(29, 29, 99);
    font-size: 24px;
  }
  
  .coursecontentpage p {
    font-size: 24px;
    color: rgb(15, 15, 29);
  }
  
  .coursescontent {
    display: flex;
  }
  
  .coursescontent-flex1 {
    flex: 2;
  }
  
  .coursescontent-flex2 {
    flex: 3.4;
    padding-left: 6rem;
    padding-top: 0rem;
    
  }
  
  .course-grid {
    background-color: white;
    margin-bottom: 1.1rem;
    border-radius: 6px;
    display: flex;
  }
  
  .course-grid-div {
    flex: 4;
    padding-top: 0.02rem;
    padding-bottom: 0.02rem;
    cursor: pointer;
  }
  
  .icon-box {
    flex: 0.01;
    padding-right: 0.5rem;
    text-align: center;
    font-size: 22px;
    align-self: center;
  }
  
  .course-grid-div h3 {
    margin-left: 5%;
    flex: 1;
    margin-top: 0.5rem;
    margin-right: 2%;
    font-size: 16px;
    margin-bottom: 0rem;
    font-weight: 500;
  }
  
  .course-grid-div h2 {
    margin-left: 5%;
    margin-top: 0.5rem;
    text-align: left;
    margin-right: 2%;
    font-size: 19px;
    margin-bottom: 1rem;
    font-weight: 500;
  }


}
