  .profile-delete-confirmation-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }

  .profile-delete-loading-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .profile-delete-confirmation-text {
    font-size: 16px;
    font-weight: bold;
    color: var(--tertiary-color);
    text-align: center;
    margin-bottom: 35px;
  }

  .profile-delete-popup-buttons {
    display: flex;
    justify-content: space-between;
    width: 50%;
  }
  
  .profile-delete-popup-buttons button {
    margin: 0 10px;
  }
  
