.mycoursepage{
    padding-top: 6rem;
    padding-left: 5rem;
    padding-right: 5rem;
    background: #eef6fc;
}


.courses-grid{
    margin-top: 2rem;
    display: grid;
    grid-template-rows: repeat(1, 16.3rem);
    grid-template-columns: repeat(auto-fit, minmax(11rem, 17rem));
    grid-row-gap: 4rem;
    grid-column-gap: 5rem;
    grid-auto-rows: 16.3rem;
    padding-bottom: 4rem;
}

.grid-div{
    border-radius: 6px;
    cursor: pointer;
    box-shadow: rgba(29, 23, 23, 0.35) 0px 5px 15px;
}

.grid-div h3{
    margin-left: 5%;
    margin-right: 2%;
    font-size: 17px;
    margin-bottom: 0rem;
    font-weight: 500;
}

.grid-div p{
    margin-left: 5%;
    margin-right: 2%;
    font-size: 15px;
    margin-top: 0.4rem;
    color: rgb(204, 118, 86);
}

.grid-card-img{
    width: 100%;
    height: 9rem;
}

.grid-card-img img{
    width: 100%;
    border-radius: 6px 6px 0px 0px;
    height: 100%;
}

.progress-bar{
    margin-left: 5%;
    margin-right: 5%;
}

.progress-bar span{
    font-size: 14px;
    color: rgb(31, 31, 71);
}

@media (max-width: 500px) {

    .mycoursepage{
        padding-top: 4rem;
        padding-left: 2rem;
        padding-right: 2rem;
        background: #eef6fc;
    }
    
    
    .courses-grid{
        margin-top: 2rem;
        display: grid;
        grid-template-rows: repeat(1, 16rem);
        grid-template-columns: repeat(auto-fit, minmax(11rem, 17rem));
        grid-row-gap: 4rem;
        grid-column-gap: 5rem;
        grid-auto-rows: 16rem;
        padding-bottom: 4rem;
    }
    
    .grid-div{
        border-radius: 6px;
        margin-bottom: -2rem;
        cursor: pointer;
        box-shadow: rgba(29, 23, 23, 0.35) 0px 5px 15px;
    }
    
}