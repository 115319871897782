.aptitude-practice {
  margin: 0 auto;
  padding: 20px;
}

.aptitude-practice-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.aptitude-practice-subtitle-info {
  font-size: 20px;
  color: var(--primary-color);
  font-weight: 600;
}

.aptitude-practice-selects {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.aptitude-practice-horizontal-line {
  width: 100%;
  height: 1px;
  background-color: var(--primary-color);
  margin: 20px 0 20px 0;
  margin-bottom: 20px;
}

.aptitude-practice-select-label {
  font-size: 16px;
  color: #333;
  font-weight: 500;
  margin-bottom: 5px;
}

.aptitude-practice-title {
  font-size: 24px;
  font-weight: 600;
  color: var(--primary-color);
  margin-bottom: 10px;
}

.aptitude-practice-subtitle {
  font-size: 18px;
  font-weight: 500;
  color: #333;
  margin-bottom: 10px;
}

.aptitude-practice-top {
  display: flex;
  justify-content: space-between;
}

.aptitude-practice-test-left {
  display: flex;
  flex-direction: column;
}

.aptitude-practice-test-right {
  color: var(--primary-color);
  font-size: 20px;
  font-weight: 600;
  margin: 5px 0 0 20px;
}

.aptitude-practice-filter {
  margin-top: 10px;
  width: 40%;
}

.aptitude-practice-filter h2 {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.aptitude-practice-filter ul {
  list-style: none;
  padding: 0;
  margin: 0;
  padding: 0;
  height: 200px;
  overflow-y: scroll;
  margin-bottom: 20px;
}

.aptitude-practice-filter li {
  cursor: pointer;
  margin-bottom: 5px;
  font-size: 20px;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.aptitude-practice-filter li:hover {
  background-color: #f0f0f0;
}

.aptitude-practice-filter li span {
  margin-left: 5px;
}

.aptitude-practice-filter-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}