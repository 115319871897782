.container-fluid {
  background-color: #feebe6;
  background-image: linear-gradient(to right, #f8967b 0%, #044c74  120%, #bb7070  100%);
}

.copyright {
  text-align: center;
  font-weight: 510;
  font-size: 16px;
  color: #233f58;
  padding-bottom: 1%;
  margin-top: -1%;
}

.flex-footer {
  display: flex;
  flex: row;
  padding-left: 2%;
  padding-right: 2%;
  color: white;
  width: 80%;
  margin-left: 7%;
  margin-right: 10%;
}

.flex-footer1 {
  display: flex;
  text-align: center;
  /* justify-content: center; */
  flex-direction: column;
  margin-top: 1.6rem;
  flex: 1.5;
}

.flex-footer1 h5 {
  text-align: left;
  margin-left: 3.5%;
  margin-top: 1%;
  font-size: 15px;
  font-weight: 500;
  color: black;
}

.flex-footer1 li {
  margin-left: 1%;
  margin-top: 1%;
}

/* .flex-footer1 p {
  font-size: 16px;
  margin-bottom: -3%;
  padding-left: 3%;
} */

.flex-footer2 {
  display: flex;
  flex-direction: column;
  margin-top: -1%;
  margin-left: 12%;
  flex: 1.5;
}

.flex-footer2 h5 {
  color: #dc2f02;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 1%;
  margin-left: 3%;
}

.flex-footer2 a {
  font-weight: 510;
  margin-left: 3%;
  margin-top: 0.5rem;
  color: #233f58;
  font-size: 15px;
  
}

.flex-footer3 {
  display: flex;
  flex-direction: column;
  margin-top: -1%;
  margin-left: 2.5%;
  flex: 1;
}

.flex-footer3 h5 {
  color: #dc2f02;
  flex: 1;
  font-size: 20px;
  font-weight: 600;
}

.flex-footer3-a {
  flex: 1;
  /* margin-bottom: 10rem; */
}

.flex-footer3 a {
  flex: 1;
  font-weight: 510;
  color: #233f58;
  text-decoration: none;
  font-size: 15px;
  /* margin-top: -1rem;
  margin-bottom: -1.5rem; */
}

.flex-footer3-a-1 {
  margin-top: -3.5rem;
  margin-bottom: -1.5rem;
}

/* .flex-footer3-a-2 {
  margin-top: -1rem;
  margin-bottom: -1.5rem;
} */

.flex-footer3-a-3 {
  margin-top: -1.5rem;
  /* margin-bottom: -1.5rem; */
}

.flex-footer3 li {
  margin-top: -1rem;
  margin-bottom: -1rem;
  list-style-type: none;

}

.flex-footer1 a {
  color: rgb(226, 218, 218);
  text-decoration: none;
}

.flex-footer2 a {
  color: #233f58;
  text-decoration: none;
}


.flex-footer4 a {
  color: #233f58;
  text-decoration: none;
}

.flex-footer4 {
  margin-top: -1%;
  flex: 1;
}

.flex-footer4 h5 {
  color: #dc2f02;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: -1%;
}

.flex4-div a {
  font-weight: 510;
  align-items: center;
  align-self: center;
  justify-content: center;
  justify-self: center;
  color: #233f58;
  font-size: 16px;
  margin-top: 0.5rem;
}

.flex4-div{
  display: flex;
  

}



.footer-surgeimg {
  width: 250px;
  height: 45px;
  margin-left: 0.5rem;
}

@media (max-width: 450px) {
  .footer-surgeimg {
    width: 18%;
    margin-left: 3rem;
  }

  .flex-footer {
    display: flex;
    flex-direction: column;
    padding-left: 3%;
    padding-right: 3%;
    color: white;
  }

  .flex-footer1 {
    margin-top: 3%;
    flex: 1.5;
  }

  .flex-footer1 h5 {
    margin-left: 3%;
    font-size: 24px;
    font-weight: 500;
  }

  .flex-footer1 span {
    font-size: 18px;
    font-weight: 400;
  }

  .flex-footer1 p {
    font-size: 18px;
    margin-bottom: -3%;
    padding-left: 3%;
  }

  .flex-footer2 {
    margin-top: -11%;
    flex: 1.5;
  }

  .flex-footer2 h5 {
    margin-top: 1rem;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 2%;
    margin-left: 3%;
  }

  .flex-footer2 a {
    margin-left: 3%;
    color: #233f58;
    font-size: 16px;
  }

  .flex-footer3 {
    margin-top: -6%;
    margin-left: 3%;
    flex: 1;
  }

  .flex-footer3 h5 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 1.5rem;
  }

  .flex-footer3 a {
    margin-bottom: 1.5rem;
    color: #233f58;
    font-size: 16px;
  }

  .flex-footer1 a {
    color: rgb(226, 218, 218);
    text-decoration: none;
  }

  .flex-footer2 a {
    color: #233f58;
    text-decoration: none;
  }

  .flex-footer3 a {
    color: #233f58;
    text-decoration: none;
  }

  .flex-footer4 a {
    color: #233f58;
    text-decoration: none;
  }

  .flex-footer4 {
    margin-top: -12%;
    flex: 1;
    margin-left: 3%;
  }

  .flex-footer4 h5 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 0%;
  }

  .flex-footer4 p {
    color: #233f58;
    font-size: 16px;
    margin-bottom: -3%;
  }

  .copyright {
    text-align: center;
    font-size: 15px;
    color: black;
    padding-bottom: 4%;
    margin-top: 8%;
  }
}

.raise-query-floating-btn{
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 15;
  width: 200px;
}