.dashboard{
  display: flex;
  flex-direction: row;
  gap: 15px;
  /* padding: 10px 0; */
}

.dashboardSection1,
.dashboardSection2{
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.dashboardSection1{
  width: 70%;
}

.dashboardSection2{
  width: 30%;
}

.dashboardHeader{
  display: flex;
  flex-direction: row;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  color: var(--primary-color);
}

.dashboardHeaderIcon{
  margin-right: 10px;
  cursor: pointer;
}

.dashboardSubHeadersContainer{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.dashboardSubHeader{
  font-size: 17px;
  font-weight: 700;
  margin-bottom: 10px;
  color: var(--primary-color);
}

.dashboardSubHeaderClickable{
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 10px;
  color: var(--primary-color);
  text-decoration: underline;
  cursor: pointer;
}

.dashboardAnalyticsRowContainer{
  /* height: 100%; */
  width: 100%;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 20px 0 20px 0;
}

.dashboardAnalyticsContainer{
  /* height: 100%; */
  width: 100%;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 0;
}

.dashboardAnalytics{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 20px 20px;
}

.dashboardAnalyticsPieChart{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.dashboardAnalytics:first-child{
  /* margin-top: 30px; */
  align-items: flex-start;
  text-align: center;
  width: 100%;
}

.dashboardAnalytics:last-child{
  display: flex;
  flex-direction: column;
  text-align: left;
}

.dashboardAnalyticsPieChart{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dashboardAnalyticsTitle{
  font-size: 16px;
  font-weight: 600;
  padding: 0 20px 15px 20px;
}

.dashboardAnalyticsTitlePieChart{
  font-size: 16px;
  text-align: center;
  font-weight: 600;
  padding: 0px;
}

.dashboardAnalyticsBarChartLegend{
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin: 2px 0 0 0;
  padding: 0 20px;
}

.dashboardInfoBox{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  gap: 20px;
  padding: 20px 25px;
  /* margin-bottom: 20px; */
}

.dashboardInfo{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  border-right: 1px solid #C2C2C2;
  width: 100%;
}

.dashboardInfo:last-child{
  border-right: none;
}

.dashboardInfoIcon{
  color: var(--primary-color);
  font-size: 30px;
}

.dashboardInfoData{
  display: flex;
  flex-direction: column;
  /* gap: 5px; */
}

.dashboardInfoDataCount{
  font-size: 18px;
  font-weight: bold;
}

.dashboardInfoDataTitle{
  font-size: 15px;
  font-weight: 400;
}

.dashboardAnalyticsBarChartLegendText{
  font-size: 14px;
  font-weight: 500;
  padding: 5px;
  width: fit-content;
  border-radius: 10px;
}

.DashboardStudentRole{
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.DashboardStudentName{
  font-size: 14px;
  font-weight: 500;
}

.dashboardAnalyticsPieChartLegendsColumn{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 70%;
  align-items: center;
  gap: 5px;
}

.dashboardAnalyticsBarChartContainer{
  padding: 10px 0;
}

.dashboardAnalyticsBarChartLegendsColumn{
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 7px;
}

.dashboardAnalyticsBarChartLegendsGrid{
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 0px 10px;
  flex-wrap: wrap;
}