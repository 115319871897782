.courseHistory{
    display: flex;
    flex-direction: row;
    gap: 5px;
    padding: 20px 0;
}

.courseHistorySection1{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.courseHistorySection1{
    gap: 29px;
}

.courseHistoryHeader{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
    font-size: 20px;
    font-weight: bold;
    color: var(--primary-color);
}

.courseHistoryHeaderContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.courseHistoryBoxesContainer{
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}

.courseHistoryHeaderIcon{
    margin-right: 10px;
    margin-top: 6px;
    cursor: pointer;
}

.courseHistorySubHeader{
    font-size: 20px;
    font-weight: 600;
    color: var(--primary-color);
}

.courseHistoryHeaderButton{
    font-size: 14px;
    font-family: inherit;
    background-color: var(--primary-color);
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: auto;
    margin: 0;
    height: 36px;
}

.courseHistoryHeaderButton:hover{
    background-color: #003855;
}

.courseHistoryHeaderButton:disabled{
    background-color: #BCBCBC;
    cursor: not-allowed;
}