.stu-skillbox-assignments-details-container-1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
    margin-top: 20px;
  }
  
  .stu-skillbox-assignments-dropdown {
    width: 48%;
  }
  
  .stu-skillbox-assignments-details-container-2 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
    margin-top: 20px;
    height: 60vh;
    overflow-y: scroll;
  }
  
  .stu-skillbox-nav-header {
    font-size: 14px;
    color: var(--tertiary-color);
    margin-top: -5px;
    margin-bottom: 10px;
  }
  
  .stu-skillbox-nav-header span:hover {
    color: var(--primary-color);
    cursor: pointer;
  }
  
  /* table css */
  
  .st-assgn-table-header,
  .st-assgn-table-row {
    display: grid;
    grid-template-columns: 1.5fr 1.5fr 1fr 1fr 1fr;
    align-items: center;
  }
  
  .st-assgn-table-header > div{
    font-weight: 600;
    color: var(--primary-color);
  }
  
  .st-assgn-table-body{
    overflow-y: scroll;
  }
  
  .st-assgn-hr-line {
    border: 1px solid #e0e0e0;
    margin: 0 20px 10px 20px;
  }
  
  /* .st-assgn-table-row:hover {
    cursor: pointer;
    background-color: aliceblue;
  } */
  
  .st-assgn-table-header > div,
  .st-assgn-table-row > div {
    padding: 10px;
    margin: 5px 20px;
  }
  
  
  .st-assgn-header,
  .st-assgn-table-body,
  .st-assgn-table {
    width: 100%; 
  }
  
  .st-assgn-table-level,
  .st-assgn-table-attempt,
  .st-assgn-table-marks,
  .st-assgn-table-header-level,
  .st-assgn-table-header-attempt,
  .st-assgn-table-header-marks{
    text-align: center;
  }
  
  /* score dashboard css */
  
  .st-assgn-section-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 10px 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #e0e0e0;
  }
  
  .st-assgn-section-header-left {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .st-assgn-section-header-title {
    font-size: 18px;
    font-weight: 500;
    color: var(--primary-color);
    margin-left: 10px;
  }
  
  .st-assgn-section-header-score {
    font-size: 16px;
    font-weight: 500;
    color: #00AD50;
    margin-left: 10px;
    border: 1px solid #00AD50;
    padding: 5px 10px;
    border-radius: 10px;
    background-color: #E4FFF1;
  }
  
  .st-assgn-section-body{
    display: flex;
    flex-direction: column;
    margin: 0 20px;
  }
  
  .st-assgn-section-body-part-2{
    height: 50vh;
    overflow-y: scroll;
  }
  
  .st-assgn-section-body-part-3{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
  
  .st-assgn-section-body-part-3-navigation{
    color: var(--primary-color);
    font-size: 16px;
    font-weight: 500;
    padding: 0 10px;
    text-decoration: underline;
    cursor: pointer;
  }
  
  /* score dashboard 1st css */
  
  .st-assgn__score-dashboard {
    display: flex;
    flex-direction: column;
    padding: 5px 15px;
  }
  
  .st-assgn__score-dashboard-first-section-1,
  .st-assgn__score-dashboard-first-section-2 {
    flex: 1;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    margin: 10px 0;
    padding: 15px 15px 5px 15px;
  }
  
  .st-assgn__score-dashboard-first-title {
    font-size: 16px;
    margin-bottom: 10px;
    font-weight: 500;
  }
  
  .st-assgn__score-dashboard-first-subtitle {
    font-size: 16px;
    color: #AAAAAA;
    margin-bottom: 10px;
  }
  
  .st-assgn__score-dashboard-first-answer {
    margin-bottom: 10px;
    line-height: 1.2;
  }
  
  .st-assgn__score-dashboard-first-answer-highlighted-text {
    background-color: #FFCACA;
    padding: 2px 5px;
    border-radius: 5px;
  }
  
  /* score dashboard 2nd css */
  .st-assgn__score-dashboard-second-section-1,
  .st-assgn__score-dashboard-second-section-2 {
    height: 200px;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    margin: 10px 0;
    overflow: hidden;
  }
  
  .st-assgn__score-dashboard-second-header{
    display: flex;
    background-color: #EDF9FF;
    align-items: center;
    padding: 0 10px;
  }
  
  .st-assgn__score-dashboard-second-title {
    font-size: 18px;
    color: var(--primary-color);
    padding: 10px;
    font-weight: 600;
  }
  
  .st-assgn__score-dashboard-second-subtitle {
    font-size: 16px;
    color: var(--primary-color);
    font-weight: 500;
    margin-bottom: 3px;
  }
  
  .st-assgn__score-dashboard-second-body{
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  
  .st-assgn__score-dashboard-second-body-1{
    display: flex;
    flex-direction: column;
    width: 50%;
    padding: 10px;
    overflow-y: scroll;
  }
  
  .st-assgn__score-dashboard-second-body-2{
    border-right: 2px solid #D4D4D4;
    width: 1%;
    height: 200px;
  }
  
  .st-assgn__score-dashboard-second-body-3{
    display: flex;
    flex-direction: column;
    width: 49%;
    padding: 10px;
    overflow-y: scroll;
  }
  
  .st-assgn__score-dashboard-second-body-3-1,
  .st-assgn__score-dashboard-second-body-3-2,
  .st-assgn__score-dashboard-second-body-3-3{
    margin-bottom: 7px;
  }
  
  .interview-questions-not-found-section{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 20px;
    font-weight: 500;
    color: #ccc;
  }
  
  .st-assgn-interview-loading{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }
  
  /* main css */
  
  .st-assgn-page{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
  
  .st-assgn-page-nav-menu{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    font-size: 14px;
    color: var(--tertiary-color);
    margin-top: -5px;
  }
  
  .st-assgn-page-nav-menu-home:hover{
    color: var(--primary-color);
    cursor: pointer;
  }
  
  .st-assgn-page-nav-menu-interview-preperation:hover{
    color: var(--primary-color);
    cursor: pointer;
  }
  
  .st-assgn-header {
    display: flex;
    justify-content: space-between;
    align-items:end;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  
  .st-assgn-header-title{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 20px;
    color: var(--primary-color);
    font-weight: bold;
    padding-bottom: 10px;
  }
  
  .st-assgn-body{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
  }
  
  .st-assgn-body-last-updated{
    font-size: 16px;
    color: var(--tertiary-color);
  }
  
  .st-assgn-section{
    display: flex;
    flex-direction: column;
    border: 1px solid #ccc;
    border-radius: 5px;
    height: 70vh;
    width: 100%;
    overflow-y: scroll;
  }