.ad-sk-asgnmt_sbms_usr-dtls-gd-section{
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 100%;
}

.ad-sk-asgnmt_sbms_usr-dtls-sub-section{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #ccc;
    background-color: #fff;
    padding: 20px;
    margin-top: 20px;
}

.ad-sk-asgnmt_sbms_usr-dtls-sub-section-tp{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #ccc;
    background-color: #fff;
    padding: 10px;
    margin-top: 20px;
}

.ad-sk-asgnmt_sbms_usr-dtls-sub-section-ts{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #ccc;
    background-color: #fff;
    padding: 10px;
    margin-top: 10px;
}

.ad-sk-asgnmt_sbms_usr-dtls-sub-section-text-ts{
    color: var(--primary-color);
    font-size: 16px;
    font-weight: 600;
    margin-top: 20px;
}

.ad-sk-asgnmt_sbms_usr-dtls-sub-section-text{
    color: var(--primary-color);
    font-size: 17px;
    font-weight: 600;
}

.ad-sk-asgnmt_sbms_usr-dtls-gd-section-grades{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 70%;
}

.ad-sk-asgnmt_sbms_usr-dtls-gd-section-grade-box{
    display: inline;
    text-align: center;
    border: 1px solid #898989;
    background-color: #F4F4F4;
    width: 50px;
    padding: 10px;
    margin-right: 10px;
    border-radius: 10px;
    color: #898989;
}

.ad-sk-asgnmt_sbms_usr-dtls-gd-section-grade-box:hover{
    cursor: pointer;
    background-color: #D2F0FF;
    color: var(--primary-color);
}

.ad-sk-asgnmt_sbms_usr-dtls-gd-section-grade-box-active{
    cursor: pointer;
    background-color: #D2F0FF;
    color: var(--primary-color);
    border-color: var(--primary-color);
}