.nav-content-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 15px 0; */
}

.nav-content-header-title {
  font-size: 24px;
  font-weight: medium;
  color: var(--primary-color);
}

.nav-content-header-dropdown {
  display: flex;
  justify-content: flex-end;
}

.admin-notification-pane {
  display: flex;
  align-items: center;
  margin-right: 20px;
  background-color: #E4F6FF;
  padding: 5px 15px;
  border-radius: 50%;
  cursor: pointer;
}

.admin-notification-pane:hover {
  background-color: #CCE6FF;
}