.upskillmargin{
    padding-top:90px;
}

.upskillNav{
    width: 100%;
    height: 3rem;
    background-color:#145169;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
}

.UpskillLink{
    color: white;
    font-size: 18px;
    padding-left:5%;
    padding-right:5%;
    text-decoration: none;
    margin-top: auto;
    margin-bottom: auto;
}

.upskill-log-out{
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    margin-left: 4rem;
    border-radius: 8px;
    padding-left: 12px;
    padding-right: 12px;
    border-color: transparent;
    color: orangered;
    font-size: 15px;
    font-weight:bold;
}