.st-skillbox-pathway-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 10px;
}

.st-skillbox-pathway-card__title {
  font-size: 19px;
  color: #004c73;
  font-weight: 700;
  border-bottom: 1px solid #ccc;
  width: 100%;
  /* height: 60px; */
  padding-bottom: 15px;
  margin-bottom: 10px;
}

.st-skillbox-pathway-card__description {
  font-size: 16px;
  color: #004c73;
  font-weight: bold;
  margin: 20px 0 10px 0;
}

.st-skillbox-pathway-card__tag {
  margin-top: 15px;
}

.st-skillbox-pathway-card__button {
  font-family: inherit;
  font-weight: bold;
  margin: 0;
  margin-top: 10px;
  color: #fff;
  background-color: #004c73;
  border: none;
  border-radius: 5px;
  padding: 10px 10px;
  cursor: pointer;
  width: 50%;
  margin: 10px 0;
}

.st-skillbox-pathway-card__button:hover {
  background-color: #003a54;
}

.st-skillbox-pathway-card_smp-text {
  font-size: 16px;
  margin: 10px 0;
}

.st-skillbox-pathway-card_smp-text span {
  color: #004c73;
  font-weight: 700;
}