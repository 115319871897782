.profile-contact-page {
  margin: 0 auto;
  width: 100%;
}

.profile-contact-input-section {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  height: 70vh;
  overflow-y: scroll;
}

.profile-contact-input-section-container-1 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-contact-input-section-container-2 {
  display: flex;
  justify-content: start;
  align-items: center;
}

.profile-contact-input-section-container-left {
  display: flex;
  flex-direction: column;
  width: 47%;
  margin-right: 20px;
}

.profile-contact-input-section-container-right {
  display: flex;
  flex-direction: column;
  width: 47%;
}

.profile-contact-input-college-email-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.profile-contact-input-phone-number-container {
  display: flex;
  width: 100%;
}

.profile-contact-input-web-links-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.profile-contact-input-web-links-sub-container {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.profile-input-address-container {
  display: flex;
  align-items: start;
  flex-direction: column;
  width: 100%;
}

.add-new-web-link-button {
  display: flex;
  justify-content: end;
  cursor: pointer;
  color: var(--primary-color);
  font-size: 14px;
  font-weight: bold;
  margin-right: 40px;
}

.profile-contact-input-web-links-delete-button {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 0 0 10px 0;
}

.add-new-web-link-button-error {
  display: flex;
  justify-content: end;
  cursor: not-allowed;
  color: red;
  font-size: 14px;
  font-weight: bold;
  margin-right: 50px;
}
