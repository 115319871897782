.sk-doubts-show-message-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 20px;
}

.sk-doubts-resolve-btn {
    padding: 8px 16px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    align-self: flex-end;
    margin-bottom: 16px;
}

.sk-doubts-resolve-btn:hover {
    background-color: #45a049;
}

.sk-doubts-message-list {
    flex: 1;
    overflow-y: auto;
    max-height: 300px;
    margin-bottom: 16px;
}

.sk-doubts-attachments {
    margin-bottom: 20px;
}

.sk-doubts-attachments img {
    border-radius: 8px;
    object-fit: contain;
    margin-bottom: 16px;
}

.sk-doubts-attachments button {
    padding: 8px 16px;
    background-color: #2196F3;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.sk-doubts-attachments button:hover {
    background-color: #1976D2;
}

.sk-doubts-message {
    margin-bottom: 16px;
    display: flex;
}

.sk-doubts-message__sender {
    justify-content: flex-end;
}

.sk-doubts-message__receiver {
    justify-content: flex-start;
}

.sk-doubts-message__content {
    max-width: 70%;
    padding: 12px;
    border-radius: 8px;
}

.sk-doubts-message__sender__text {
    background-color: #DCF8C6;
}

.sk-doubts-message__receiver__text {
    background-color: #E8E8E8;
}

.sk-doubts-message__content__text {
    margin-bottom: 4px;
    word-wrap: break-word;
}

.sk-doubts-message__content__time {
    font-size: 12px;
    color: #666666;
    text-align: right;
}

.sk-doubts-loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 40px;
}

.sk-doubts-loading-spinner {
    width: 40px;
    height: 40px;
    border: 4px solid #f3f3f3;
    border-top: 4px solid #2196F3;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin-bottom: 16px;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.sk-doubts-resolved-banner {
    background-color: #4CAF50;
    color: white;
    padding: 12px;
    border-radius: 8px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    cursor: not-allowed;
}

.sk-doubts-resolved-icon {
    margin-right: 8px;
    font-size: 20px;
}

.sk-doubts-image-container {
    position: relative;
    margin-bottom: 20px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.sk-doubts-image-container img {
    width: 100%;
    height: 400px;
    object-fit: contain;
    background-color: #f5f5f5;
}

.sk-doubts-download-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: 100%;
    padding: 12px;
    background-color: #2196F3;
    transition: background-color 0.3s ease;
}

.sk-doubts-message-form {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 10px;
    margin-top: auto;
    background: none;
    box-shadow: none;
    padding: 0px;
    border: 1px solid #e0e0e0;
}

.sk-doubts-message-form textarea {
    height: 28px;
    font-family: inherit;
    font-size: 16px;
    width: 100%;
    border: none;
    outline: none;
    resize: none;
    padding: 8px 12px;
    background: none;
    box-shadow: none;
}

.sk-doubts-message-form textarea::placeholder {
    color: #999;
}

.sk-doubts-form-actions {
    display: flex;
    gap: 12px;
    margin-top: 12px;
}

.sk-doubts-sending-indicator {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #666;
}

.sk-doubts-sending-spinner {
    width: 20px;
    height: 20px;
    border: 2px solid #f3f3f3;
    border-top: 2px solid var(--color-primary);
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

.sk-doubts-send-message-icon {
    background: none;
    border: none;
    box-shadow: none;
    cursor: pointer;
    padding: 0px 10px;
}

.sk-doubts-resolve-btn-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
}

.sk-doubts-resolve-btn {
    background-color: #4CAF50;
    margin: 0;
}
