/* Popup.css */

.custom_profile_popup{
  height: 100%;
  width: 100%;
  background-color: #FAFAFA;
}

.custom_profile_popup-header {
    color: #ffffff;
    padding: 10px;
    padding-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .custom_profile_popup-content {
    margin-top: 20px;
    padding: 20px;
    padding-top: 0;
  }
  
  .custom_profile_popup-close-button {
    padding: 20px;
    cursor: pointer;
  }
  
  /* Position the close button in the top right corner */
  .custom_profile_popup-close-button {
    position: absolute;
    top: 0;
    right: 0;
  }
  
  .custom_profile_popup-title {
    display: flex;
    align-items: center;
  }

  .custom_profile_popup-title h3 {
    color: #434343;
    font-size: 24px;
    font-weight: bold;
    margin: 0;
    font-family: 'Montserrat', sans-serif;
  }
  
  .custom_profile_popup-title-icon {
    margin-right: 10px;
    width: 20px;
  }