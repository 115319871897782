.profile-input-component-container-disabled{
  cursor: not-allowed;
}

.profile-input-datepicker-disabled {
  cursor: not-allowed; 
  opacity: 0.7;
}

.datepicker-container {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  background-color: #fff;
  /* border: 1px solid #ccc; */
  border-radius: 8px;
  /* padding: 0 10px; */
  margin-bottom: 10px;
  align-items: center;
  cursor: pointer;
}

.react-datepicker__header {
  background-color: #fff !important;
  border-bottom: 0 !important;
}

.react-datepicker__input-container input {
  width: 80%;
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 0;
  outline: none;
}

.react-datepicker__navigation--previous,
.react-datepicker__navigation--next
{
  margin: 0;
  border: 0;
  box-shadow: none;
  background-color: transparent;
}

.react-datepicker__navigation--previous:hover,
.react-datepicker__navigation--next:hover
{
  background-color: transparent;
}

.react-datepicker__navigation-icon--previous::before,
.react-datepicker__navigation-icon--next::before
{
  color: var(--primary-color);
}

.react-datepicker__navigation-icon--previous::after,
.react-datepicker__navigation-icon--next::after
{
  border-color: var(--primary-color);

}

/* .react-datepicker{
  display: none;
} */

.react-datepicker__navigation--years-upcoming {
  position: relative;
  top: 0;
}

.react-datepicker__navigation--years-upcoming::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-135deg);
  border: solid var(--primary-color);
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
}

.react-datepicker__navigation--years-previous {
  position: relative;
  top: 0;
}

.react-datepicker__navigation--years-previous::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  border: solid var(--primary-color);
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
}