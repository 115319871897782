.adminPage{
    padding-top: 5rem;
    display: flex;
    min-height: 30rem;
}

.admin-left-div{
    flex: 1;
    background-color: #eef6f4;
}

.admin-left-sec{
    background-color: #ddede9;
    color: #000;
    font-weight: 500;
    font-size: 18px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1rem;
}

.admin-left-sec:hover {
    cursor: pointer;
    background-color: #c2d6ca;
}

.admin-right-div{
    flex: 4;
    margin-top: -3rem;
}

.admin-right-div-free-course-enquiry{
    width: 82%;
}

