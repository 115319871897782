.interview-preperation-personal-interview-skill-container-wrapper {
    width: 100%;
    height: 460px;
}

.interview-preperation-personal-interview-skill-container {
    display: grid;
    grid-template-columns: repeat(6, minmax(0, 1fr));
    gap: 20px;
    column-gap: 20px;
    margin-bottom: 20px;
    width: 100%;
    max-height: 430px;
    overflow-y: auto;
}

.interview-preperation-personal-interview-skill-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    align-items: center;
    /* background-color: #f0f0f0; */
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 10px 15px;
    cursor: pointer;
    transition: all 0.3s ease;
    height: 100px;
    text-align: center;
}

.interview-preperation-personal-interview-skill-icon {
    max-height: 50px;
    margin-bottom: 2px;
}

.interview-preperation-personal-interview-skill-box:hover {
    background-color: #e0e0e0;
    border-color: #bbb;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.interview-preperation-personal-interview-skill-box.selected {
    background-color: #DFF4FF;
    border-color: #DFF4FF;
}

.interview-preperation-personal-interview-skill-line {
    width: 100%;
    height: 1px;
    margin: 0;
    padding: 0;
    background-color: #ccc;
}