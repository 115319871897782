/* Video Player Styles */
.video {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
  }
  
  .video-box {
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .module-flex2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f5f5f5;
  }
  
  h2 {
    color: #333;
    font-size: 1.5em;
    margin-bottom: 15px;
  }
  
  .video-player-div {
    position: relative;
    width: 100%;
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
    overflow: hidden;
  }
  
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .play-button-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .play-button {
    background-color: #3498db;
    color: #fff;
    font-size: 24px; /* Adjust the font size as needed */
    padding: 10px;
    cursor: pointer;
    border: none;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }  
  
  