:root{
    --profile-dropdown-list-select-color: #F9FAFB;
    --profile-icon-background-color: #A6E1FF;
}

.custom-profile-dropdown {
    position: relative;
    width: 200px;
    margin: 20px 0 20px 20px;
  }
  
  .profile-dropdown-header {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 25px;
  }
  
  .profile-user-info {
    display: flex;
    align-items: center;
  }

  .profile-pic-container{
    position: relative;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
    background-color: var(--profile-icon-background-color);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .profile-pic-image-container {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
  
  .online-indicator {
    position: absolute;
    bottom: 1px;
    right: 0;
    width: 8px;
    height: 8px;
    background-color: #4CAF50;
    border-radius: 50%;
  }
    
  .profile-pic {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .profile-username {
    font-weight: bold;
  }
  
  .profile-dropdown-arrow {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 10px;
  }
  
  .profile-dropdown-options {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-top: 2px;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    border-radius: 5px;
    background-color: #fff;
    /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
    z-index: 100;
  }
  
  .profile-dropdown-options li {
    padding: 15px 10px 15px 10px;
    margin: 0;
    cursor: pointer;
    transition: background-color 0.3s;
    display: flex;
    flex-direction: row ;
    justify-content: space-between;
  }
  
  .profile-dropdown-options li:hover {
    background-color: var(--profile-dropdown-list-select-color);
  }
  
  .profile-dropdown-options .selected {
    background-color: var(--profile-dropdown-list-select-color);
  }
  
  .profile-tick-icon {
    width: 16px;
    height: 16px;
    margin-left: 5px;
  }
  