.announcements-container {
  height: 80vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.announcements-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 16px 0;
}

.announcements-header__left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.announcements-header__title {
  font-size: 20px;
  color: var(--primary-color);
  font-weight: 700;
}

.announcements-count-badge {
  background: #ECFDF3;
  color: #027A48;
  padding: 6px 12px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 500;
}

.announcements-header__mark-as-read-btn {
  font-family: inherit;
  font-weight: bold;
  display: flex;
  align-items: flex-end;
  color: var(--primary-color);
  cursor: pointer;
  width: auto;
  text-decoration: underline;
  font-size: 18px;
  text-underline-offset: 4px;
}

.announcements-header__mark-as-read-btn:hover {
  color: #003a54;
}

.announcements-section {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.announcements-section h2 {
  font-size: 18px;
  color: #666;
  margin-bottom: 16px;
}

.announcements-list {
  flex: 1;
  padding-right: 16px;
}

.announcement-card {
  background: white;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  margin-bottom: 12px;
  transition: all 0.2s;
  cursor: pointer;
}

.announcement-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.announcement-card--unread {
  background-color: #f8faff;
  border-color: #e0e7ff;
}

.announcement-card--urgent {
  border-left: 4px solid #ff4757;
}

.announcement-card--info {
  border-left: 4px solid #2874f0;
}

.announcement-card--reminder {
  border-left: 4px solid #ffa502;
}

.announcement-card-title {
  font-size: 1.1rem;
  margin-bottom: 8px;
  color: #333;
}

.announcement-card-message {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 12px;
}

.announcement-card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.8rem;
}

.announcement-card-author-info {
  display: flex;
  gap: 8px;
}

.announcement-card-author-role {
  color: #1976d2;
  font-weight: 500;
}

.announcement-card-author-name {
  color: #666;
}

.announcement-card-timestamp {
  color: #888;
}

.announcement-card-header {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 8px;
}

.announcement-icon {
  font-size: 20px;
}

.announcement-icon.urgent {
  color: #ff4757;
}

.announcement-icon.info {
  color: #2874f0;
}

.announcement-icon.reminder {
  color: #ffa502;
}

.announcement-icon.default {
  color: #666;
} 