.interview-preperation-personal-interview-page{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.interview-preperation-personal-interview-page-nav-menu{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    font-size: 14px;
    color: var(--tertiary-color);
    margin-top: -5px;
}

.interview-preperation-personal-interview-page-nav-menu-home:hover{
    color: var(--primary-color);
    cursor: pointer;
}

.interview-preperation-personal-interview-page-nav-menu-other:hover{
    color: var(--primary-color);
    cursor: pointer;
}

.interview-preperation-personal-interview-header {
    display: flex;
    justify-content: space-between;
    align-items:end;
    margin-bottom: 10px;
    margin-top: 10px;
  }

.interview-preperation-personal-interview-header-title{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 20px;
    color: var(--primary-color);
    font-weight: bold;
    padding-bottom: 10px;
}

.interview-preperation-personal-interview-title{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 20px;
    color: var(--primary-color);
    font-weight: 600;
    padding-bottom: 5px;
}

.interview-preperation-personal-interview-subtitle{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 20px;
    color: var(--primary-color);
    padding-bottom: 10px;
}

.interview-preperation-personal-interview-body{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.interview-preperation-personal-interview-body-1{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin: 20px 0;
}

.interview-preperation-personal-interview-body-2{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.interview-preperation-personal-interview-body-3{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin-top: 15px;
}

.interview-preperation-personal-interview-body-4{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: start;
}

.interview-preperation-personal-interview-body-3-navigation{
    color: var(--primary-color);
    font-size: 16px;
    font-weight: 500;
    padding: 0 10px;
    text-decoration: underline;
    cursor: pointer;
  }

.interview-preperation-personal-interview-body-1-section-1{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.interview-preperation-personal-interview-body-1-section-1 > img{
    width: 350px;
}
  
.interview-preperation-personal-interview-body-1-section-2{
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    width: 60%;
}

.interview-preperation-personal-interview-body-1-section-2-1{
    display: flex;
    flex-direction: column;
    width: auto;
    gap: 5px;
    /* border: 1.5px solid var(--primary-color);
    padding: 20px; */
}

.interview-preperation-personal-interview-body-1-section-2-2{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
}

.interview-preperation-personal-interview-body-2-1{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.interview-preperation-personal-interview-body-2-2{
    display: flex;
    margin: 20px 0;
}

.interview-preperation-personal-interview-body-2-3{
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.interview-preperation-personal-interview-body-4-1{
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    justify-content: space-between;
    align-items: start;
    width: 96%;
}

.interview-preperation-personal-interview-body-4-2{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* margin-bottom: 50px; */
    width: 100%;
}

.interview-preperation-personal-interview-body-4-3{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.interview-preperation-personal-interview-cover-list{
    display: flex;
    align-items: center;
    width: fit-content;
    margin-bottom: 10px;
    color: var(--primary-color);
    font-weight: 500;
    font-size: 16px;
    background-color: #EDF9FF;
    padding: 5px 10px;
}

.interview-preperation-personal-interview-cover-list > svg{
    margin-right: 10px;
}

.interview-preperation-personal-interview-error{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 20px;
    font-weight: 500;
    margin: 60px 0;
    color: red;
}

.interview-preperation-personal-interview-info-page-nav-button{
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

