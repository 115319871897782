.rolesHistory{
    display: flex;
    flex-direction: row;
    gap: 5px;
    padding: 20px 0;
}

.rolesHistorySection1{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.rolesHistorySection1{
    gap: 29px;
}

.rolesHistoryHeader{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 20px;
    font-weight: bold;
    color: var(--primary-color);
}

.rolesHistoryHeaderContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.rolesHistoryHeaderIcon{
    margin-right: 10px;
    cursor: pointer;
}

.rolesHistorySubHeader{
    font-size: 20px;
    font-weight: 600;
    color: var(--primary-color);
}

.rolesHistoryHeaderButton{
    font-size: 14px;
    font-family: inherit;
    background-color: var(--primary-color);
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: auto;
    margin: 0;
}

.rolesHistoryHeaderButton:hover{
    background-color: #003855;
}

.rolesHistoryHeaderButton:disabled{
    background-color: #BCBCBC;
    cursor: not-allowed;
}