.queries-page {
  padding-top: 5rem;
  padding-left: 5rem;
  padding-right: 5rem;
  padding-bottom: 2rem;
  background: #eef6fc;
}

.main-heading {
  font-weight: 600;
  font-size: 24px;
  font-style: italic;
  background-image: linear-gradient(
    to right,
    #f84f20 0%,
    #044c74 120%,
    #c04848 100%
  );
  color: #fff;
  padding: 10px;
  line-height: 1.7em;
  box-shadow: 0 2px 10px 0 #cac9c9;
  text-align: left;
  user-select: none;
  border-left: 20px solid #044c74;
}

.queries-table {
  padding: 1rem;
  width: 100%;
}

.queries-table td {
  border: 1.8px solid #bbb;
  padding: 7px;
}

.queries-table th {
  background-color: #cce4de;
  border: 1.8px solid #bbb;
  padding: 14px;
}

.status-yellow {
  background: yellow;
  color: black;
  padding: 4px;
  border-radius: 10px;
}

.status-green {
  background: green;
  color: white;
  padding: 4px;
  border-radius: 10px;
}

.status-blue {
  background: blue;
  color: white;
  padding: 4px;
  border-radius: 10px;
}

.level-green {
  background: green;
  color: white;
  padding: 4px;
  border-radius: 10px;
}

.level-yellow {
  background: yellow;
  color: black;
  padding: 4px;
  border-radius: 10px;
}

.level-red {
  background: red;
  color: white;
  padding: 4px;
  border-radius: 10px;
}

.raise-query-btn {
  position: relative;
  right: 20px;
  top: -70px;
  margin-bottom: -70px;
  width: auto;
  float: right;
  border-radius: 8px;
  cursor: pointer;
}
