.stu-skillbox-certificates-page {
    height: 80vh;
}

.stu-skillbox-certificates-fixed-header {
    position: sticky;
    top: 0;
    background: white;
    z-index: 100;
    padding: 0;
}

.stu-skillbox-certificates-nav-header {
    font-size: 14px;
    color: var(--tertiary-color);
    margin-top: -5px;
}

.stu-skillbox-certificates-nav-header span:hover {
    color: var(--primary-color);
    cursor: pointer;
}

.stu-skillbox-certificates-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 20px 0;
}

.stu-skillbox-certificates-title h1 {
    color: var(--primary-color);
    font-size: 24px;
    margin: 0;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.stu-skillbox-certificates-select {
    display: flex;
    gap: 15px;
}

.stu-skillbox-certificates-select select {
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background: white;
    min-width: 200px;
}

.stu-module-certificates-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    padding: 10px 0;
    height: 60vh;
    overflow-y: auto;
    scroll-behavior: smooth;
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1;
}

.stu-module-certificates-grid::-webkit-scrollbar {
    width: 8px;
}

.stu-module-certificates-grid::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.stu-module-certificates-grid::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}

.stu-certificate-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.stu-certificate-popup-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 90%;
    max-height: 90%;
    overflow: auto;
    position: relative;
}

.stu-close-popup {
    position: absolute;
    right: 10px;
    top: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
}

.stu-certificate-actions {
    display: flex;
    gap: 10px;
    margin-top: 15px;
    justify-content: center;
}

.stu-certificate-actions button {
    background: var(--primary-color);
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
}

.stu-certificate-actions button:hover {
    background: #003855;
}

.stu-skillbox-cert-custom-select {
    position: relative;
    width: 300px;
    user-select: none;
    cursor: pointer;
}

.stu-skillbox-cert-selected-option {
    padding: 12px 16px;
    background: white;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    color: #333;
    transition: all 0.2s ease;
}

.stu-skillbox-cert-custom-select:hover .stu-skillbox-cert-selected-option {
    border-color: #004c73;
}

.stu-skillbox-cert-custom-select.open .stu-skillbox-cert-selected-option {
    border-color: #004c73;
    border-radius: 8px 8px 0 0;
}

.stu-skillbox-cert-select-arrow {
    transition: transform 0.2s ease;
}

.stu-skillbox-cert-custom-select.open .stu-skillbox-cert-select-arrow {
    transform: rotate(180deg);
}

.stu-skillbox-cert-options-container {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: white;
    border: 1px solid #004c73;
    border-top: none;
    border-radius: 0 0 8px 8px;
    max-height: 300px;
    overflow-y: auto;
    z-index: 1000;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.stu-skillbox-cert-option {
    padding: 12px 16px;
    transition: all 0.2s ease;
}

.stu-skillbox-cert-option:hover {
    background-color: #f5f5f5;
}

.stu-skillbox-cert-option.selected {
    background-color: #e6f3f7;
    color: #004c73;
}

.stu-skillbox-cert-loading {
    color: #666;
    font-style: italic;
}

.stu-skillbox-cert-custom-select.loading {
    opacity: 0.7;
    pointer-events: none;
}

.stu-skillbox-certificates-loading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 60vh;
}
