.company-based-practice {
    margin: 0 auto;
    padding: 20px;
  }
  
  .company-based-practice-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .company-based-practice-subtitle-info{
      font-size: 20px;
      color: var(--primary-color);
      font-weight: 600;
  }
  
  .company-based-practice-selects {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }
  
  .company-based-practice-horizontal-line{
      width: 100%;
      height: 1px;
      background-color: var(--primary-color);
      margin: 20px 0 20px 0;
      margin-bottom: 20px;
  }
  
  .company-based-practice-select-label {
    font-size: 16px;
    color: #333;
    font-weight: 500;
    margin-bottom: 5px;
  }
  
  .company-based-practice-title {
    font-size: 24px;
    font-weight: 600;
    color: var(--primary-color);
    margin-bottom: 10px;
  }
  
  .company-based-practice-subtitle {
    font-size: 18px;
    font-weight: 500;
    color: #333;
    margin-bottom: 10px;
  }
  
  .company-based-practice-top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .company-based-practice-test-left {
    display: flex;
    flex-direction: column;
  }
  
  .company-based-practice-test-right {
    color: var(--primary-color);
    font-size: 20px;
    font-weight: 600;
    margin: 5px 0 0 20px;
  }
  
  .company-based-practice-schedule-test,
  .company-based-practice-filter {
    margin-bottom: 20px;
  }
  
  .company-based-practice-tabs {
    display: flex;
    flex-direction: column;
    background-color: #f0f0f0;
  }
  
  .company-based-practice-tab {
    padding: 15px 20px;
    font-size: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .company-based-practice-tab:hover {
    background-color: #e0e0e0;
  }
  
  .company-based-practice-tab.active {
    background-color: var(--primary-color);
    color: #fff;
  }
  
  .company-based-practice-tab-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 20px;
    background-color: aliceblue;
    border: 1px solid #ccc;
    border-radius: 2px;
  }
  
  .company-based-practice-tab-container-section-1 {
    width: 30%;
    background-color: #f0f0f0;
    height: 40vh;
  }
  
  .company-based-practice-tab-container-section-2 {
    width: 70%;
    padding: 30px 20px 20px 20px;
  }

  .coding-practice-select-3 {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
  }
  