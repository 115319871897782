.student-assignment-pop {
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.box {
    position: relative;
    width: 85%;
    margin: 0 auto;
    height: auto;
    max-height: 70vh;
    margin-top: calc(100vh - 85vh - 20px);
    background: #fff;
    border-radius: 4px;
    padding: 20px;
    border: 1px solid #999;
    overflow: auto;
}

.close-icon {
    content: 'x';
    cursor: pointer;
    position: fixed;
    right: calc(15% - 127px);
    top: calc(100vh - 86vh - 9px);
    background: #c73c3c;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    line-height: 20px;
    text-align: center;
    border: 1px solid #999;
    font-size: 20px;
}

.assign-info {
    display: flex;
}

.submit-status {
    width: 30%;
    position: fixed;
    right: calc(1%);
    top: calc(25% - 1.5px);
    display: inline-block;
    padding: 0.5em 1em;
    margin-left: 5%;
    margin-right: 2%;
    /* color: #044c74;
    font-weight: bold;
    cursor: pointer; */
}

.file-content {
    width: 65%;
}

.add-heading {
    display: flex;
    margin-top: 2rem;
    margin-left: 25%;
}

.add-heading button {
    background-image: linear-gradient(to right, #f84f20 0%, #044c74 120%, #C04848 100%);
    width: fit-content;
    margin-top: 0.5rem;
    margin-left: 2rem;
    margin-right: 3rem;
}

.submission {
    display: flex;
    margin-top: 2rem;
    margin-left: 25%;
}

.submission div {
    margin-left: 10%;
}

.assingment-submit {
    background-image: linear-gradient(to right, #f84f20 0%, #044c74  120%, #C04848  100%);
    width: 5rem;
    margin-left: 50%;
}
