.profile-experience-page {
  margin: 0 auto;
  width: 100%;
}

.profile-experience-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.profile-experience-header-title {
  font-size: 20px;
  color: var(--primary-color);
  font-weight: bold;
}

.profile-experience-section-1 {
  display: flex;
  flex-direction: column;
  height: 70vh;
  overflow-y: scroll;
}

.profile-experience-section-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  height: 70vh;
}

.profile-experience-add-new {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: top;
  margin-top: 80px;
}

.profile-experience-add-new-title {
  font-size: 20px;
  color: #434343;
  font-weight: bold;
  margin-bottom: 7px;
}

.profile-experience-add-new-subtitle {
  font-size: 16px;
  color: var(--tertiary-color);
  margin-bottom: 17px;
}

.profile-experience-card {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 25px 30px 15px 30px;
  margin-bottom: 15px;
}

.profile-experience-card-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 10px;
}

.profile-experience-card-header-title {
  font-size: 19px;
  color: #434343;
  font-weight: bold;
  margin-bottom: 5px;
}

.profile-experience-card-body {
  display: flex;
  flex-direction: column;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 10px 0px;
  padding-right: 25px;
}

.profile-experience-card-body-container-1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.profile-experience-card-body-title {
  font-size: 15px;
  color: var(--tertiary-color);
  margin-bottom: 7px;
}

.profile-experience-card-body-title-1 {
  font-size: 16px;
  color: var(--tertiary-color);
  margin-bottom: 7px;
}

.profile-experience-card-body-data-value {
  font-size: 16px;
  color: #434343;
  font-weight: bold;
  margin-bottom: 10px;
}

.profile-experience-card-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
  padding-top: 3px;
}

.profile-experience-card-edit {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.profile-experience-card-edit-title {
  font-size: 16px;
  color: var(--primary-color);
  margin-left: 5px;
}

.profile-experience-card-delete {
  cursor: pointer;
}

/* form css */

.profile-exp-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.profile-exp-form-section {
  display: flex;
  flex-direction:row;
  justify-content: space-between;
  margin: 0 20px;
  width: 100%;
}

.profile-exp-form-section-checkmark {
  display: flex;
  flex-direction: column;
  width: 100%;
}