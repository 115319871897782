.sk-adm_popup {
  height: 100%;
  width: 100%;
  background-color: #fafafa;
}

.sk-adm_popup-header {
  color: #ffffff;
  padding: 10px;
  padding-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sk-adm_popup-content {
  padding: 20px;
  padding-top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sk-adm_popup-close-button {
  padding: 20px;
  cursor: pointer;
}

.sk-adm_popup-close-button {
  position: absolute;
  top: 0;
  right: 0;
}

.sk-adm_popup-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.sk-adm_popup-title h3 {
  color: var(--primary-color);
  font-size: 24px;
  font-weight: bold;
  margin: 0;
  font-family: "Montserrat", sans-serif;
}

.sk-adm_popup-title p{
  font-size: 16px;
  color: #777777;
}

.sk-adm_popup-title-icon {
  margin-right: 10px;
  width: 20px;
}
