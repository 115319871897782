.profile-about-page {
  margin: 0 auto;
  width: 100%;
}

.profile-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.profile-header-title {
  font-size: 20px;
  color: var(--primary-color);
  font-weight: bold;
}

.profile-image {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  object-fit: contain;
  clip-path: circle(50% at 50% 50%);
}

.profile-autosave {
  display: flex;
  align-items: center;
  color: var(--tertiary-color);
}

.profile-input-section {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  height: 70vh;
}

.profile-input-section-container-1 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.profile-input-section-container-2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.profile-image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 300px;
}

.profile-input-name-email-container {
  display: flex;
  flex-direction: column;
  margin: 0 10px;
  width: 100%;
}

.profile-input-name-container {
  display: flex;
  justify-content: flex-start;
}

.upload-profile-pic-button {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  margin-top: 10px;
  cursor: pointer;
  color: var(--tertiary-color);
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  font-size: 16px;
  text-decoration: underline;
}

.profile-input-first-name-container {
  display: flex;
  flex-direction: column;
  width: 30%;
  padding-right: 20px;
}

.profile-input-middle-name-container {
  display: flex;
  flex-direction: column;
  width: 30%;
}

.profile-input-last-name-container {
  display: flex;
  flex-direction: column;
  width: 30%;
  padding-left: 20px;
}

.profile-input-email-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.profile-input-section-container-left {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-right: 20px;
}

.profile-input-section-container-right {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.profile-loader-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  height: 70vh;
}

.profile-loader-container-for-loader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}
