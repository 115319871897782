/* ViewCourse.css */

.content_popup__content {
  font-size: 16px;
  line-height: 1.6;
}

.content_popup__content-row {
  display: flex;
  margin-bottom: 15px;
}

.content_popup__content-row-title {
  flex: 1;
  font-weight: bold;
  margin-right: 10px;
}

.content_popup__content-row-value {
  flex: 2;
}

/* Style links inside the popup content */
.content_popup__content a {
  color: #3498db;
  text-decoration: underline;
  cursor: pointer;
}

.content_popup__content a:hover {
  color: #2980b9;
}

/* Style paragraphs inside the popup content */
.content_popup__content p {
  margin: 0;
}

.content-url-container{
  overflow: hidden;
  text-overflow: scroll;
}

.content-url{
  color: #3498db;
  text-decoration: underline;
  user-select: text;
  cursor: 'text';
  width: 95%;
}

.content-url-copy-button{
  cursor: pointer;
  color: #031019;

}

.content-url-copy-text{
  margin-left: 2px;
  font-size: 14px;
  text-decoration: none;
}