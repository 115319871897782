.adminSmatPage{
    padding-top:6rem;
    padding-left: 5rem;
    padding-right: 5rem;
    background: #eef6fc;
    
}

.smat-admin-h{
    text-align: center;
}

.smat-admin-h h3{
    font-size: 25px;
}

.smat-info-flex{
    display: flex;
    margin-top: 2rem;
    flex-direction: row;
    
}

.smat-info-1{
    flex: 1;
    margin-right: 3rem;
}

.smat-info1-div{
    width: 100%;
    padding: 1%;
    padding-left: 2%;
    padding-right: 0%;
   
}

.info1-div-map{
    display: flex;
    flex-direction: row;
    background-color: white;
    margin-bottom: 2rem;
    border-radius: 8px;
    padding: 1%;
    box-shadow: rgba(29, 23, 23, 0.35) 0px 5px 15px;
}

.map-flex-img{
   flex: 0.8;
   align-self: center;
   text-align: center;
   justify-content: center;
   align-items: center;
   padding-left: 1%;
   
}

.map-flex-img img{
   
   border-radius: 6px;
   width: 100%;
}

.map-flex-content{
   flex: 2.2;
   padding-left: 1rem;
}

.map-flex-content1{
    flex: 1;
    align-self: center;
 }



.map-flex-content1 button{
    text-align: center;
    padding: 5px;
    padding-left: 16px;
    padding-right: 16px;
    font-size: 14px;
    font-weight: bolder;
    border-radius: 5px;
    text-decoration: none;
    border-color: transparent;
    background: linear-gradient(
      to right,
      red,
      rgba(247, 90, 17, 0.904),
      rgb(238, 130, 8)
    );
    color: white;
}

.smat-info-2{
    flex: 1;
    padding: 2%;
}

.smat-info-2 h2{
   text-align: center;
   color: rgb(29, 29, 68);
   font-size: 20px;
   margin-bottom: 2rem;
}

.info2-flex{
    display: flex;
    flex-direction: row;
}

.info2-flex h3{
    flex: 4;
    padding-left: 2rem;
    font-size: 17px;
}


.info2-flex p{
    flex: 1;
    font-size: 18px;
    text-align: center;
    font-weight: bold;
}