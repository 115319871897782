.sk-add-doubt-container {
  padding: 20px 40px;
}

.sk-add-doubt-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: transparent;
  box-shadow: none;
  padding: 0;
}

.sk-add-doubt-form-group {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.sk-add-doubt-form-group label {
  font-weight: 500;
  color: #434343;
  font-size: 14px;
}

.sk-add-doubt-form-group input,
.sk-add-doubt-form-group textarea {
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-size: 14px;
  width: 95%;
}

.sk-add-doubt-form-group input:focus,
.sk-add-doubt-form-group textarea:focus {
  outline: none;
  border-color: var(--primary-color);
}

.sk-add-doubt-form-group input.error,
.sk-add-doubt-form-group textarea.error {
  border-color: #dc3545;
}

.sk-add-doubt-error {
  color: #dc3545;
  font-size: 12px;
  margin-top: 4px;
}

.sk-add-doubt-actions {
  display: flex;
  justify-content: center;
  gap: 12px;
}

.sk-add-doubt-form-group textarea {
  resize: vertical;
  min-height: 50px;
  max-height: 60px;
}

.sk-add-doubt-form-row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.sk-add-doubt-form-group select {
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-size: 14px;
  width: 100%;
  background-color: white;
}

.sk-add-doubt-file-upload {
  display: flex;
  flex-direction: row;
  border: 2px dashed #e0e0e0;
  /* padding: 20px; */
  border-radius: 4px;
  text-align: center;
}


.sk-add-doubt-file-remove {
  background: none;
  border: none;
  color: #dc3545;
  cursor: pointer;
  font-size: 20px;
  padding: 0 5px;
}

.sk-add-doubt-file-remove:hover {
  color: #bd2130;
}

.sk-add-doubt-file-header {
  display: flex;
  align-items: center;
  /* margin-bottom: 10px; */
  /* padding: 8px 0; */
}

.sk-add-doubt-remove-all {
  background: none;
  border: none;
  font-family: inherit;
  color: #dc3545;
  cursor: pointer;
  font-size: 14px;
  padding: 4px 8px;
  border-radius: 4px;
  width: auto;
}

.sk-add-doubt-remove-all:hover {
  background-color: #ffebee;
}