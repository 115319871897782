.select-job-role {
    width: 340px;
}

.select-job-role > select {
    border-radius: 10px;
}

option {
    display: flex;
    padding: 10px;
}

.keyword-area {
    width: 42.6%;
    height: fit-content;
}

