.interview-platform-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.interview-platform-video-container {
  background-color: #ccc;
  width: 300px;
  height: 200px;
  border: 2px solid var(--primary-color);
  margin-bottom: 10px;
  position: relative;
}

.interview-platform-ques {
  font-weight: 600;
  color: var(--primary-color);
  font-size: 22px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.interview-platform-exam-btn {
  margin: 10px 0px 20px 0px;
}

.interview-platform-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

.interview-platform-btn-instructions{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  color: red;
  font-size: 14px;
}

.interview-platform-exam-instructions{
  margin-top: 15px;
  color:  #434343;
  font-size: 16px;
}

.interview-platform-btn-instructions > svg {
  margin-right: 5px;
}

.interview-question-timing {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #777777;
  font-size: 16px;
  margin-bottom: 5px;
}

.interview-question-timing-data {
  margin: 0 0 10px 5px;
  color: #FA1B00;
  font-size: 28px;
  font-weight: 700;
}

.interview-platform-btn-instructions > svg {
  animation: flashing-text-animation 1s infinite alternate;
  opacity: 0; 
}

@keyframes flashing-text-animation {
  0%, 100% {
    opacity: 1;
  }
  25%, 75% {
    opacity: 0;
  }
}
