.campus-programme-admin {
    padding-top: 4rem;
    padding-left: 2rem;
    padding-right: 4rem;
}

.campus-programme-admin-title {
    margin-left: 2rem;
    font-style: italic;
}

.campus-programme-admin-listing-table {
    flex: 1;
    margin-left: 2rem;
    margin-right: 3rem;
}

.campus-programme-admin-list {
    margin-left: 2rem;
    margin-right: 2rem;
}

.campus-programme-admin-list-title {
    border-style: ridge;
    padding-left: 10px;
    width: 70%;    
}

.campus-programme-admin-list-edit {
    width: 30%;
    text-align: right;
}

.edit-button{
    background-image: linear-gradient(to right, #f84f20 0%, #044c74  120%, #C04848  100%);
    cursor: pointer;
}