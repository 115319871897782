.profile-input-component-container {
  display: flex;
  flex-direction: column;
}

.profile-input-field-container-outer {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.profile-input-field-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.profile-input-field {
  padding: 8px 12px;
  border-radius: 8px;
  border: 1px solid #ccc;
}

.profile-input-field-err {
  border-color: red !important;
}

.profile-input-field-label {
  font-size: 14px;
  color: #434343;
  margin-bottom: 7px;
  font-weight: medium;
}

.profile-input-field::placeholder {
  color: var(--tertiary-color);
  font-size: 16px;
}

.profile-input-err-desc {
  color: red;
  font-size: 12px;
  margin-top: 0;
}