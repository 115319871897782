.assignment-status-tag {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  border-radius: 15px;
  padding: 5px 10px;
  margin: 0 5px 5px 0;
}

.assignment-status-tag-value{
    padding: 0 5px;
    border-radius: 15px;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    padding: 2px 5px;
    margin: 0 5px 0 0;
}