.apptest-page {
  padding-top: 5rem;
  padding-bottom: 2rem;
  background: #eef6fc;
}

.apptest-page h3 {
  margin-left: 5%;
  color: rgb(20, 20, 78);
  font-size: 28px;
}

.optionValue1 {
  color: #f34213;
  font-size: 20px;
  line-height: 3;
  font-weight: 600;
  padding-left: 2%;
}

.optionValue1:hover {
  background-color: rgba(220, 220, 220, 0.5);
  border-radius: 20px;
  /* padding-left:20px; */
  cursor: pointer;
}

.selected-option {
  color: #211b58;
  font-size: 20px;
  line-height: 3;
  font-weight: 600;
  padding-left: 2%;
  background-color: rgba(220, 220, 220);
  border-radius: 20px;
  /* padding-left:20px; */
  cursor: pointer;
}

.timer-box {
  padding: 3%;
  margin-top: -5rem;
  border: 3px solid #f34213;
  border-radius: 20px;
  width: fit-content;
  position: fixed;
  font-family: initial;
  right: 2%;
}

.question-box {
  margin-top: -2rem;
  font-family: initial;
  padding-left: 0%;
  margin-left: 0%;
  width: 67%;
}

.ques-no{
    font-family: initial;
    font-size: 12px;
}

.ques-status-x {
  display: flex;
  flex-direction: row;
}

.ques-status-no {
  flex-basis: 100%;
  height: 0;
  /* display: inline-block;  */
  break-after: always; /* New syntax */
  white-space: pre;
  /* display: block; */
  content: attr(data-text);
  content: "\A";
  line-break: strict;
}

.hjkloiu {
  display: flex;
  flex-direction: column;
}
