.admin-leads {
    padding: 1rem;
}

.admin-leads-heading {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid #ccc;
}

.admin-leads-heading h3 {
    text-align: left;
    width: 50%;
}

.admin-leads-heading a {
    display: flex;
    justify-content: flex-end;
    text-decoration: none;
    width: 50%;
    text-align: right;
}

.admin-leads-heading a button {
    background-image: linear-gradient(to right, #f84f20 0%, #044c74 120%, #C04848 100%);
    cursor: pointer;
}

.admin-leads-vertical-loading-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 200px;
    width: 100%;
}

.admin-leads-download-button {
    display: flex;
    text-decoration: none;
    width: 230px;
}

.admin-leads-download-button svg {
    margin-right: 10px;
    height: 20px;
}