.certificate-view-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.certificate-view-popup__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(4px);
}

.certificate-view-popup__content {
    position: relative;
    background: white;
    border-radius: 16px;
    padding: 24px;
    max-width: 90%;
    max-height: 90vh;
    width: auto;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.2);
    z-index: 1001;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.certificate-view-popup__close {
    position: absolute;
    top: 0px;
    padding: 0;
    right: 0px;
    width: 32px;
    height: 32px;
    margin: 0;
    border-radius: 50%;
    background: #ef4444;
    color: white;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.certificate-view-popup__close:hover {
    background: #dc2626;
    transform: scale(1.1);
}

.certificate-view-popup__image-container {
    overflow: auto;
    max-height: calc(90vh - 140px);
    border-radius: 8px;
}

.certificate-view-popup__image {
    width: 600px;
    height: auto;
    display: block;
    border-radius: 8px;
}

.certificate-view-popup__actions {
    display: flex;
    gap: 12px;
    justify-content: center;
    padding-top: 16px;
}

.certificate-view-popup__action-btn {
    display: flex;
    font-family: inherit;
    align-items: center;
    justify-content: center;
    margin: 0;
    gap: 8px;
    padding: 12px 24px;
    border-radius: 8px;
    border: none;
    background: #3b82f6;
    color: white;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
}

.certificate-view-popup__action-btn:hover {
    background: #2563eb;
    transform: translateY(-2px);
}

/* Responsive adjustments */
@media (max-width: 640px) {
    .certificate-view-popup__content {
        padding: 16px;
        width: 95%;
    }

    .certificate-view-popup__actions {
        flex-direction: column;
    }

    .certificate-view-popup__action-btn {
        width: 100%;
        justify-content: center;
    }
}
