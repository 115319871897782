.notification-popup {
  position: absolute;
  top: 60px;
  right: 0px;
  width: 360px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  padding: 16px;
  z-index: 1000;
}

.notification-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.notification-header__title {
  font-size: 19px;
  color: #004c73;
  font-weight: 600;
}

.new-tag {
  background: #ECFDF3;
  color: #027A48;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 500;
}

.notification-list {
  max-height: 300px;
  overflow-y: auto;
}

.notification-item {
  padding: 12px;
  border-radius: 8px;
  margin-bottom: 8px;
  display: flex;
  align-items: flex-start;
  transition: background-color 0.2s;
  cursor: pointer;
}

.notification-item:hover {
  background-color: #f8f9fa;
}

.notification-item.unread {
  background-color: #f1f5ff;
}

.notification-icon {
  margin-right: 12px;
  font-size: 18px;
  margin-top: 2px;
}

.notification-icon.urgent {
  color: #ff4757;
}

.notification-icon.info {
  color: #2874f0;
}

.notification-icon.reminder {
  color: #ffa502;
}

.notification-icon.default {
  color: #777777;
}

.notification-item.urgent {
  background-color: #fff8f8;
}

.notification-item.urgent:hover {
  background-color: #ffe8e8;
}

.notification-item.info {
  background-color: #f1f5ff;
}

.notification-item.info:hover {
  background-color: #e8eeff;
}

.notification-item.reminder {
  background-color: #fff9f0;
}

.notification-item.reminder:hover {
  background-color: #fff4e0;
}

.notification-content {
  flex: 1;
  margin-right: 8px;
}

.notification-content p {
  margin: 0;
  font-size: 14px;
  line-height: 1.4;
  color: #333;
}

.notification-time {
  font-size: 12px;
  color: #666;
  display: block;
  margin-top: 4px;
}

.unread-dot {
  width: 8px;
  height: 8px;
  background: var(--primary-color);
  border-radius: 50%;
  margin-left: 8px;
  flex-shrink: 0;
}

.notification-actions {
  display: flex;
  justify-content: space-between;
}

.notification-actions button {
  padding: 8px 16px;
  border-radius: 6px;
  border: none;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s;
}

.no-notifications {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px 16px;
  color: #666;
  text-align: center;
}

.no-notifications-icon {
  font-size: 32px;
  color: #ccc;
  margin-bottom: 12px;
}

.no-notifications p {
  margin: 0;
  font-size: 14px;
}


.notification-actions__mark-as-read-btn {
    font-family: inherit;
    font-weight: bold;
    display: flex;
    align-items: flex-end;
    color: #004c73;
    padding: 5px 0;
    cursor: pointer;
    width: auto;
}

.notification-actions__see-all-btn {
    font-family: inherit;
    font-weight: bold;
    margin: 0;
    color: #fff;
    background-color: #004c73;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    width: auto;
}

.notification-actions__see-all-btn:hover {
    background-color: #003a54;
}