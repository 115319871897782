.moderator-home {
    padding-top: 5rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 2rem;
    background: #eef6fc;
    display: flex;
}

.tab-flex {
    width: 20%;
    /* height: 60%; */
    margin-top: 1rem;
    margin-left: 1rem;
}

.info-flex {
    width: 70%;
}

.tab-flex-element {
    background-image: linear-gradient(to right, #eef6fc 0%, #044c74  120%, #eef6fc  100%);
    color: #000;
    font-weight: 500;
    font-size: 18px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1rem;
    cursor: pointer;
}

.dashboard-stats {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.dashboard-stats p {
    margin-top: 1rem;
    margin-left: 4.5rem;
    margin-right: 7rem;
    font-size: larger;
}

.info-flex h3 {
    text-align: left;
    margin-left: 2%;
    color: #000;
}

.subs-status-heading {
    display: flex;
}

.subs-status-heading-dropdown {
    margin-left: 25%;
    margin-top: 2rem;
    display: flex;
}

.subs-status-heading-dropdown select {
    margin-left: 0.5rem;
    height: fit-content;
    margin-top: 0.3rem;
}

.module-table td button {
    background-image: linear-gradient(to right, #f84f20 0%, #044c74  120%, #C04848  100%);
}

.download-stats{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.download-btn{
    margin-left: 2rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    background-image: linear-gradient(to right, #f84f20 0%, #044c74  120%, #C04848  100%);
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 0.5rem;
    cursor: pointer;
}

.download-btn img{
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.5rem;
    filter: invert(100%);
}