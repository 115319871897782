.attempted-assessment-page{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.attempted-assessment-page-nav-menu{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    font-size: 14px;
    color: var(--tertiary-color);
    margin-top: -5px;
}

.attempted-assessment-page-nav-menu-home:hover{
    color: var(--primary-color);
    cursor: pointer;
}

.attempted-assessment-page-nav-menu-interview-preperation:hover{
    color: var(--primary-color);
    cursor: pointer;
}

.attempted-assessment-header {
    display: flex;
    justify-content: space-between;
    align-items:end;
    margin-bottom: 10px;
    margin-top: 10px;
  }

.attempted-assessment-header-title{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 20px;
    color: var(--primary-color);
    font-weight: bold;
    padding-bottom: 10px;
}

.attempted-assessment-body{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
}

.attempted-assessment-body-last-updated{
    font-size: 16px;
    color: var(--tertiary-color);
}

.attempted-assessment-section{
    display: flex;
    flex-direction: column;
    border: 1px solid #ccc;
    border-radius: 5px;
    height: 70vh;
    width: 100%;
    overflow-y: scroll;
}