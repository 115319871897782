.profile-content-header-nav-menu-under-development {
    font-size: 14px;
    color: var(--tertiary-color);
    margin-top: -5px;
}

.profile-content-header-nav-menu-under-development:hover {
    color: var(--primary-color);
    cursor: pointer;
}

.under-development-page {
    display: flex;
    flex-direction: column;
    padding-top: 15px;
}

.under-development-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 75vh;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.construction-svg-container {
    width: 400px;
}

.under-development-text {
    font-size: 16px;
    margin-bottom: 20px;
    text-align: center;
    color: var(--tertiary-color);
}

.previous-version-button {
    background-color: var(--primary-color);
    color: white;
    padding: 10px 20px;
    margin: 0;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
    width: 200px;
}

.previous-version-button:hover {
    background-color: #0056b3;
}
