.popup-box {
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}
   
.box {
    position: relative;
    width: 70%;
    margin: 0 auto;
    height: auto;
    max-height: 70vh;
    margin-top: calc(100vh - 85vh - 20px);
    background: #fff;
    border-radius: 4px;
    padding: 20px;
    border: 1px solid #999;
    overflow: auto;
}
   
.close-icon {
    content: 'x';
    cursor: pointer;
    position: fixed;
    right: calc(15% - 38px);
    top: calc(100vh - 86vh - 9px);
    background: #c73c3c;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    line-height: 20px;
    text-align: center;
    border: 1px solid #999;
    font-size: 20px;
}

.close-icon1 {
    position: fixed;
    right: calc(15% - 30px);
    top: calc(100vh - 87vh);
}

.module-flex2{
    flex: 3;
    padding-left: 2rem;
    margin-left: 0.5rem;
}

.video-player-div{
    margin-top: 1rem;
    padding-bottom: 0.5rem;
    display: flex;
}
