.ad-assgn-lt-section {
    display: flex;
    flex-direction: column;
    border: 1px solid #ccc;
    border-radius: 5px;
    height: 65vh;
    width: 100%;
    overflow-y: scroll;
  }

.ad-assgn-lt-table-row-2,
.ad-assgn-lt-table-row-3,
.ad-assgn-lt-table-row-4,
.ad-assgn-lt-table-header-row-2,
.ad-assgn-lt-table-header-row-3,
.ad-assgn-lt-table-header-row-4 {
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.ad-assgn-lt-table-row-3,
.ad-assgn-lt-table-row-4 {
  text-decoration: underline;
  text-underline-offset: 1.5px;
  cursor: pointer;
}

.ad-assgn-lt-table-row-3{
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.ad-assgn-lt-table-row-3:hover,
.ad-assgn-lt-table-row-4:hover {
  color: var(--primary-color);
}

.ad-assgn-lt-table-icn-act path:hover {
  fill: var(--primary-color);
}