

.dropdown__row{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    padding: 10px;
    border-bottom: 1px solid #ddd;

}
.dropdown__side-actions{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
}

.custom-dropdown{
    position: relative;
    display: inline-block;
    width: 100%;
    margin-bottom: 10px;
}

.dropdown__side-actions > button{
    background: none;
    width: auto;
    height: auto;
    border: none;
    cursor: pointer;    
    margin: 0 5px;
    padding: 2px;
    box-shadow: none;
}

.dropdown__side-actions > button:hover{
    background: none;
}