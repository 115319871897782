.StudentViewIcon {
  color: #f5f5f5;
  font-size: 1.5rem;
  cursor: pointer;
}

.searchBoxAndFilterContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin: 20px 0 30px 0;
}

.StudentSearchBoxAdminPanel {
  padding: 15px;
  font-size: 16px;
  outline: none;
  border: none;
  width: 100%;
  padding-left: 20px;
}

.search-box {
    display: flex;
    align-items: center;
    width: 45%;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    padding-right: 10px;
    background-color: white;
    overflow: hidden;
}

.search-icon {
    cursor: pointer;
}

.orText {
  margin: 0 10px;
  color: var(--primary-color);
  font-size: 20px;
  font-weight: 550;
}

.StudentFilterDropdown {
    padding: 13px;
    width: 45%;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    font-size: 18px;
    outline: none;
    cursor: pointer;
    border: none;
}

.StudentFilterDropdown::after {
    content: '\25BC'; /* Unicode for downward arrow */
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    pointer-events: none; /* Ensure dropdown is clickable */
}

.students-container {
    width: 100%;
    overflow-x: hidden;
    padding: 20px 0px;
}

.datatable-wrapper {
    width: 100%;
    overflow-x: auto;
    scrollbar-width: none;
}