.stu-skillbox-attendance-page {
  height: 80vh;
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
}

.stu-skillbox-attendance-nav-header {
  font-size: 14px;
  color: var(--tertiary-color);
  margin-top: -5px;
}

.stu-skillbox-attendance-nav-header span:hover {
  color: var(--primary-color);
  cursor: pointer;
}

.stu-skillbox-attendance-fixed-header {
  position: sticky;
  top: 0;
  background: white;
  z-index: 100;
  padding: 0;
  border-bottom: 1px solid #eee;
  /* box-shadow: 0 2px 4px rgba(0,0,0,0.05); */
}

.stu-skillbox-attendance-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 20px 0;
  /* padding: 0 10px; */
}

.stu-skillbox-attendance-title h1 {
  color: var(--primary-color);
  font-size: 24px;
  margin: 0;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.stu-skillbox-attendance-timestamp {
  color: #666;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 5px;
}

.stu-skillbox-attendance-percentage {
  text-align: center;
  position: relative;
}

.stu-skillbox-attendance-content {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  scrollbar-width: thin;
  scrollbar-color: #888 #f5f5f5;
}

.stu-skillbox-attendance-content::-webkit-scrollbar {
  width: 8px;
}

.stu-skillbox-attendance-content::-webkit-scrollbar-track {
  background: #f5f5f5;
}

.stu-skillbox-attendance-content::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
  border: 2px solid #f5f5f5;
}

.stu-skillbox-attendance-stats {
  display: flex;
  gap: 30px;
  margin-bottom: 30px;
  flex-wrap: wrap;
}

.stu-skillbox-attendance-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  flex: 1;
  min-width: 300px;
}

.stu-skillbox-attendance-stat-card {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  text-align: center;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.stu-skillbox-attendance-stat-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 12px rgba(0,0,0,0.15);
}

.stu-skillbox-attendance-stat-icon {
  font-size: 28px;
  margin-bottom: 15px;
  opacity: 0.9;
  transition: all 0.3s ease;
  padding: 12px;
  border-radius: 50%;
  background-color: currentColor;
  color: white !important;
  width: 52px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 15px;
}

.stu-skillbox-attendance-stat-card:hover .stu-skillbox-attendance-stat-icon {
  transform: scale(1.1) rotate(5deg);
  box-shadow: 0 4px 12px rgba(0,0,0,0.15);
}

.stu-skillbox-attendance-stat-card h3 {
  margin: 0;
  color: #666;
  font-size: 16px;
}

.stu-skillbox-attendance-stat-card p {
  margin: 10px 0 0;
  font-size: 28px;
  font-weight: bold;
  background: linear-gradient(45deg, #333, #666);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.stu-skillbox-attendance-stat-card.stu-skillbox-attendance-total { 
  border-left: 4px solid #2196F3; 
  .stu-skillbox-attendance-stat-icon { background-color: #2196F3; }
}

.stu-skillbox-attendance-stat-card.stu-skillbox-attendance-remaining { 
  border-left: 4px solid #9e9e9e; 
  .stu-skillbox-attendance-stat-icon { background-color: #9e9e9e; }
}

.stu-skillbox-attendance-stat-card.stu-skillbox-attendance-present { 
  border-left: 4px solid #4CAF50; 
  .stu-skillbox-attendance-stat-icon { background-color: #4CAF50; }
}

.stu-skillbox-attendance-stat-card.stu-skillbox-attendance-absent { 
  border-left: 4px solid #f44336; 
  .stu-skillbox-attendance-stat-icon { background-color: #f44336; }
}

.stu-skillbox-attendance-chart {
  flex: 1;
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  min-height: 300px;
  min-width: 300px;
  transition: box-shadow 0.3s ease;
}

.stu-skillbox-attendance-chart:hover {
  box-shadow: 0 4px 12px rgba(0,0,0,0.15);
}

.stu-skillbox-attendance-chart-title {
  text-align: center;
  color: #333;
  font-size: 20px;
  margin-bottom: 5px;
  font-weight: 600;
}

.stu-skillbox-attendance-chart-tooltip {
  background: white;
  padding: 12px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.15);
  border: 1px solid #eee;
}

.stu-skillbox-attendance-chart-tooltip-label {
  font-weight: bold;
  margin: 0;
  color: #333;
}

.stu-skillbox-attendance-chart-tooltip-value {
  margin: 5px 0;
  color: #666;
}

.stu-skillbox-attendance-chart-tooltip-percentage {
  color: #888;
  font-size: 12px;
  margin: 0;
}

.stu-skillbox-attendance-actions {
  display: flex;
  gap: 15px;
  margin-bottom: 30px;
  flex-wrap: wrap;
}

.stu-skillbox-attendance-table-wrapper {
  background: white;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  overflow: hidden;
  transition: box-shadow 0.3s ease;
}

.stu-skillbox-attendance-table-wrapper:hover {
  box-shadow: 0 4px 12px rgba(0,0,0,0.15);
}

.stu-skillbox-attendance-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.stu-skillbox-attendance-table th,
.stu-skillbox-attendance-table td {
  padding: 12px 10px;
  text-align: left;
  border-bottom: 1px solid #eee;
}

.stu-skillbox-attendance-table th {
  /* background-color: #f8f9fa; */
  color: var(--primary-color);
  font-weight: 600;
  position: sticky;
  top: 0;
  z-index: 10;
  white-space: nowrap;
}

.stu-skillbox-attendance-table tbody tr {
  transition: background-color 0.2s ease;
}

.stu-skillbox-attendance-table tbody tr:hover {
  /* background-color: #f5f5f5; */
}

.stu-skillbox-attendance-status-badge {
  padding: 6px 12px;
  border-radius: 20px;
  font-size: 12px;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  transition: transform 0.2s ease;
}

.stu-skillbox-attendance-status-badge:hover {
  transform: scale(1.05);
}

.stu-skillbox-attendance-status-badge.stu-skillbox-attendance-status-present {
  background-color: #e8f5e9;
  color: #4CAF50;
}

.stu-skillbox-attendance-status-badge.stu-skillbox-attendance-status-absent {
  background-color: #ffebee;
  color: #f44336;
}

.stu-skillbox-recording-btn {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 6px 12px;
  border: none;
  background-color: #2196F3;
  color: white;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 12px;
  font-weight: 500;
}

.stu-skillbox-recording-btn:hover {
  background-color: #1976D2;
  transform: translateY(-2px);
  box-shadow: 0 2px 4px rgba(33, 150, 243, 0.3);
}

.stu-skillbox-attendance-loading {
  text-align: center;
  padding: 40px;
  color: #666;
  font-size: 16px;
}

.stu-skillbox-attendance-error {
  color: #f44336;
  padding: 12px 16px;
  margin: 10px 0;
  background-color: #ffebee;
  border-radius: 8px;
  border-left: 4px solid #f44336;
  font-size: 14px;
}

@media (max-width: 768px) {
  .stu-skillbox-attendance-stats {
    flex-direction: column;
  }
  
  .stu-skillbox-attendance-cards {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .stu-skillbox-attendance-header {
    flex-direction: column;
    gap: 20px;
    text-align: center;
  }
  
  .stu-skillbox-attendance-actions {
    justify-content: center;
  }
}

@media (max-width: 480px) {
  .stu-skillbox-attendance-cards {
    grid-template-columns: 1fr;
  }
  
  .stu-skillbox-attendance-table th,
  .stu-skillbox-attendance-table td {
    padding: 8px;
    font-size: 12px;
  }
}

.stu-skillbox-attendance-status {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 16px 24px;
  border-radius: 12px;
  transition: all 0.3s ease;
}

.stu-skillbox-attendance-status-icon {
  font-size: 24px;
}

.stu-skillbox-attendance-details {
  display: flex;
  flex-direction: column;
}

.stu-skillbox-attendance-value {
  font-size: 24px;
  font-weight: bold;
}

.stu-skillbox-attendance-label {
  font-size: 14px;
  opacity: 0.8;
}

.stu-skillbox-attendance-status-good {
  background-color: rgba(76, 175, 80, 0.1);
  color: #4CAF50;
}

.stu-skillbox-attendance-status-average {
  background-color: rgba(255, 152, 0, 0.1);
  color: #FF9800;
}

.stu-skillbox-attendance-status-poor {
  background-color: rgba(244, 67, 54, 0.1);
  color: #f44336;
}

.stu-skillbox-attendance-title-cell {
  max-width: 250px; /* Adjust this value as needed */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.stu-skillbox-attendance-instructor-cell {
  max-width: 150px; /* Adjust this value as needed */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.stu-skillbox-attendance-no-data {
  text-align: center;
  padding: 2rem;
  font-size: 1.2rem;
  color: #666;
}