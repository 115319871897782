.sc-container {
  background: #fff;
}

.sc-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.sc-header-title {
  font-size: 24px;
  font-weight: 600;
  color: #004c73;
}

.sc-filter-section {
  display: flex;
  align-items: center;
}

.sc-filter-select {
  padding: 8px 16px;
  border-radius: 4px;
  border: 1px solid #004c73;
  min-width: 200px;
  color: #004c73;
  background-color: white;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 24 24' fill='none' stroke='%23004c73' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 12px center;
  padding-right: 36px;
}

.sc-filter-select:hover {
  border-color: #003557;
  box-shadow: 0 2px 4px rgba(0, 76, 115, 0.1);
}

.sc-filter-select:focus {
  outline: none;
  border-color: #003557;
  box-shadow: 0 0 0 2px rgba(0, 76, 115, 0.2);
}

.sc-body {
  background: #fff;
  border-radius: 8px;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  border: 1px solid #ccc;
}

.sc-section {
  padding: 5px;
}

.sc-loading {
  display: flex;
  justify-content: center;
  padding: 40px;
}

.sc-not-found {
  text-align: center;
  padding: 40px;
  color: #666;
  font-size: 16px;
}

.sc-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.sc-popup {
  background: white;
  padding: 24px;
  border-radius: 12px;
  width: 100%;
  max-width: 500px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

.sc-popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 24px;
  font-weight: 600;
  color: #004c73;
  margin-bottom: 24px;
  padding-bottom: 12px;
  border-bottom: 2px solid #e5e7eb;
}

.sc-popup-content {
  margin-bottom: 24px;
}

.sc-detail-row {
  display: flex;
  padding: 12px 0;
  border-bottom: 1px solid #f3f4f6;
}

.sc-detail-row:last-child {
  border-bottom: none;
}

.sc-detail-label {
  flex: 0 0 120px;
  color: #6b7280;
  font-weight: 500;
}

.sc-detail-value {
  flex: 1;
  color: #111827;
  font-weight: 500;
}

.sc-popup-btn {
  width: 100%;
  margin-top: 8px;
}

.sc-detail-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  padding: 1rem;
}

.sc-detail-item {
  background-color: #f5f5f5;
  padding: 1rem;
  border-radius: 8px;
}

.sc-detail-label {
  font-weight: bold;
  color: #004c73;
  margin-bottom: 0.5rem;
}

.sc-detail-value {
  color: #333;
}

.sc-close-icon {
  font-size: 24px;
  cursor: pointer;
  color: #004c73;
  transition: color 0.2s ease;
}

.sc-close-icon:hover {
  color: #ff0000;
}
