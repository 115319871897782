.ad-sk-asgnmt_sbms_usr-dtls-ntfy-section{
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 100%;
}

.ad-sk-asgnmt_sbms_usr-dtls-ntfy-sub-section{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #ccc;
    background-color: #fff;
    padding: 20px;
    margin-top: 20px;
}

.ad-sk-asgnmt_sbms_usr-dtls-ntfy-sub-section-text{
    color: var(--primary-color);
    font-size: 16px;
    font-weight: 600;
}