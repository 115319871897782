.list-assignments {
    padding-top: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 2rem;
    background: #eef6fc;
}

.assign-list {
    padding-top: 1rem;
    padding-left: 2rem;
    padding-right: 1rem;
    padding-bottom: 2rem;
}

.list-assign-row-table {
    width: 100%;
    padding: 1rem;
    margin-left: 2rem;
    border: 1px solid
}

.list-assign-row-table th {
    text-align: center;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
    padding-bottom: 4px;
    border: 1px solid
}

.list-assign-row-table td {
    text-align: center;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
    padding-bottom: 4px;
}

.list-assign-row-table button{
    background-image: linear-gradient(to right, #f84f20 0%, #044c74 120%, #C04848 100%);
    width: auto;
}