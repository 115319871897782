.st-skillbox-interview-type-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease;
  margin: 10px;
}

.st-skillbox-interview-type-card:hover {
  transform: scale(1.02);
  background-color: #e0f7fa;
  border: none;
}

.st-skillbox-interview-type-card__img {
  width: 100%;
  height: 150px;
  object-fit: contain;
  border-bottom: 1px solid #ccc;
}

.st-skillbox-interview-type-card__title {
  font-size: 19px;
  color: #434343;
  width: 100%;
  margin-top: 10px;
}

.st-skillbox-interview-type-card:hover .st-skillbox-interview-type-card__title {
  color: #004c73;
  font-weight: 700;
}
