.coding-apptest-page {
  height: 100vh;
  background: #eef6fc;
}

.coding-ques-page {
  height: 100%;
  background: #0e141e;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.apptest-page h3 {
  color: rgb(20, 20, 78);
  font-size: 22px;
}

.coding-head-fixed{
  width: 100%;
  height: fit-content;
  top: 0;
  left: 0;
}

.coding-exam-heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.coding-exam-surge-head{
  width: 90%;
  display: flex;
  margin: 20px 0 0 0;
  justify-content: space-between;
}

.coding-exam-surge-heading-bottom{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin-top:0;
  margin: 0 0 20px 0;
}


.coding-exam-surge-head-part-left{
  display: flex;
  flex-direction: row;
}

.coding-flex-item{
  flex: 1;
}

.coding-flex-item:nth-child(1) {
  flex: 2;
  text-align: start;
}

.coding-flex-item:nth-child(2) {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 10px;
}

.coding-exam-surge-head-part-left  img {
  max-height: 30px;
  max-width: auto;
  margin-right: 10px;
  margin-top: 20px;
}

.coding-exam-surge-head-part-left div {
  font-size: 30px;
  font-weight: bold;
  color: #fff;
  margin: 10px 10px 0 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.coding-exam-surge-head-part-left svg {
  margin-top: 23px;
  margin-right: 10px;  
}

.coding-exit-btn {
  cursor: pointer;
  padding: 5px;
  margin-top: 20px;
  display: flex;
  justify-content: end;
}

.coding-test-total-data p{
  margin: 0;
  font-size: 16px;
  font-weight: normal;
  padding-top: 5px;
  color: #8c96a6;
}

.coding-timer {
  display: flex;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  font-size: 24px;
  text-align: center;
  width: fit-content;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 5px 10px;
  animation: flash 1s infinite;
}

@keyframes flash {
  0% {
    background-color: #fff;
  }
  50% {
    background-color: #cbe1f0;
  }
  100% {
    background-color: #fff;
  }
}

.coding-timer p {
  margin: 0;
}

.coding-timer span {
  color: #e74c3c;
  font-weight: normal;
}

.coding-timer svg {
  margin-right: 5px;
  margin-top: 3.5px;
}


#coding-exit-btn {
  width: 30px;
  height: 30px;
  fill: #000;
}

body:fullscreen {
  overflow: scroll !important;
}
body:-ms-fullscreen {
  overflow: scroll !important;
}
body:-webkit-full-screen {
  overflow: scroll !important;
}
body:-moz-full-screen {
  overflow: scroll !important;
}


.coding-practice-test {
  width: 90%;
  height: 70%;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  background-color: #1d2432;
  background-image: url("../../assets/python.svg");
  background-repeat: no-repeat;
  background-position-y: bottom;
  background-position-x: right;
  background-size: 30%;
}

.coding-question-card {
  display: flex;
  justify-content: space-between;
  align-items: top;
  padding: 15px;
  width: 95%;
}

.coding-question-card:hover{
  cursor:default;
}

.coding-question-card-left-top-question{
  text-align: left;
  color: #deeafa;
  width: 100%;
  margin: 0;
  padding: 0;
  font-weight: 300;
}

.coding-question-card-left {
  display: flex;
  flex-direction: column;
  width: 70%;
}

.coding-question-card-left-bottom {
  display: flex;
}

.coding-question-card-left-top-level,
.coding-question-card-left-bottom-time,
.coding-question-card-left-bottom-score {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 5px;
}

.coding-question-card-left-top-level p,
.coding-question-card-left-bottom-time p,
.coding-question-card-left-bottom-score p{
  font-size: 16px;
  color: #738f93;
  margin-right: 10px;
  font-weight: 400;
  font-family: 'Helvetica Neue', Arial, sans-serif;
}

.coding-test-question-tags{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 0;
  padding: 0;
}

.coding-test-question-tags .coding-question-card-left-top-level p,
.coding-test-question-tags .coding-question-card-left-bottom-time p,
.coding-test-question-tags .coding-question-card-left-bottom-score p{

  margin-right: 10px;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  white-space: pre-wrap;
  font-size: 14px;
  color: #b5c0d0;
  line-height: 2;
  font-family : OpenSans, Arial, Helvetica, sans-serif;
}

.coding-test-question-tags .coding-question-card-left-top-level svg{
  width: 25px;;
}

.coding-test-question-tags .coding-question-card-left-bottom-time svg{
  width: 23px;
}

.coding-test-question-tags .coding-question-card-left-bottom-score svg{
  width: 20px;
}

.coding-question-card-left-top-level svg{
  width: 35px;
  height: auto;
  margin-right: 5px;
  padding: auto;
}

.coding-question-card-left-bottom-time svg{
  width: 30px;
  height: auto;
  margin-right: 5px;
  padding: auto;
}

.coding-question-card-left-bottom-score svg {
  width: 25px;
  height: auto;
  margin-right: 5px;
  padding: auto;
}

.coding-question-card-right{
  width: 15%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
}

.coding-question-button {
  background-color: #5B8E57;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 10px;
  width: fit-content;
  cursor: pointer;
  transition: background-color 0.3s;
  margin: 0;
}

.coding-question-button.inactive{
  background-color: skyblue;
  color: black;
  cursor: not-allowed;
}

.coding-question-button.inactive:hover {
  background-color: skyblue;
}

.coding-submit-button {
  background-color: #4C679B;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  color: #fff;
  border: none;
  padding: 12px 40px;
  border-radius: 12px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: fit-content;
  margin: 0;
}


.coding-question-card-right button:hover {
  background-color: #2980b9;
}

/* coding-page */

.coding-test{
  background-color: #D9D9D9;
}

.coding-test-flex-container{
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
  height: 90vh;
  flex:1;
  background-color: #050c1b;
}

.coding-test-flex-container-left{
  width: 39%;
  padding: 10px;
  color: #fff;
  background-color: #111827;
  height: 85vh;
  overflow: scroll;
}

.coding-test-flex-container-middle{
  width: 1%;
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.vertical-line {
  border-left: 2px solid #fff;
  height: 30px;
  margin: 0;
  cursor: col-resize;
}

.coding-test-flex-container-middle:hover .vertical-line {
  border-left: 2px solid #3498db;
  height: 100%;
}

.coding-test-flex-container-right{
  width: 60%;
  margin: 15px 10px 15px 2px;
  height: 80vh;
}

.coding-test-editor{
  height: 60%;
  background-color: #1d2432;
  display: flex;
  flex-direction: column;
  justify-content: start;
  border-radius: 10px;
  padding: 10px 0 0 0;
  margin-bottom: 2px;
  overflow: hidden;
}

.coding-test-editor-buttons{
  display: flex;
  justify-content: start;
  width: 60%;
  align-items: center;
}

.coding-test-flex-container-right-middle{
  width: 100%;
  height: 1%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.horizontal-line {
  border-top: 2px solid #fff;
  width: 30px;
  margin: 0;
  cursor: row-resize;
}

.coding-test-flex-container-right-middle:hover .horizontal-line {
  border-top: 2px solid #3498db;
  width: 100%;
}

/* heading */
.coding-test-heading-container {
  height: 8vh;
  padding: 10px;
  margin: 0;
  background-color: #0e141e;
}

.coding-test-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.coding-test-heading-left {
  display: flex;
  align-items: center;
  width: 40%;
}

.coding-test-heading-left-logo img {
  max-width: 30px;
  margin-right: 10px;
  height: auto;
}

.coding-test-heading-left-title h1 {
  margin: 0;
  margin-left: 10px;
  font-weight: 400;
  color: #fff;
  font-size: 24px;
  max-width: 500px;
  text-overflow:ellipsis;
  word-wrap: no-wrap;
}

.coding-hamburger-nav-icon{
  cursor: pointer;
  padding-top: 10px;
}

.coding-test-heading-right {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 60%;
}
.coding-test-editor-buttons-reset-button {
  margin: 5px 10px 5px 0;
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 8px 15px;
  cursor: pointer;
  background-color: #e74c3c;
  color: #fff;
  border: none;
  border-radius: 3px;
  font-family: 'Helvetica Neue', Arial, sans-serif;
}

.coding-test-editor-buttons-run-button {
  margin: 5px 10px 5px 0;
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 8px 15px;
  cursor: pointer;
  background-color: #3498db;
  color: #fff;
  border: none;
  border-radius: 3px;
  font-family: 'Helvetica Neue', Arial, sans-serif;
}


.coding-test-editor-buttons-submit-button {
  margin: 5px 10px 5px 0;
  padding: 10px 15px;
  width:fit-content;
  cursor: pointer;
  background-color: #32c766;
  color: #fff;
  border: none;
  border-radius: 3px;
  font-family: 'Helvetica Neue', Arial, sans-serif;
}
.coding-test-editor-buttons-submit-button:hover {
  background-color: #2bb357;
  color: #050c1b;
}

.coding-test-close{
  cursor: pointer;
  padding: 5px;
  display: flex;
  justify-content: end;
}

.coding-test-close-icon {
  cursor: pointer;
  font-size: 20px;
  color: #e74c3c;
}

.coding-test-close-icon:hover {
  color: #c0392b;
}

.coding-test-question h3 {
  margin: 0;
  padding: 0;
  font-size: 20px;
  font-weight: 400;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  text-align: left;
  color: #d4e4f9;
}

.coding-test-question h4{
  margin: 0;
  margin-top: 10px;
  padding: 0;
  font-size: 16px;
  font-weight: 500;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  text-align: left;
  color: #d4e4f9;
}

.coding-test-question pre{
  white-space: pre-wrap;
  font-size: 14px;
  color: #b5c0d0;
  line-height: 2;
  font-family: OpenSans, Arial, Helvetica, sans-serif;
}

/* output */

.coding-test-results{
  width: 100%;
  height: 39%;
  margin-top: 2px;
  overflow: scroll;
  background-color: #1d2432;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
}

.coding-test-results-heading {
  display: flex;
  justify-content: start;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(196, 185, 185, 0.1);
}

.coding-test-custom-input,
.coding-test-results-testcases-results {
  cursor: pointer;
  padding: 2px 10px 2px 10px;
  font-weight: bold;
}

.coding-test-custom-input {
  border-right: 1px solid #ccc;
  padding-left: 0;
}

.coding-test-custom-input.active,
.coding-test-results-testcases-results.active {
  background-color: #4caf50;
  color: white;
}

.coding-test-custom-input-box {
  margin: 10px 0;
}

.coding-test-custom-input-box-textarea {
  width: 70%;
  height: 100px;
  padding: 10px;
  margin: 10px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 5px;
  resize: vertical;
}

.coding-test-case-results-div {
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
}

.coding-test-output-box {
  margin: 0 0 10px 0;
  padding: 0 10px 10px 10px;
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.coding-test-output-box-error {
  color: red;
}

.coding-test-output-box-success {
  color: green;
}

.code-testcase-input {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: start;
}

.coding-test-case {
  cursor: pointer;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.coding-test-case.active {
  background-color: #4caf50;
  color: white;
}

.coding-testcase-input,
.coding-testcase-expected,
.coding-testcase-output {
  margin-top: 10px;
}

.coding-test-output-box-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px;
}

.coding-test-output-box-error-div {
  color: white;
  font-weight: bold;
  font-size: 20px;
}

.coding-test-editor-language-select{
  width: 25%;
  margin: 5px;
  margin-top: 0;
  height: 30px;
  padding: 2px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  background-color: #050c1b;
  color: #fff;
}

.coding-test-question-description p{
  margin: 10px 0;
  font-size: 16px;
  font-weight: 400;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  text-align: left;
  color: #b5c0d0;
}

.coding-test-question-description ul{
  list-style: circle !important;
}

.code-test-question-description ul li{
  font-size: 10px;
}

.code-test-question-description ul li code{
  all: unset;
}

.coding-run-loading{
  height: 24px;
  width: 24px;
  margin-right: 10px;
}

.coding-test-output-box-success-score {
  color: #32c766;
  font-size: 20px;
  font-weight: bold;
}