.qt-roles-container {
  padding: 20px 0px;
}

.qt-roles-header {
  display: flex;
  flex-direction: row;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  color: var(--primary-color);
}

.qt-roles-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin: 20px 0 30px 0;
}

.qt-search-container-input {
  padding: 15px;
  font-size: 16px;
  outline: none;
  border: none;
  width: 100%;
  padding-left: 20px;
}

.qt-search-box {
  display: flex;
  align-items: center;
  width: 45%;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  padding-right: 10px;
  background-color: white;
  overflow: hidden;
}

.qt-search-box:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

.qt-add-role-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 45%;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  padding: 17px;
  color: #777777;
  background-color: white;
  overflow: hidden;
  cursor: pointer;
}

.qt-add-role-btn:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

.qt-roles-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  padding: 10px 0;
  max-height: 55vh;
  overflow-y: scroll;
}

.qt-role-box {
  background-color: white;
  border: none;
  border-radius: 20px;
  padding: 15px;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.qt-role-box:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  background-color: var(--primary-color);
  color: white;
  font-weight: bold;
}

.qt-add-role-input-container {
  position: relative;
  width: 45%;
}

.qt-add-role-input-wrapper {
  display: flex;
  align-items: center;
  gap: 0px;
}

.qt-add-role-input {
  width: 90%;
  padding: 12px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 18px;
}

.qt-add-role-input.error {
  border-color: red;
}

.qt-add-role-error {
  color: red;
  font-size: 12px;
  position: absolute;
  top: -20px;
  left: 0;
  width: 100%;
}

.qt-add-role-button {
  margin: 0px;
  padding: 15px;
  cursor: pointer;
  background-color: var(--primary-color);
  border-radius: 0 10px 10px 0;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.qt-add-role-button svg path {
  fill: white;
}

.qt-add-role-button:hover {
  background-color: #003855;
}

.qt-add-role-button:hover svg path {
  fill: white;
}

.qt-add-role-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.qt-add-role-button:disabled svg path {
  fill: #999;
}

.qgt-slider-container-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;
}

.qgt-slider-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
  width: 100%;
}

.qgt-slider-label {
  display: block;
  font-size: 18px;
  font-weight: bold;
  color: var(--primary-color);
  margin-bottom: 20px;
  padding: 0 10px;
}

.qgt-slider-wrapper {
  display: flex;
  align-items: center;
}

.qgt-slider-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
}

.qgt-slider-input-wrapper {
  position: relative;
  flex-grow: 1;
  margin: 0 10px;
}

.qgt-slider {
  width: 100%;
  border-radius: 5px;
  border: none;
  accent-color: var(--primary-color);
}

.qgt-slider:hover {
  opacity: 1;
}

.qgt-slider[type="range"]:focus {
  outline: none;
}

.qgt-slider-value {
  position: absolute;
  top: 25px;
  transform: translateX(-50%);
  color: var(--primary-color);
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 5px;
  border-radius: 3px;
  font-size: 16px;
  font-weight: bold;
}

.qgt-progress-value {
    color: var(--primary-color);
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 5px;
    border-radius: 3px;
    font-size: 16px;
    font-weight: bold;
  }

.qgt-generate-csv-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.qgt-generate-button,
.qgt-action-button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 15px;
  margin: 5px;
  cursor: pointer;
  border-radius: 4px;
  font-family: 'Inter', sans-serif;
}

.qgt-success-actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.qgt-progress-bar {
  width: 70%;
  background-color: #ddd;
  height: 10px;
  margin-top: 10px;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 20px;
}

.qgt-progress {
  height: 100%;
  background-color: var(--primary-color);
  transition: width 0.5s ease-in-out;
}

.qgt-error-actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  text-align: center;
}

.qgt-error-message {
  color: red;
  margin-bottom: 10px;
}

.qgt-success-message-text {
  font-size: 16px;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
  line-height: 1.5;
  padding: 10px;
  background-color: #e6f7e6;
  border-radius: 5px;
  border: 1px solid #c3e6cb;
}

.qt-error-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
}

.qt-roles-loading {
  margin-bottom: 10px;
}

.qt-roles-error-message {
  color: #d9534f;
  font-size: 16px;
  text-align: center;
  margin-top: 10px;
}

.qt-role-box-question-count {
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-top: 8px;
}

.qt-role-box-question-count-text {
  font-weight: bold;
  margin-right: 4px;
  font-size: 12px;
}

.qgt-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

