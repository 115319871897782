/* import codinggeek.jpeg from assets */

.coding-page {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
  }

  .coding-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    flex-direction: column;
    background-image: url("../../assets/algorithm.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: bottom;
    background-position-x: right;
    background-size: 30%;
    background-color: #0e141e;
  }
  
  .coding-content {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background-color: #1d2432;
    margin-top: 120px;
    margin-bottom: 30px;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    width: 50%;
  }

  .coding-content h1 {
    color: #deeafa;
  }
  
  .coding-instructions {
    padding: 20px;
    margin: 0 20px;
    padding-top: 0;
    text-align: left;
    max-height: 500px;
  }
  
  .coding-instructions h2 {
    font-size: 20px;
    margin: 0;
    text-align: left;
    color: #8c96a6;
    font-weight: 450;
  }
  
  .coding-instructions hr {
    margin: 10px 0;
    border: none;
    border-top: 1px solid #ccc;
  }
  
  .coding-instructions ul {
    list-style: disc;
    padding-left: 20px;
    text-align: left;
  }
  
  .coding-instructions li {
    font-size: 16px;
    margin-bottom: 10px;
    color: #eef4fc;
    display: list-item !important;
  }
  
  .coding-instructions li:hover{
    font-weight: bold;
    cursor: default;
  }
  
  .coding-exam-start-button {
    background-color: #00751f;
    width: 200px;
    margin: 0px;
    color: #fff;
    border: none;
    border-radius: 12px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
  }