.assesment-dashboard {
  margin: 0 auto;
  padding: 0px 20px 10px 20px;
  border-radius: 10px;
  border: 1px solid #ccc;
}

.assesment-dashboard-total-count-details {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.assesment-dashboard-tests-attempted {
  color: var(--primary-color);
  font-size: 24px;
  font-weight: bold;
  margin: 0;
}

.assesment-dashboard-upgrade {
  display: flex;
  flex-direction: row;
  width: 50%;
  justify-content: flex-end;
  color: var(--primary-color);
  font-size: 24px;
  font-weight: bold;
}

.assesment-dashboard-progress {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 20px;
}

.assesment-dashboard-upcoming-tests {
  color: #333;
}

.assesment-dashboard-upcoming-tests-list,
.assesment-dashboard-test-suggestions-list {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  overflow-x: scroll;
  margin-bottom: 20px;
}

.assesment-dashboard-test-card,
.assesment-dashboard-test-suggestion-card {
  padding: 20px 40px;
  width: 400px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
  margin-right: 20px;
  border-radius: 5px;
  background-color: #fff;
  transition: all 0.3s ease;
}

.assesment-dashboard-test-suggestion-card:hover,
.assesment-dashboard-test-card:hover {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.assesment-dashboard-attempted-tests {
  color: #333;
}

.assesment-dashboard-attempted-tests-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 40px;
}

.assesment-dashboard-attempted-tests-table th,
.assesment-dashboard-attempted-tests-table td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
}

.assesment-dashboard-attempted-tests-table th {
  color: var(--primary-color);
  font-weight: bold;
  font-size: 20px;
  background-color: #f2f2f2;
}

.assesment-dashboard-test-suggestions {
  color: #333;
}

.assesment-dashboard-attend-test-button {
  background-color: #FA1B00;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.assesment-dashboard-attend-test-button:hover {
  background-color: #d80000;
}

.assesment-dashboard-test-card-title,
.assesment-dashboard-test-suggestion-title {
  font-size: 20px;
  color: var(--primary-color);
  font-weight: bold;
  margin-bottom: 5px;
}

.assesment-dashboard-info {
  color: #333;
  font-size: 18px;
  margin-bottom: 10px;
}

.upgrade-button {
  width: 250px;
  height: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  background-color: #FA1B00;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-right: 20px;
}

.upgrade-button svg {
  margin-right: 8px;
}

.upgrade-button:hover {
  background-color: #d80000;
}
