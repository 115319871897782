.rewards-main-section {
  display: flex;
  flex-direction: column;
  padding: 10px;
  height: 70vh;
  overflow-y: scroll;
}

.rewards-card-container {
  display: flex;
  flex-direction: column;
}

.rewards-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 15px 25px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 20px;
}

.rewards-card-head {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  color: var(--primary-color);
  font-size: 20px;
  font-weight: 600;
  margin: 5px 0;
}

.rewards-card-body {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100px;
}

.rewards-card-left {
  flex: 1;
  margin: 10px 0;
}

.rewards-card-name,
.rewards-card-date,
.rewards-card-time {
  display: flex;
  margin-bottom: 3px;
}

.rewards-card-name .label,
.rewards-card-date .label,
.rewards-card-time .label {
  font-size: 16px;
  margin-right: 5px;
}

.rewards-card-name .value,
.rewards-card-date .value,
.rewards-card-time .value {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}

.rewards-card-hr-line {
  width: 100%;
  border: 1px solid #ccc;
  margin: 10px 0;
}

.rewards-card-right {
  flex: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 30px;
  border-radius: 5px;
  background-color: #edf9ff;
}

.rewards-card-claim-description {
  flex: 2;
  text-align: start;
  font-size: 16px;
  color: var(--primary-color);
  font-weight: 600;
  margin-right: 10px;
}

.rewards-card-claim-button {
  flex: 1;
  margin: 0;
  padding: 10px 20px;
  border-radius: 5px;
  text-align: center;
  background-color: var(--primary-color);
  color: white;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}

.rewards-card-claim-button:hover {
  background-color: var(--primary-color);
}

.rewards-card-claim-button.disabled {
  border: 2px solid var(--primary-color);
  background-color: transparent;
  color: var(--primary-color);
  cursor: not-allowed;
}
