/* Form styling */
form {
  padding: 1rem;
  background-color: #f7f7f7;
  border-radius: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Label styling */
label {
  font-weight: 600;
  margin-bottom: 0.5rem;
  display: block;
}

/* Input styling */
input.form-control {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border-radius: 0.25rem;
  border: 1px solid #d1d1d1;
}
/* Form group container */
.form-group {
  margin-bottom: 1.5rem;
}

/* Label within form group */
.form-group label {
  display: block;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

/* Input within form group */
.form-group input.form-control {
  width: 100%;
  padding: 0.5rem;
  border-radius: 0.25rem;
  border: 1px solid #d1d1d1;
}

/* Small text within form group */
.form-group small.form-text {
  font-size: 80%;
  color: #6c757d;
  margin-bottom: 1.5rem;
}
button {
  background-color: #f84f20;
  border-radius: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.main-heading {
  font-size: 24px;
  text-align: center;
  margin-bottom: 20px;
}
.meeting-list {
  display: flex;
  flex-wrap: wrap;
}
.meeting-item {
  width: 40%;
  margin-left: 10px;
  margin-right: 5px;
  margin-top: 5px;
  padding: 10px;
  background-color: #f5e0da;
  text-align: center;
  border-radius: 5px;  
}
.meeting-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #044c74;
}
.meeting-time,
.meeting-duration,
.meeting-otp,
.meeting-description {
  font-size: 16px;
  margin-bottom: 10px;
  color: #044c74;
}
.meeting-link a {
  color: blue;
  text-decoration: none;
}
.join-meeting button,.edit-meeting button {
  background-color: #c04848;
  color: white;
  padding: 10px 20px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
}

.live {
  color: green;
}
.not-live {
  color: red;
}

@media (max-width:667px){
  .meeting-item {
      width:auto;
  }
}
.meeting-item button{
  margin-left:0 !important;
}

.schedulePage {
  display: flex;
  margin-top: 2%;
}