.Ip_AdminInterviewInfoBox{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: white;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    gap: 20px;
    padding: 20px 25px;
    /* margin-bottom: 20px; */
}

.Ip_AdminInterviewInfo{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    border-right: 1px solid #C2C2C2;
    width: 100%;
}

.Ip_AdminInterviewInfo:last-child{
    border-right: none;
}

.Ip_AdminInterviewInfoIcon{
    color: var(--primary-color);
    font-size: 30px;
}

.Ip_AdminInterviewInfoData{
    display: flex;
    flex-direction: column;
    /* gap: 5px; */
}

.Ip_AdminInterviewInfoDataCount{
    font-size: 18px;
    font-weight: bold;
}

.Ip_AdminInterviewInfoDataTitle{
    font-size: 15px;
    font-weight: 400;
}