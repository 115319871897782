.adminQR{
    padding-top: 6rem;
    padding-left: 2rem;
}

.adminQR button{
    
  padding: 8px;
  padding-left: 26px;
  padding-right: 26px;
  font-size: 17px;
  font-weight: bolder;
  border-radius: 5px;
  text-decoration: none;
  border-color: transparent;
  background: linear-gradient(
    to right,
    red,
    rgba(247, 90, 17, 0.904),
    rgb(238, 130, 8)
  );
  color: white;
}

.adminQR h2{
  color: rgb(34, 34, 104);
  font-size: 30px;
  text-align: center;
}