.CustomToolbar {
  display: flex;
  gap: 8px;
  padding: 8px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.CustomToolbar .section-1{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    padding: 8px;
}

.CustomToolbar .section-2{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ToolbarItem {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.ToolbarItem:hover {
  background-color: #e0e0e0;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}

.ActionButton {
    font-family: inherit;
    font-weight: bold;
    margin: 0;
    margin-top: 10px;
    color: #fff;
    background-color: #004c73;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    width: auto;
    margin: 10px 0;
}

.ActionButton:hover {
    background-color: #003a54;
}

.ToolbarItemColorPicker{
    border: none;
    background-color: transparent;
    cursor: pointer;
    width: 30px;
    margin: 0;
    padding: 0;
    height: 25px;
}