.aptitudecontent-page {
  padding-top: 5rem;
  padding-bottom: 2rem;
  background: #eef6fc;
}

.topic-wise-app{
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 3%;
}

.topic-wise-app h3{
  text-align: center;
  padding-bottom: 1rem;
  font-size: 25px;
  color: rgb(31, 31, 78);
}

.topic-wise-app p{
  text-align: center;
  margin-top: 3rem;
  font-weight: 510;
  font-size: 18px;
}

.topic-grid-div{
  
  display: grid;
  cursor: pointer;
  padding-bottom: 2rem;
  justify-content: center;
  grid-template-rows: repeat(1, 1rem);
  grid-template-columns: repeat(auto-fit, minmax(7rem, 18rem));
  grid-row-gap: 1.5rem;
  grid-column-gap: 0.5rem;
  grid-auto-rows: 1rem;
}
