.admin-referral-details-main-section {
  display: flex;
  flex-direction: column;
  padding: 10px;
  height: 70vh;
  overflow-y: scroll;
}

.admin-referral-code-container {
  border: 1px solid #ccc;
  padding: 10px;
  margin: 10px 0;
  padding: 10px 20px;
  display: flex;
  justify-content: flex-start;
  border-radius: 5px;
}

.admin-referral-code-left-section {
  display: flex;
  flex-direction: column;
  width: 15%;
  margin: 20px 0 20px 20px;
}

.admin-referral-code-right-section {
  display: flex;
  flex-direction: column;
  width: 85%;
  margin: 20px 20px 20px 0;
}

.admin-referral-code-title {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 10px;
}

.admin-referral-code-subtitle-container {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
}

.admin-referral-code {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
}

.admin-referral-code-sub-container {
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  margin: 10px 0;
  width: 70%;
  cursor: pointer;
}

.admin-referral-code-sub-container > a {
  text-decoration: none;
  color: var(--text-color);
  font-size: 16px;
}

.admin-referral-code-copy-button,
.admin-referral-code-share-button {
  border: 1px solid var(--primary-color);
  padding: 10px;
  font-size: 14px;
  color: var(--primary-color);
  font-weight: 600;
  border-radius: 5px;
  margin: 10px 0;
  width: 20%;
  text-align: center;
  cursor: pointer;
}

.admin-referral-code-copy-button:hover,
.admin-referral-code-share-button:hover {
  background-color: var(--primary-color);
  color: white;
}

.admin-referral-code-faq-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 10px 0;
  align-items: center;
}

.admin-referral-code-faq-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.admin-referral-code-faq-content {
  width: 100%;
}

.admin-referral-code-faq-card {
  display: flex;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 15px;
  cursor: pointer;
}

.admin-referral-code-faq-question {
  font-weight: 700;
  font-size: 16px;
  color: #585858;
}

.admin-referral-code-faq-arrow {
  margin-right: 10px;
}

.admin-referral-code-faq-answer {
  display: none;
  margin-top: 10px;
  color: #585858;
}

.admin-referral-code-faq-card.show-answer .admin-referral-code-faq-answer {
  display: block;
}

.admin-referral-code-card-container{
  display: flex;
  justify-content: center;
  text-align: center;
}
