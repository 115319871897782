.skillbox-cert-wrapper {
    display: flex;
    justify-content: center;
    padding: 20px;
    width: 100%;
}

.skillbox-cert-card {
    background: white;
    border-radius: 16px;
    padding: 32px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
    transition: all 0.3s ease;
    width: 100%;
    max-width: 600px;
    position: relative;
}

.skillbox-cert-card:hover {
    transform: translateY(-4px);
    box-shadow: 0 6px 24px rgba(0, 0, 0, 0.12);
}

.skillbox-cert-card__header {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    margin-bottom: 32px;
}

.skillbox-cert-card__icon-wrapper {
    background: rgba(59, 130, 246, 0.1);
    padding: 12px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
}

.skillbox-cert-card__icon {
    font-size: 1.75rem;
    color: #3b82f6;
}

.skillbox-cert-card__title-group {
    flex-grow: 1;
}

.skillbox-cert-card__title {
    font-size: 1.5rem;
    font-weight: 600;
    color: #1e293b;
    margin-bottom: 12px;
    line-height: 1.3;
}

.skillbox-cert-card__content {
    background: #f8fafc;
    border-radius: 12px;
    padding: 24px;
}

.skillbox-cert-card__progress-container {
    margin-bottom: 24px;
}

.skillbox-cert-card__progress-text {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 0.95rem;
    color: #64748b;
    margin-bottom: 12px;
}

.skillbox-cert-card__progress-icon {
    font-size: 1.1rem;
    color: #3b82f6;
}

.skillbox-cert-card__progress-bar {
    width: 100%;
    height: 8px;
    background: #e2e8f0;
    border-radius: 6px;
    overflow: hidden;
}

.skillbox-cert-card__progress-fill {
    height: 100%;
    background: linear-gradient(90deg, #3b82f6, #2563eb);
    border-radius: 6px;
    transition: width 0.5s ease;
}

.skillbox-cert-card__status {
    display: inline-flex;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    border-radius: 24px;
    font-size: 0.875rem;
    font-weight: 500;
}

.skillbox-cert-card__status.is-locked {
    background: #fee2e2;
    color: #dc2626;
}

.skillbox-cert-card__status.is-unlocked {
    background: #dcfce7;
    color: #16a34a;
}

.skillbox-cert-card__status-icon {
    font-size: 1rem;
}

.skillbox-cert-card__button-container {
    display: flex;
    justify-content: center;
}

/* Responsive adjustments */
@media (max-width: 640px) {
    .skillbox-cert-wrapper {
        padding: 16px;
    }

    .skillbox-cert-card {
        padding: 24px;
    }

    .skillbox-cert-card__header {
        gap: 16px;
    }

    .skillbox-cert-card__title {
        font-size: 1.25rem;
    }

    .skillbox-cert-card__content {
        padding: 20px;
    }
}