.mse-submissions-admin-page {
    padding-top: 5rem;
    padding-left: 2rem;
    padding-right: 1rem;
    padding-bottom: 2rem;
    background: #ffffff;
}

.mse-submission-table {
    padding: 1rem;
}

.mse-submission-table td {
    border: 1.8px solid #bbb;
    padding: 7px;
}

.mse-submission-table th {
    background-color: #cce4de;
    border: 1.8px solid #bbb;
    padding: 14px;
}

.mse-dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}

.mse-dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}
  
.mse-dropdown-content a:hover {background-color: #f1f1f1}
  
.dropdown:hover .mse-dropdown-content {
    display: block;
}
  
.dropdown:hover .dropbtn {
    background-color: #0782c5;
    transition: none;
}

.dropdown-modules{
    position: relative;
    display: inline-block;
    margin-top: 0.3rem;
    margin-right: auto;
    margin-left: 1rem;
}

.dropbtn-modules{
    color: white;
    padding: 0.5rem;
    width: 9rem;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    height: 2.8rem;
    font-size: 18px;
}

.scroll {
    width: 100%;
    height: 30rem;
    background: #f5f5f0;
    border-radius: 6px;
}

.imgs-container {
    padding-top: 2rem;
    padding-left: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.indv-img {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    width: 30%;
    margin: 0 0 0.5em 0;
    padding: 0.5em;
    border: 1px solid black;
}
.indv-img img {
    margin: 0.5rem 0;
}
