.profile-education-page {
  margin: 0 auto;
  width: 100%;
}

.profile-education-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.profile-education-header-title {
  font-size: 20px;
  color: var(--primary-color);
  font-weight: bold;
}

.profile-education-section-1 {
  display: flex;
  flex-direction: column;
  height: 70vh;
  overflow-y: scroll;
}

.profile-education-section-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  height: 70vh;
}

.profile-education-add-new {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: top;
  margin-top: 80px;
}

.profile-education-add-new-title {
  font-size: 20px;
  color: #434343;
  font-weight: bold;
  margin-bottom: 7px;
}

.profile-education-add-new-subtitle {
  font-size: 16px;
  color: var(--tertiary-color);
  margin-bottom: 17px;
}

.profile-education-card {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 25px 30px 15px 30px;
  margin-bottom: 15px;
}

.profile-education-card-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 10px;
}

.profile-education-card-header-title {
  font-size: 20px;
  color: #434343;
  font-weight: bold;
  margin-bottom: 7px;
}

.profile-education-card-header-subtitle {
  font-size: 16px;
  color: var(--tertiary-color);
}

.profile-education-card-body {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 10px 0px;
  padding-right: 25px;
}

.profile-education-card-body-section-1{
  flex: 1;
}

.profile-education-card-body-section-2{
  flex: 1;
}

.profile-education-card-body-section-3{
  flex: 0.5;
}

.profile-education-card-body-title {
  font-size: 15px;
  color: var(--tertiary-color);
  margin-bottom: 7px;
}

.profile-education-card-body-title-1 {
  font-size: 16px;
  color: var(--tertiary-color);
  margin-bottom: 7px;
}

.profile-education-card-body-data-value {
  font-size: 16px;
  color: #434343;
  font-weight: bold;
}

.profile-education-card-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
  padding-top: 3px;
}

.profile-education-card-edit {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.profile-education-card-edit-title {
  font-size: 16px;
  color: var(--primary-color);
  margin-left: 5px;
}

.profile-education-card-delete {
  cursor: pointer;
}

/* popup styles */
.profile-education-popup-education-type-container{
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 15px;
  align-items: center;
  justify-content: center;
}

.profile-education-popup-education-type-title{
  font-size: 20px;
  color: #434343;
  margin-bottom: 20px;

}

.profile-education-popup-type-item {
  display: flex;
  flex-direction: row;
  width: 50%;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #ccc;
  background-color: #fff;
  color: #434343;
  cursor: pointer;
}

.profile-education-popup-type-item-selected {
  background-color: var(--primary-color);
  color: #fff;
}

/* form styles */
.profile-education-form-section{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.profile-education-form-section-button{
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 30px;
  width: 100%;
}
