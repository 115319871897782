.st-cls-lt-section {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 5px;
  height: 65vh;
  width: 100%;
  overflow-y: scroll;
}

.st-cls-lt-table-row-2,
.st-cls-lt-table-row-3,
.st-cls-lt-table-row-4,
.st-cls-lt-table-row-5,
.st-cls-lt-table-header-row-2,
.st-cls-lt-table-header-row-3,
.st-cls-lt-table-header-row-4,
.st-cls-lt-table-header-row-5 {
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.st-cls-lt-table-row-3 {
  text-decoration: underline;
  text-underline-offset: 1.5px;
  cursor: pointer;
}

.st-cls-lt-table-row-3 {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.st-cls-lt-table-row-3 > span:hover {
  color: var(--primary-color);
}

.st-cls-lt-table-icn-act path:hover {
  fill: var(--primary-color);
}

/* table css */

.st-cls-table-header,
.st-cls-table-row {
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr 1fr;
  align-items: center;
}

.st-cls-table-header > div {
  font-weight: 600;
  color: var(--primary-color);
}

.st-cls-table-body {
  overflow-y: scroll;
}

.st-cls-hr-line {
  border: 1px solid #e0e0e0;
  margin: 0 20px 10px 20px;
}

.st-cls-table-row:hover {
  cursor: pointer;
  background-color: aliceblue;
}

.st-cls-table-header > div,
.st-cls-table-row > div {
  padding: 10px;
  margin: 5px 20px;
}

.st-cls-header,
.st-cls-table-body,
.st-cls-table {
  width: 100%;
}
