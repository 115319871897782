.live-classes-body-home-button{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    font-size: 14px;
    color: var(--tertiary-color);
    margin-top: -5px;
    margin-bottom: 10px;
}

.live-classes-body-home-button:hover{
    color: var(--primary-color);
    cursor: pointer;
}