.interview-preperation-personal-interview-role-container-wrapper {
    width: 100%;
    height: 460px;
}

.interview-preperation-personal-interview-role-container {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 20px;
    column-gap: 20px;
    margin-bottom: 20px;
    width: 100%;
    max-height: 430px;
    overflow-y: auto;
}

.interview-preperation-personal-interview-level-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    gap: 30px;
    margin-bottom: 50px;
}

.interview-preperation-personal-interview-box {
    background-color: #ffffff;
    border: 2px solid #B6B6B699;
    border-radius: 8px;
    padding: 15px;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 16px;
    color: #434343;
    width: 100%;
    height: 55px;
    box-sizing: border-box;
    word-wrap: break-word;
    overflow-wrap: break-word;
}

.interview-preperation-personal-interview-box:hover {
    background-color: #e0e0e0;
    border-color: #bbb;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.interview-preperation-personal-interview-box.selected {
    background-color: #DFF4FF;
    font-weight: 600;
    border-color: #DFF4FF;
}

.interview-preperation-personal-interview-search {
    width: 400px;
}

.interview-preperation-personal-interview-search-wrapper {
    position: relative;
    width: 100%;
}

.interview-preperation-personal-interview-search-input {
    width: 100%;
    padding: 5px 15px;
    font-size: 16px;
    font-weight: 500;
    color: #434343;
    border: 1px solid #D0D5DD;
    border-radius: 8px;
    transition: border-color 0.3s ease;
}

.interview-preperation-personal-interview-search-input:focus {
    outline: none;
    border-color: #004c73;
}

.interview-preperation-personal-interview-search-icon {
    position: absolute;
    right: -10px;
    top: 50%;
    transform: translateY(-50%);
    color: #888;
    pointer-events: none; 
}

.interview-preperation-personal-interview-no-results {
    text-align: center;
    color: #666;
    margin-top: 20px;
}
