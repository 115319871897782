.profile-user-resume-page {
  margin: 0 auto;
  width: 100%;
}

.profile-user-resume-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.profile-user-resume-header-title {
  font-size: 20px;
  color: var(--primary-color);
  font-weight: bold;
}

.profile-user-resume {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  height: 70vh;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.profile-user-resume__view {
    display: flex;
    flex-direction: column;
    padding: 40px;
    width: 90%;
  }

.profile-user-resume__view__details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
    align-items: center;
  box-shadow: 0 0 5px #ccc;
  padding: 10px;
    margin-bottom: 15px;
}

.profile-user-resume__view__details__download {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50vh;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
}

.profile-user-resume__view__no-resume {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  color: var(--tertiary-color);
  font-size: 20px;
}

.profile-user-resume-view-details-section-1,
.profile-user-resume-view-details-section-2{
  font-size: 16px;
  font-weight: bold;
}

.profile-user-resume-view-details-section-1 span{
  font-size: 16px;
  color: var(--tertiary-color);
  font-weight: bold;
  margin-right: 5px;
}

.profile-user-resume-view-details-section-2 span{
  font-size: 16px;
  color: var(--tertiary-color);
  font-weight: bold;
  margin-right: 5px;
}

.profile-user-upload-new-container-1{
  margin-bottom: 5px;
}

.profile-user-upload-new-container-2{
  margin-bottom: 15px;
}