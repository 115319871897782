.main-section-container {
  display: flex;
  overflow: hidden;
}

.container-fluid {
  padding-left: 0;
  padding-right: 0;
    margin: 0 auto;
    width: 100%;
  
}

.resume-upload-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.resume-upload-section-title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #434343;
  text-align: center;
  margin-top: 20px;
}

.resume-upload-section-subtitle {
  font-size: 24px;
  font-weight: 400;
  color: #434343;
  text-align: center;
  margin-top: 10px;
}

.resume-upload-button {
  padding: 10px 20px;
  margin: 0 10px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.resume-upload-button-outline {
  padding: 4px 34px;
  margin: 10px 10px;
  max-width: 100px;
  border: 2px solid orangered;
  border-radius: 8px;
  text-align: center;
  color: orangered;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.resume-upload-button-outline:hover {
  padding: 4px 34px;
  margin: 10px 10px;
  max-width: 100px;
  border-radius: 8px;
  text-align: center;
  background-color: orangered;
  color: white;
  cursor: pointer;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.drag-drop-zone {
  border: 2px dashed #ccc;
  border-radius: 5px;
  padding: 20px;
  text-align: center;
  margin: 10px;
  cursor: pointer;
  color: #ccc;
  font-weight: bold;
  font-size: 16px;
}

.upload-navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 40px;
  padding: 20px 30px 20px;
}

.file-input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.drag-drop-section {
  display: flex;
  background-color: #f7f9fa;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  margin: 0 60px;
  border: dashed;
  cursor: pointer;
  border-radius: 10px;
  border-width: 2px;
  border-color: #004d737c;
}

.uploaded-resume-section {
  margin: 0 28%;
}

.has-scroll {
  overflow-y: auto;
}

.resume-list-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  margin: 20px 0;
}

.resume-list-container:hover {
  cursor: pointer;
  background-color: navy;
  color: white;
}

.resume-list-container:hover * {
  color: white;
  fill: white;
}

.gray-border {
  border-radius: 6px;
  border-style: solid;
  border-color: lightgray;
  border-width: 2px;
}

.green-border {
  border-radius: 6px;
  border-style: solid;
  border-color: rgb(50, 230, 50);
  border-width: 2px;
}

/* .selected {
  cursor: pointer;
  background-color: navy;
  color: white;
  fill: white;
} */

.saved-resume-list-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 4px 20px;
  font-size: 16px;
  margin: 20px 0;
}
