.interview-feedback-nav-legends {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .interview-feedback-nav-legends-section-1 {
    display: flex;
    align-items: center;
  }

  .interview-feedback-nav-legends-squares {
    display: flex;
    align-items: start;
    overflow-x: scroll;
  }
  
  .interview-feedback-nav-legends-square {
    width: 40px;
    height: 40px;
    border-radius: 10px;
    background-color: #fff;
    border: 1px solid #898989;
    background-color: #F4F4F4;
    color: #898989;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 8px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .interview-feedback-nav-legends-square:hover {
    background-color: #D2F0FF;
    color: #004C73;
  }
  
  .interview-feedback-nav-legends-square-active {
    background-color: #D2F0FF;
    color: #004C73;
    border-color: #004C73;
  }

  .interview-feedback-nav-legends-svgs{
    cursor: pointer;
  }