.password-reset-page {
    padding-top: 5rem;
    padding-bottom: 2rem;
    margin-right: 3%;
    margin-left: 3%;
    text-align: center;
    background: #eef6fc;
  }
  
  .password-reset-page button {
    width: fit-content;
    background-color: #044c74;
    color: #f84f20;
  }