* {
    /* font-family: "Sora", sans-serif;
    box-sizing: border-box;
    margin: 0;
    padding: 0; */
}

.app {
    width: 100vw;
    scroll-behavior: smooth;
    background: #eef6fc;
}

h2{
   font-size: 30px;
   color: rgb(48, 48, 119); 
   
}

h3{
    font-size: 26px;
    color: rgb(48, 48, 119);
}

.popup-content {
    border-radius: 5px;
}