.ad-assgn-tags {
  display: flex;
  flex-direction: row;
  margin: -10px 0 10px 0;
}

.ad-assgn-subs-section {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 5px;
  height: 60vh;
  width: 100%;
  overflow-y: scroll;
}

.ad-assgn-subs-nsection {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 5px;
  height: 65vh;
  width: 100%;
  overflow-y: scroll;
}

.ad-assgn-body-subs-filter {
  width: 30%;
}

.ad-assgn-subs-table-header,
.ad-assgn-subs-table-row {
  display: grid;
  grid-template-columns: 2fr 1fr 0.8fr;
  align-items: center;
}

.ad-assgn-subs-table-header > div {
  font-weight: 600;
  color: var(--primary-color);
}

.ad-assgn-subs-table-header > div,
.ad-assgn-subs-table-row > div {
  padding: 7px 0;
  margin: 5px 20px;
}

.ad-assgn-subs-table-row-2,
.ad-assgn-subs-table-row-3,
.ad-assgn-subs-table-header-row-2,
.ad-assgn-subs-table-header-row-3 {
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.ad-assgn-subs-table-row-3 {
  text-decoration: underline;
  text-underline-offset: 1.5px;
  cursor: pointer;
}

.ad-assgn-subs-table-row-3:hover {
  color: var(--primary-color);
}
