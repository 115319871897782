.qt-courses-container {
  padding: 20px 0px;
}

.qt-courses-header {
  display: flex;
  flex-direction: row;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  color: var(--primary-color);
}

.qt-courses-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin: 20px 0 30px 0;
}

.qt-search-container-input {
  padding: 15px;
  font-size: 16px;
  outline: none;
  border: none;
  width: 100%;
  padding-left: 20px;
}

.qt-search-box {
  display: flex;
  align-items: center;
  width: 45%;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  padding-right: 10px;
  background-color: white;
  overflow: hidden;
}

.qt-search-box:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

.qt-add-course-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 45%;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  padding: 20px;
  color: #777777;
  background-color: white;
  overflow: hidden;
  cursor: pointer;
}

.qt-add-course-btn:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

.qt-courses-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  padding: 10px 0;
  max-height: 55vh;
  overflow-y: scroll;
}

.qt-course-box {
  background-color: white;
  border: none;
  border-radius: 20px;
  padding: 15px;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.qt-course-box:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  background-color: var(--primary-color);
  color: white;
  font-weight: bold;
}

.qt-add-course-input-container {
  position: relative;
  width: 45%;
}

.qt-add-course-input-wrapper {
  display: flex;
  align-items: center;
  gap: 0px;
}

.qt-add-course-input {
  width: 90%;
  padding: 12px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 18px;
}

.qt-add-course-input.error {
  border-color: red;
}

.qt-add-course-error {
  color: red;
  font-size: 12px;
  position: absolute;
  top: -20px;
  left: 0;
  width: 100%;
}

.qt-add-course-button {
  margin: 0px;
  padding: 15px;
  cursor: pointer;
  background-color: var(--primary-color);
  border-radius: 0 10px 10px 0;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.qt-add-course-button svg path {
  fill: white;
}

.qt-add-course-button:hover {
  background-color: #003855;
}

.qt-add-course-button:hover svg path {
  fill: white;
}

.qt-add-course-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.qt-add-course-button:disabled svg path {
  fill: #999;
}
