.apptest-page {
    padding-top: 1rem;
    padding-bottom: 2rem;
    background: #eef6fc;
  }

 hr {
    margin: 10px 0;
    border: none;
    border-top: 1px solid #ccc;
  }

  .practice-test{
    margin-top: 80px;
  }
  
  .apptest-page h3 {
    color: rgb(20, 20, 78);
    font-size: 22px;
  }
  
  .optionValue1 {
    color: #f34213;
    font-size: 20px;
    line-height: 3;
    font-weight: 600;
    padding-left: 2%;
  }
  
  .optionValue1:hover {
    background-color: rgba(220, 220, 220, 0.5);
    border-radius: 20px;
    /* padding-left:20px; */
    cursor: pointer;
  }
  
  .selected-option1 {
    color: #f34213;
    font-size: 20px;
    line-height: 3;
    font-weight: 600;
    padding-left: 2%;
    /* background-color: #b0cfb1e0; */
    border-radius: 20px;
    /* padding-left:20px; */
    cursor: pointer;
  }
  
  .timer-box {
    background-color: azure;
    padding: 3%;
    margin-top: 0;
    border: 3px solid #f34213;
    border-radius: 20px;
    width: fit-content;
    position: fixed;
    font-family: initial;
    right: 2%;
  }
  
  .question-box {
    margin-top: -2rem;
    margin-bottom: 40px;
    font-family: initial;
    padding-left: 0%;
    margin-left: 0%;
    width: 67%;
  }

  .question-option{
    margin-top: 20px;
  }
  
  .ques-no{
      font-family: initial;
      font-size: 12px;
  }
  
  .ques-status-x {
    display: flex;
    flex-direction: row;
  }
  
  .ques-status-no {
    flex-basis: 100%;
    height: 0;
    /* display: inline-block;  */
    break-after: always; /* New syntax */
    white-space: pre;
    /* display: block; */
    content: attr(data-text);
    content: "\A";
    line-break: strict;
  }

  .head-fixed{
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    background-color: azure;
  }

  .exam-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px 0 10px;
    width: 100%;
  }

  .flex-item{
    flex: 1;
  }

  .flex-item:nth-child(1) {
    flex: 1.5;
    text-align: start;
  }

  .flex-item:nth-child(2) {
    flex: 3;
    text-align: center;
  }

  .flex-item:nth-child(4){
    flex: 0.5;
    text-align: end;
    margin-right: 50px;
  }
  
  .exam-heading img {
    max-height: 100px;
    max-width: 250px;
    margin-right: 10px;
    margin-top: 10px;
  }

  .exit-btn {
    cursor: pointer;
    padding: 5px;
    margin-top: 20px;
  }

  .timer {
    font-family: 'Helvetica Neue', Arial, sans-serif;
    font-size: 24px;
    font-weight: bold;
    color: #ffffff;
    text-align: center;
    padding: 10px;
    border: 2px solid #3498db;
    border-radius: 5px;
    background-color: #3498db;
    margin: 20px;
  }
  
  .timer p {
    margin: 0;
  }
  
  .timer span {
    color: #e74c3c;
    font-weight: normal;
  }
  
  
  #exit {
    width: 30px;
    height: 30px;
    fill: #000;
  }

  #tick1{
    float: right;
    margin: 20px;
  }
  
  body:fullscreen {
    overflow: scroll !important;
  }
  body:-ms-fullscreen {
    overflow: scroll !important;
  }
  body:-webkit-full-screen {
    overflow: scroll !important;
  }
  body:-moz-full-screen {
    overflow: scroll !important;
  }

  *{
    user-select: none;
  }


  /* question palette */

.fixed-box{
  margin-top: 0;
  width: fit-content;
  position: fixed;
  font-family: initial;
  right: 2%;
}

.question-palette {
    /* padding: 3%; */
    border-radius: 20px;
  border: 1px solid #ccc;
  padding: 10px;
  margin: 10px;
  width: 300px;
}

.palette-header {
  text-align: center;
  font-weight: bold;
}

.palette-body {
  display: flex;
  flex-wrap: wrap;
}

.question {
  width: 60px;
  height: 60px;
  border: 1px solid #ccc;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
}

.not-answered {
  background-color: #fff;
  color: #000;
}

.answered {
  background-color: #4caf50;
  color: #fff;
}

/* css for legend */

.legend {
  margin: 20px;
}

.legend-box {
  display: flex;
}

.legend-item {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.legend-color {
  width: 20px;
  height: 20px; 
  margin-right: 8px; 
}

.legend .legend-item .legend-color.answered {
  background-color: #4caf50; 
  border-radius: 50%;
}

.legend .legend-item .legend-color.not-answered {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
}

.legend-text {
  font-size: 16px;
}
