.stu-cls-cls-vid-title{
    font-size: 1.5rem;
    font-weight: 500;
    color: var(--primary-color);
    padding: 20px;
}

.stu-cls-vid-container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.st-cls-class-video{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
    width: 80%;
    height: 500px;
    box-sizing: border-box;
}