:root {
  --qcreator-sidebar-bg-color: #edf9ff;
  --qcreator-sidebar-header-bg-color: #34495e;
  --qcreator-sidebar-text-color: white;
  --qcreator-sidebar-hover-text-color: #004c73;
  --qcreator-sidebar-icon-color: #bdc3c7;
}

.qcreator-sidebar {
  background-color: var(--primary-color);
  color: var(--qcreator-sidebar-text-color);
  overflow-y: auto;
  padding: 20px 0;
}

@media (min-width: 1440px) {
  .qcreator-sidebar {
    width: 15%;
  }
}

@media (min-width: 768px) and (max-width: 1439px) {
  .qcreator-sidebar {
    width: 25%;
  }
}

.qcreator-sidebar-header {
  padding: 15px 0 40px 0;
  text-align: center;
}

.qcreator-sidebar-header-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.qcreator-sidebar-header-logo {
  padding: 10px;
  width: 160px;
  cursor: pointer;
  background-color: white;
  border-radius: 10px;
}

.qcreator-sidebar-body {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
}

.qcreator-sidebar-body-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.qcreator-sidebar-body-list-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 10px 0px 10px 20px;
  border-radius: 50px 0 0 50px;
  cursor: pointer;
  color: var(--qcreator-sidebar-text-color);
  transition: background-color 0.3s ease;
  margin: 0;
}

.qcreator-sidebar-body-list-item:hover {
  background-color: #ffffffde;
  color: var(--qcreator-sidebar-hover-text-color);
  font-weight: 600;
}

.qcreator-sidebar-body-list-item.active {
  border-right: 5px solid var(--qcreator-sidebar-hover-text-color);
  background-color: #F7F7FF;
  color: var(--qcreator-sidebar-hover-text-color);
  font-weight: 600;
}

.qcreator-sidebar-body-list-item:hover svg path {
  fill: var(--primary-color);
}

.qcreator-sidebar-body-list-item.active svg path {
  fill: var(--primary-color);
}

.qcreator-sidebar-body-list-item.active:hover svg path {
  fill: var(--primary-color);
}

.qcreator-sidebar-body-list-item-icon {
  margin-right: 15px;
  padding-top: 1px;
}

.qcreator-sidebar-body-list-item-title {
  flex: 1;
}
