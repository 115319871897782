.st-skillbox-programs-mn-ct {
  display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.st-skillbox-programs-mn-ct-section{
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  height: 70vh;
  width: 100%;
  overflow-y: scroll;
}