.resume-gen-download-dropdown {
  position: relative;
  display: inline-block;
  width: 175px;
}

.resume-gen-download-dropdown-button {
  cursor: pointer;
  font-family: 'Arial', sans-serif;
  margin: 0;
  background-color: #004c73;
  color: white;
  padding: 10px 20px;
  border-radius: 4px;
  text-align: center;
  width: 100%;
}

.resume-gen-download-dropdown-button svg{
    margin-left: 10px;
}

.resume-gen-download-dropdown-button:hover {
    background-color: #09344a;
}

.resume-gen-download-dropdown-menu {
  display: block;
  position: absolute;
  background-color: white;
  min-width: 173px;
  box-shadow: inset 0px 8px 16px 0px rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid #B6B6B6;
  border-left: 1px solid #B6B6B6;
  border-right: 1px solid #B6B6B6;
  border-radius: 4px;
  z-index: 1;
}

.resume-gen-download-dropdown-menu-item {
  color: black;
  font-weight: bold;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
  text-align: center;
}

.resume-gen-download-dropdown-menu-item:first-child {
    position: relative;
  }
  
.resume-gen-download-dropdown-menu-item:first-child:after {
content: "";
position: absolute;
bottom: 0;
left: 25%;
width: 50%;
border-bottom: 1px solid #B6B6B6;
}

.resume-gen-download-dropdown-menu-item:hover {
  background-color: #f1f1f1;
}
