.module-content-page{
    padding-top: 5rem;
    padding-left: 2rem;
    padding-right: 2rem;
    background: #eef6fc;
}

.module-content-page h2{
    color: rgb(23, 23, 82);
    font-size: 26px;
}

.main-course-heading{
    display: flex;
}

.main-course-heading h2{
    flex: 1;
    font-size: 22px;
    color: orangered;
    margin-left: 0.7rem;
}


.module-content-flex{
    display: flex;
    flex-direction: row;
}

.module-flex1{
    flex: 1.2;
    padding-right: 1rem;
    margin-right: 0rem;
}


.module-flex11{
    padding-top: 0.5rem;
    margin-right: 3rem;
    margin-left: -2rem;
}

.module-flex11-arrow{
    display: flex;
    text-align: center;
    border: solid;
    font-size: 45px;
    position: fixed;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border-color: rgb(14, 14, 68);
}

.module-flex11-arrow p{
    font-size: 17px;
    font-weight: bold;
    color: white;
    display: none;
    align-self: center;
}


.module-flex11-arrow:hover{
    font-size: 34px;
    background-color: rgb(17, 17, 56);
    border-color: white;
    color: white;
    padding-left: 0.5rem;
    padding-right: 0.5rem;

}

.module-flex11-arrow:hover p{
    display: block;
    margin-right: 0.3rem;
}


.module-flex1-banner{
    display: flex;
}

.module-flex1-div {
    display: flex;
    cursor: pointer;
    border-radius: 7px;
    padding-bottom: 0.5rem;
    margin-bottom: 0.3rem;
}

.flex1-div-content{
    flex: 1;
    align-self: center;
    
}

.flex1-div-content1{
    flex: 1;
    margin-top: 2rem;
    
}

.flex2-div-content{
    flex: 7;
    align-self: center;
}

.flex2-div-content h2{
    text-align: left;
    margin-top: 0.4rem;
    margin-right: 2%;
    font-size: 17px;
    margin-bottom: 1rem;
    font-weight: 500;
}

.flex2-div-content h3{
   color: rgb(16, 16, 41);
   font-size: 16px;
   margin-top: 0.5rem;
    margin-bottom: 0rem;
    font-weight: 490;
}


.progress-bar-module{
    margin-right: 5%;
    margin-top: -0.2rem;
}

.progress-bar-module span{
    font-size: 14px;
    color: rgb(31, 31, 71);
}

.flex1-video-grid ul{
    margin-left: -2.5rem;
    list-style-type: none;
}

.flex1-video-grid li{
    padding-top: 0.3rem;
    
    padding-bottom: 0.3rem;
}

.flex1-video-grid li:hover{
    background-color: rgb(213, 213, 226);
}


.module-flex2{
    flex: 3;
    padding-left: 2rem;
    margin-left: 0.5rem;
}


.module-flex2 p{
    font-size: 16px;
    width: 90%;
}

.module-flex2 h2{
    font-size: 24px;
}


.video-player-div{
    margin-top: 1rem;
    padding-bottom: 0.5rem;
    display: flex;
}

.video-player-control{
    align-self: center;
    cursor: pointer;
    position: sticky;
    margin-right: -2.2rem;
    font-size: 36px;
    color: white;
    background-color: black;
}

.video-player-control1{
    align-self: center;
    cursor: pointer;
    margin-left: -2.2rem;
    font-size: 36px;
    color: white;
    background-color: black;
}


.flex3-div-content{
    padding-right: 0.5rem;
    font-size: 17px;
    padding-top: 0.5rem;
}

