.resume-content-header-nav-menu {
    font-size: 14px;
    color: var(--tertiary-color);
    margin-top: 5px;
}

.resume-content-header-nav-menu:hover {
    color: var(--primary-color);
    cursor: pointer;
}

.resume-content-body {
    margin: 10px 40px 15px 40px;
    height: 100%;
    background-color: #fff;
    color: var(--text-color);
  }