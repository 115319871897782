.homepage {
    padding-top: 5rem;
    padding-left: 5rem;
    padding-right: 5rem;
    padding-bottom: 2rem;
    background: #eef6fc;
}

.homepagecontent {
    display: flex;
    margin-top: 2%;
}

.module-course-item {
    margin-left: 1rem;
}

.module-course-item h3 {
    text-align: left;
    margin-left: 3rem;
    color: black;
}

.indv-course-item {
    display: flex;
    height: 3rem;
    margin-top: -2rem;
    margin-bottom: 1rem;
    cursor: pointer;
}

.indv-course-item-title h3 {
    font-family: sans-serif2;
}

.indv-course-item-plus {
    font-size: larger;
    margin-top: 2rem;
    margin-left: 10rem;
    align-items: right;
}

.module-course-item-head {
    display: flex;
}

.module-course-item-head-title {
    width: 65%;
}

.module-course-item-head-title h2 {
    text-align: left;
    color: #f84f20;
}

.module-course-item-head-plus {
    font-size: 25px;
    margin-top: 2.2rem;
    margin-left: 10rem;
    align-items: right;
}

.home-courses-flex1{
    display: grid;
    grid-template-rows: repeat(1, 16.3rem);
    grid-template-columns: repeat(auto-fit, minmax(11rem, 17rem));
    grid-row-gap: 4rem;
    grid-column-gap: 5rem;
    grid-auto-rows: 16.3rem;
    padding-bottom: 4rem;
    flex: 2;
    margin-top: 1%;
}

.grid-div-home{
    border-radius: 6px;
    /* cursor: pointer; */
    box-shadow: rgba(29, 23, 23, 0.35) 0px 5px 15px;
    height: fit-content;
    padding-bottom: 10%;
}

.grid-div-home h3{
    margin-left: 5%;
    margin-right: 2%;
    font-size: 17px;
    margin-bottom: 0rem;
    font-weight: 500;
}

/* .grid-div-home span {
    margin-left: 10%;
    margin-right: 2%;
    font-size: 14px;
    padding-top: 20rem;
    color: rgb(31, 31, 71);
} */

.grid-card-img-home {
    width: 100%;
    height: 9rem;
}

.grid-card-img-home img{
    width: 100%;
    border-radius: 6px 6px 0px 0px;
    height: 100%;
}

.home-courses-flex2 {
    flex: 3.4;
    padding-left: 4rem;
    padding-top: 0rem;
    margin-top: 0.5%;
  }

.module-flex1-div {
    display: flex;
    padding-top: 0.4rem;
    cursor: pointer;
    border-radius: 7px;
    padding: 0;
    margin: 0.2rem;
}

.flex1-div-content1{
    flex: 0.2;
    text-align: center;
    margin-top: 1.6rem;    
}
   
.flex2-div-content{
    flex: 1;
    align-self: center;
}

.flex2-div-content h2{
    text-align: left;
    margin-top: 1rem;
    margin-right: 2%;
    font-size: 20px;
    font-weight: bold;
}

.flex1-video-grid ul{
    margin-left: -2.5rem;
    list-style-type: none;
    margin-top: 0px;
}

.flex1-video-grid li{
    padding-top: 0.3rem;
    padding-left: 0.5rem;
    padding-bottom: 0.3rem;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 2px;
}

.flex1-video-grid-lock li{
    background-color: rgb(230, 230, 240);
    border-radius: 12px;
    color: #999999;
}

.flex1-video-grid li:hover{
    background-color: rgb(230, 230, 240);
}

.flex1-lecture-grid ul{
    margin-left: -2.5rem;
    list-style-type: none;
    margin-top: 0px;
}

.flex1-lecture-grid li{
    padding-top: 0.3rem;
    padding-left: 0.5rem;
    padding-bottom: 0.3rem;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 2px;
}

.flex1-lecture-grid li:hover{
    cursor:default;
}

.flex3-div-content{
    margin-top: 2%;
    font-size: 25px;
    max-width: 10%;
    cursor: pointer;
}

/* .module-and-no-of-items{
    display: flex;
} */

.no-of-items{
    margin-top: -0.5rem;
    display: flex;
    color: #66767f;
    font-weight: 600;
}

.no-of-items p{
    margin: 0px;
}

/* button{
    background-color: #ff5c33;
    border: none;
    color: white;
    padding: 10px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    width: 70%;
    border-radius: 4px;
    font-family: cursive, sans-serif;
}
#button-3 {
    position: relative;
    overflow: hidden;
    cursor: pointer;
  }
  
  #circle {
    width: 0%;
    height: 0%;
    opacity: 0;
    line-height: 40px;
    border-radius: 50%;
    background: #adebad;
    position: absolute;
    transition: all .3s ease-Out;
    top: 20px;
    left: 70px;
  }
  
  #button-3:hover #circle {
    width: 200%;
    height: 500%;
    opacity: 0.5;
    top: -70px;
    left: -70px;
  } */

.assign-row-table {
    width: 100%;
    padding: 1rem;
    margin-left: 5rem;
}

.assign-row-table td {
    margin-left: 15%;
    margin-right: 5%;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    text-align: center;
}


h3{
    text-align: center;
}

button{
    background-image: linear-gradient(to right, #f84f20 0%, #044c74  120%, #C04848  100%);
    border: none;
    color: rgb(255, 255, 255);
    padding: 10px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    width: 80%;
    border-radius: 4px;
    font-family: cursive, sans-serif;
    transition: 0.5s;
    background-size: 200% auto;
    margin-left: 10%;
    margin-bottom: 2%;
}

.video-content {
    flex: 1;
    margin-top: 0.5rem;
    margin-right: 10%;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    /* background-image: linear-gradient(to right, #f84f20 0%, #044c74  120%, #C04848  100%); */
    color: rgb(255, 255, 255);
}

.class-content {
    flex: 1;
    margin-top: 0.5rem;
    margin-right: 2%;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;  
    /* background-image: linear-gradient(to right, #044c74 0%, #f84f20  120%, #044c74  100%); */
    color: rgb(255, 255, 255);
}

button:hover{
    background-position: right center;
}

.flex3-duration-grid ul{
    margin-left: -1rem;
    list-style-type: none;
    margin-top: 0px;
}

.flex3-duration-grid li{
    font-weight: 500;
    color: #66767f;
    padding-top: 0.3rem;
    padding-left: 0.5rem;
    padding-bottom: 0.3rem;
    font-size: 17px;
    list-style-type:circle ;
    margin-bottom: 2px;
}

.flex3-duration-grid{
    margin-top: 8%;
}

.main-heading{
    font-weight: 600;
    font-size: 24px;
    font-style: italic;
    /* transform: skew(-10deg); */
    background-image: linear-gradient(to right, #f84f20 0%, #044c74  120%, #C04848  100%);
    color: #fff;
    padding: 10px;
    line-height: 1.7em;
    box-shadow: 0 2px 10px 0 #cac9c9;
    text-align: left;
    user-select: none;
    cursor: pointer;
    border-left: 20px solid #044c74;
    /* max-width: fit-content; */
}

/* .main-heading{
    border-bottom: 5px solid #f84f20;
    line-height: 50px;
    font-size: 24px;
    margin-top: 2%;
    font-style: italic;
}

.main-heading span{
    padding: 2px 20px;
    display: inline-block;
    background: #044c74;
    color: #fff;
    
} */

/* .main-heading span::before{
    position: absolute;
    content: "";
    width: 90%;
    height: 100%;
    background: #000;
    z-index: -1;
    transform: rotate(45deg);
} */

.sme-table {
    padding: 1rem;
    align-items: center;
}

.sme-table td {
    border: 1.8px solid #044c74;
    padding-left: 14px;
    padding-right: 14px;
    padding-top: 5px;
    padding-bottom: 5px;
    color: #044c74;
}

.sme-table td button {
    color: #f84f20;
}

.plan-heading {
    display: flex;
}

.plan-heading button {
    /* background-image: linear-gradient(to right, #044c74 0%, #f84f20  120%, #044c74  100%); */
    background-image: linear-gradient(to right, #f84f20 0%, #044c74  120%, #C04848  100%);
    margin-right: 1.5rem;
    margin-top: 0.5rem;
}

.schedule-item {
    display: flex;
    border-style: solid;
    border-color: #044c74;
}

.schedule-item p {
    margin-left: 3rem;
    margin-right: 1rem;
    text-align: center;
}

.assignment-tab {
    margin-top: 0.5rem;
    margin-left: 1rem;
}

.assignment-tab p {
    align-content: center;
    margin-left: 4rem;
    font-size: 20px;
    cursor: pointer;
}

.class-link {
    margin-top: 2rem;
    width: 28%;
    /* margin-left: 32%; */
    display: flex;
    border-style: inset;
    align-self: left;
}

.class-link p {
    margin-left: 0.5rem;
}

.class-link button {
    margin-top: 6px;
    margin-left: 20%;
    width: 20%;
    background-image: linear-gradient(to right, #f84f20 0%, #044c74  120%, #C04848  100%);
    align-items: right;
}

/* new css */
  
.module-div {
    margin: 0 10px 20px 10px;
  }
  
  .pathway-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f0f0f0;
    padding: 10px 10px 10px 9px;
    border-radius: 8px;
  }
  
  .module-title h3 {
    margin: 0;
  }
  
  .module-title span {
    font-weight: bold;
    margin-right: 5px;
    color:#044c74;
  }
  
  .Resume-button-container {
    display: flex;
    align-items: center;
  }
  
  .Resume-button {
    background-image: linear-gradient(to right, #f84f20 0%, #044c74  120%, #C04848  100%);
    color: #fff;
    font-weight: bold;    
    padding: 8px 12px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 150px; 
    margin-top: 1px;
  }

  .Resume-button span{
    margin-top: 2px;
  }
  