.swot-list-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid rgba(108, 106, 106, 0.76);
  border-radius: 4px;
  overflow-y: scroll;
  max-height: 300px;
  padding: 10px;
  padding-right: 0px;
}

.swot-list {
  display: flex;
  flex-direction: column;
  max-width: 386px;
}

.swot-list-item {
  padding: 4px;
}
.swot-list-item-value {
  font-size: 14px;
  margin-bottom: 10px;
  border-radius: 6px;
  font-weight: 500;
  padding: 8px;
}

.swot-title {
  text-align: center;
  font-size: 22px;
  font-weight: 700;
  color: rgba(3, 5, 51, 0.785);
}
