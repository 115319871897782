.sk-student-doubts-table-header,
.sk-student-doubts-table-row {
  display: grid;
  grid-template-columns: 0.5fr 2fr 1fr 1fr 1fr 1fr;
  align-items: center;
}

.sk-student-doubts-table-header > div{
  font-weight: 600;
  color: var(--primary-color);
}

.sk-student-doubts-table-body{
  overflow-y: scroll;
}

.sk-student-doubts-hr-line {
  border: 1px solid #e0e0e0;
  margin: 0 20px 10px 20px;
}

.sk-student-doubts-table-row:hover {
  cursor: pointer;
  background-color: aliceblue;
}

.sk-student-doubts-table-header > div,
.sk-student-doubts-table-row > div {
  padding: 10px;
  margin: 5px 20px;
}


.sk-student-doubts-header,
.sk-student-doubts-table-body,
.sk-student-doubts-table {
  width: 100%; 
}

.sk-student-doubts-table-el,
.sk-student-doubts-table-header-el{
  text-align: center;
}

/* score dashboard css */

.sk-student-doubts-section-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e0e0e0;
}

.sk-student-doubts-section-header-left {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.sk-student-doubts-section-header-title {
  font-size: 18px;
  font-weight: 500;
  color: var(--primary-color);
  margin-left: 10px;
}

.sk-student-doubts-section-header-score {
  font-size: 16px;
  font-weight: 500;
  color: #00AD50;
  margin-left: 10px;
  border: 1px solid #00AD50;
  padding: 5px 10px;
  border-radius: 10px;
  background-color: #E4FFF1;
}

.sk-student-doubts-section-body{
  display: flex;
  flex-direction: column;
  margin: 0 20px;
}

.sk-student-doubts-section-body-part-2{
  height: 50vh;
  overflow-y: scroll;
}

.sk-student-doubts-section-body-part-3{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.sk-student-doubts-section-body-part-3-navigation{
  color: var(--primary-color);
  font-size: 16px;
  font-weight: 500;
  padding: 0 10px;
  text-decoration: underline;
  cursor: pointer;
}


.sk-student-doubts-not-found-section{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 20px;
  font-weight: 500;
  color: #ccc;
}

.sk-student-doubts-interview-loading{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}



.sk-raise-doubt-icon{
  max-height: 17px;
}

.sk-doubt-status-yellow {
  background-color: #FFFAEB;
  color: #B54708;
  padding: 5px 13px;
  border-radius: 10px;
}

.sk-doubt-status-green {
  background-color: #ECFDF3;
  color: #027A48;
  padding: 5px 13px;
  border-radius: 10px;
}

.sk-doubt-status-blue {
  background-color: #cce5ff;
  color: #004085;
  padding: 5px 13px;
  border-radius: 10px;
}

.sk-doubt-action-container{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.sk-doubt-open-chat-button{
  color: #434343;
  font-size: 16px;
  font-weight: 400;
  text-decoration: underline;
  cursor: pointer;
}

.sk-doubt-new-dot{
  background-color: #FF0000;
  margin-right: 8px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.sk-doubt-new-dot-red-empty{
  background-color: transparent;
  margin-right: 5px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}