/* Popup.css */

.custom_course_popup-header {
    color: #ffffff;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .custom_course_popup-content {
    padding: 20px;
    padding-top: 0;
  }
  
  .custom_course_popup-close-button {
    background-color: red;
    color: #ffffff;
    border: none;
    border-radius: 50%;
    padding: 5px 8px;
    cursor: pointer;
    font-size: 14px; 
  }
  
  .custom_course_popup-close-button:hover {
    background-color: #c0392b;
  }
  
  /* Customize the Popup overlay */
  .reactjs-popup-overlay {
    background-color: rgba(0, 0, 0, 0.6);
  }
  
  /* Customize the Popup content */
  .reactjs-popup-content {
    border-radius: 5px;
    max-width: 400px;
    width: 100%;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    overflow: hidden;
    position: relative;
  }
  
  /* Position the close button in the top right corner */
  .custom_course_popup-close-button {
    position: absolute;
    top: -7px;
    right: -7px;
  }
  
  .custom-course-popup-title {
    font-size: 1.5em;
    font-weight: bold;
    display: flex;
    align-items: center;
    color: #233f58;
  }

  .custom-course-popup-title h3 {
    color: #233f58;
    margin: 0;
  }
  
  .custom-course-popup-title-icon {
    margin-right: 10px;
    width: 20px;
  }