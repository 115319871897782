.pre {
    white-space:pre-wrap;
    background:#f7f9fa;
    padding:10px 15px;
    color:#263238;
    line-height:1.6;
    font-size:13px;
    border-radius:3px; 
    margin-top: 0;
    margin-bottom:1em;
    overflow:auto
}

.b strong{
    font-weight:bolder
}

.title {
    font-size:16px;
    color:#212121;
    font-weight:600;
    margin-bottom:10px
}

.hr {
    height:10px;
    border:0;
    box-shadow:0 10px 10px -10px #8c8b8b inset
}