.section-title {
  font-size: 20px;
  padding: 10px 0px;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: #004c73;
}

.section-content {
  border: 2px solid #ddd;
  padding: 1.4rem;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  min-height: 64vh;
  max-height: 64vh;
  overflow: auto;
  overflow-x: hidden;
}

.content-title {
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  color: #434343;
}

.content-title-desc {
  font-size: 16px;
  padding: 8px 0px;
  font-weight: 400;
  line-height: 1.5rem;
  color: #777777;
  /* text-align: justify; */
  margin-bottom: 2%;
}

.template-container {
  display: flex;
  flex-wrap: wrap;
  max-width: max-content;
  justify-content: space-between;
}

.template-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 16px;
  align-items: center;
  width: 20%;
  height: 32%;
  background-color: #fafafa;
  padding: 14px 16px;
  border-radius: 4px;
  box-shadow: #434343 0px 0px 6px 0px;
}

.template-card:hover {
  background-color: #004d732f;
  padding: 14px 16px;
  border: darkblue 1px solid;
  border-radius: 4px;
  cursor: pointer;
}

.template-image {
  width: 100%;
  height: 100%;
  box-shadow: lightgray 0px 0px 0px 1px;
}

.template-name {
  font-size: 16px;
  padding-top: 8px;
  font-weight: 400;
  line-height: 20px;
  color: #434343;
}

.create-resume-container {
  display: flex;
  flex-direction: row;
}

.create-resume-details {
  background-color: #edf9ff;
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  gap: 1vh;
}

.generate-resume-button {
  background-color: #004c73;
  color: white;
  padding: 10px 20px;
  border-radius: 4px;
  text-align: center;
  width: 130px;
}

.generate-resume-button:hover {
  background-color: #09344a;
  cursor: pointer;
}

.generate-resume-button-outline {
  color: #004c73;
  padding: 10px 20px;
  border: #004c73 2px solid;
  border-radius: 4px;
  text-align: center;
  width: 130px;
}

.generate-resume-button-outline:hover {
  background-color: #004d73;
  color: white;
  cursor: pointer;
  border: #004c73 2px solid;
}

.loading-modal {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(67, 67, 67, 0.683);
}

/* .loading-ring {
  position: absolute;
  top: 50%;
  left: 50%;
  color: white;
  z-index: 1;
} */

.dropdown-sections-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 2%;
}

.select-from-dropdown {
    width: 100%;
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.5);
    z-index: 30;
    background-color: white;
}

.action-verbs {
    width: 100%;
}

select {
    margin-top: 2%;
    width: 80%;
    border: lightgray 1px solid;
    border-radius: 5px;
    /* padding: 12px 16px; */
    font-size: 16px;
    color: #777777;
}


/* CustomSelect.css */
.new-custom-select {
  position: relative;
  width: 100%;
  max-width: 100%;
  font-size: 1.15rem;
  color: #000;
  margin-top: 2%;
  box-shadow: none;
}

.new-select-button {
  width: 100%;
  font-size: 1.15rem;
  background-color: white;
  color: darkgray;
  padding: 0.675em 1em;
  border: 1px solid #caced1;
  border-radius: 0.5rem;
  cursor: pointer;
  box-shadow: none;
  
  display: flex;
  justify-content: space-between;
  margin-left: 0;
  align-items: center;
  /* align-items: left; */
  /* left: 0; */
}

.new-select-button:hover {
  background-color: rgb(214, 213, 213);
  color: darkgray;
}

.selected-value {
  text-align: left;
  font-size: medium;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.arrow {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  /* border-top: 6px solid #000; */
  transition: transform ease-in-out 0.3s;
}

.select-from-dropdown {
  position: absolute;
  list-style: none;
  width: 90%;
  /* box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2); */
  background-color: none;
  z-index: 1;
  /* border: 1px solid #caced1; */
  border: none;
  border-radius: 4px;
  padding: 10px;
  margin-top: 10px;
  max-height: 200px;
  overflow-y: auto;
  transition: 0.5s ease;
  
  transform: scaleY(0);
  opacity: 0;
  visibility: hidden;
}

.options-container {
  /* background-color: #004d73; */
  /* box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2); */
  border-radius: 6px;
  padding: 2px 0;
}

.search-input {
  display: flex;
  border: #c4c3c3 1px solid;
  /* box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);   */
  border-radius: 5px;
  padding: 6px 24px;
  width: 100%;
  color: #000;
  font-size: 1.2rem;
}

.select-from-dropdown:focus-within {
  /* box-shadow: 0 10px 25px rgba(98, 98, 99, 0.6); */
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.5);
}

.select-from-dropdown li {
  position: relative;
  cursor: pointer;
  display: flex;
  gap: 1rem;
  align-items: center;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
}

.select-from-dropdown li label {
  padding-left: 1rem;
}

.select-from-dropdown li input {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-items: center;
  height: 100%;
}

.select-from-dropdown li {
  display: flex;
  align-items: center;
  justify-items: center;
  height: 100%;
}

.select-from-dropdown::-webkit-scrollbar {
  width: 4px;
}
.select-from-dropdown::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 25px;
}

.select-from-dropdown::-webkit-scrollbar-thumb {
  background: #000;
  border-radius: 25px;
}

.select-from-dropdown li:hover,
.select-from-dropdown input:checked {
  background-color: #f2f2f2;
}

.select-from-dropdown input:focus {
  background-color: #dfdfdf;
}

.select-from-dropdown input[type="radio"] {
  position: absolute;
  left: 0;
  opacity: 0;
}


.new-custom-select.active .arrow {
  transform: rotate(180deg);
}

.new-custom-select.active .select-from-dropdown {
  opacity: 1;
  visibility: visible;
  transform: scaleY(1);
}


.dropdown-sections-container-action {
  /* display: grid;
  grid-template-columns: repeat(2, 1fr); */
  display: flex;
  flex-direction: row;
  width: 80%;
  gap: 0.4rem;
}

.dropdown-sections-container-bullet {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  gap: 1rem;
}

.select-category {
  /* display: grid; */
  width: 100%;
}

.action-verbs-data {
  width: 100%;
  margin-left: 1rem;
}

.bullet-search-btn {
  display: flex;
  align-items: center;
  margin-top: auto;
  margin-bottom: 2.4rem;
  margin-left: 1rem;
  scale: 1.5;
  width: fit-content;
  height: fit-content;
}

.bullet-search-btn:hover {
  cursor: pointer;
  scale: 1.6;
}

.action-verbs-display-container {
  display: block;
  margin-top: 1.2rem;
  max-width: 50rem;
  height: auto;
  text-align: justify;
  padding: 0.675em 1em;
  border: #c4c3c3 1px solid;
  border-radius: 4px;
}

.category-confirm-btn {
  display: flex;
  padding: 0.6rem;
  height: fit-content;
  margin-top: 3.4rem;
  background-color: #004c73;
  border-radius: 0.4rem;
  color: white;
  text-align: center;
}

.category-confirm-btn:hover {
  background-color: #033954;
  cursor: pointer;
}

.template-resume-final-ui{
  border: 3px solid #DEDEDE;
}


/* resume profile incomplete warning */

.gen-resume-incomplete-resume {
  display: flex;
  flex-direction: column;
  font-weight: 500;
  margin: 20px 20px 20px 0px;
}

.gen-resume-alert-container {
  display: flex;
  gap: 10px;
  color: #585858;
}

.gen-resume-alert-container svg{
  width: 50px;
  padding: 10px 0px;
}

.gen-resume-alert-message {
  border-radius: 16px;
  background-color: #fef3f2;
  flex-grow: 1;
  color: #777777;
  padding: 10px;
}

.gen-resume-alert-text {
  display: inline;
  margin-bottom: 5px;
}

.gen-resume-alert-link {
  font-weight: 800;
  text-decoration: underline;
  color: #585858;
  cursor: pointer;
}

.gen-resume-alert-link:hover {
  text-decoration: underline;
}

.gen-resume-missing-data {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px 10px 0 10px;
}

.gen-resume-missing-data  b{
  color: #585858;
}

.gen-resume-missing-data ul {
  list-style-type: disc;
  list-style-position: inside;
  margin: 0;
  padding: 0;
}

.gen-resume-missing-data ul li {
  display: list-item;
  margin-bottom: 5px;
  color: #777777;
}
