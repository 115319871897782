.authentication {
  padding-top: 8rem;
  padding-bottom: 3rem;
  background: #eef6fc;
  text-align: center;
  padding-left: 5%;
  padding-right: 5%;
  text-align: center;
}

.Buttons {
  padding-top: 1rem;
  padding-bottom: 1rem;
  background: #eef6fc;
  text-align: center;
  padding-left: 25%;
  padding-right: 25%;
  text-align: center;
}

.radio-div{
  display: flex;
  margin-top: -1rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.radio-1{

  flex: 1;
  text-align: center;
  display: flex;
}

.radio-1 p{
  color: #12133f;
  margin-right: 1.5rem;
  font-size: 18px;
  font-weight: bold;
}

.exploring-info-btn{

  padding: 0.2rem 0.7rem;
  border: 1px solid #12133f;
  border-radius: 3px;
  margin-left: 0.4rem;
  cursor: pointer;
  font-weight: 200;
  background: #223792;
  color: white;
  text-decoration: none;
 
}

.mylogin-title{
   margin-top: 0rem;
   /* font-size: 28px;
   color: rgb(48, 48, 119); */
   margin-bottom: 1.5rem;
}

.authentication form {
  border-radius: 8px;
  margin-left: 28%;
  margin-right: 28%;
  padding-left: 1%;
  padding-right: 1%;
  padding-top: 1%;
  padding-bottom: 2%;
  background: rgba(246, 251, 252, 0.7);
}

.form-class1 {
 margin-top: 1rem;
 margin-bottom: 2rem;
  margin-right: 7%;
  margin-left: 5%;
}

.auth-form .form-control {
  width: 100%;
}

.password-input {
  padding: 12px !important;
}

.signin-title{
   margin-top: 2rem;
   margin-bottom: 0rem;
}

.auth-box button {
  width: 12rem;
  align-items: center;
  /* background: linear-gradient(to right, #f84f20 0%, #044c74  120%, #C04848  100%); */
}

.Buttons {
  display: flex;
}

.Buttons button{
  width: 14rem;
  align-items: center;
  background: linear-gradient(to right, #f84f20 0%, #044c74  120%, #C04848  100%);
}


@media (max-width: 500px) {


  .authentication {
    padding-top: 7rem;
    padding-left: 0%;
    padding-right: 0%;
  }
  
  .exploring-info-btn{
  
    padding: 0.2rem 0.7rem;
    border: 1px solid #12133f;
    border-radius: 3px;
    margin-left: 0.4rem;
    cursor: pointer;
    font-weight: 200;
    background: #223792;
    color: white;
    text-decoration: none;
   
  }
  
  .mylogin-title{
     margin-top: 0rem;
     /* font-size: 28px;
     color: rgb(48, 48, 119); */
     margin-bottom: 1.5rem;
  }
  
  .authentication form {
    margin-left: 12%;
    margin-right: 12%;
  }
  
  .form-class1 {
   margin-top: 1rem;
   margin-bottom: 2rem;
    margin-right: 7%;
    margin-left: 5%;
  }
 
}
