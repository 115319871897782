.profile-custom-button{
    background-color: #FA1B00;
    color: white;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    transition: 0.3s;
    &:hover{
        background-color: #f54f3d;
        color: white;
        transition: 0.3s;
    }
}

.profile-custom-button-disabled{
    opacity: 0.5;
    cursor: not-allowed;
}