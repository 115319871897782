.edit_section_popup__content-row {
  display: flex;
  justify-content: space-between;
}

.edit_section_popup__content-row-title {
  flex: 1;
  margin-right: 10px;
}

.edit_section_popup__content-row-value {
  flex: 2;
}

.edit_section_popup__buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.edit_section_popup__buttons button {
  padding: 10px 20px;
  margin-left: 10px;
  cursor: pointer;
}

.edit_section_popup__buttons-cancel {
  background-color: #f84f20;
  color: #fff;
  border: none;
}

.edit_section_popup__buttons-save {
  background-color: #233f58;
  color: #fff;
  border: none;
}

.edit_section_popup__content-row-value select {
  width: 95%;
  height: 1.8rem;
  border: 2px solid rgba(77, 77, 77, 0.5);
  margin: 0px;
}

.edit_section_popup__content-row-value input.input_val_error {
  border: 1px solid red; 
}

.edit_section_popup__content-row-value small.input_val_error-text {
  color: red;
  font-size: 10px;
  margin-top: 5px;
}

.edit_section_popup__loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}