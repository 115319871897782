.popup-box {
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}
   
.box {
    position: relative;
    width: 85%;
    margin: 0 auto;
    height: auto;
    max-height: 70vh;
    margin-top: calc(100vh - 85vh - 20px);
    background: #fff;
    border-radius: 4px;
    padding: 20px;
    border: 1px solid #999;
    overflow: auto;
}

.close-icon {
    content: 'x';
    cursor: pointer;
    position: fixed;
    right: calc(15% - 127px);
    top: calc(100vh - 86vh - 9px);
    background: #c73c3c;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    line-height: 20px;
    text-align: center;
    border: 1px solid #999;
    font-size: 20px;
}

.msepage {
    padding-top: 5rem;
    padding-left: 5rem;
}

.msepage-left {
    padding-left: 0.5rem;
    width: 50%;
    height: 80%;
    background: #eef6fc;
    float: left;
}

.msepage-right {
    width: 45%;
    float: right;
    height: 80%;
}

/* .msepage-right button {
    position: absolute;
    top: 20%;
    color: #044c74;
    width: fit-content;
} */
