.stu-skillbox-nav-header {
  font-size: 14px;
  color: var(--tertiary-color);
  margin-top: -5px;
  margin-bottom: 10px;
}

.stu-skillbox-nav-header span:hover {
  color: var(--primary-color);
  cursor: pointer;
}

.st-cls-section-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e0e0e0;
}

.st-cls-section-header-title {
  font-size: 18px;
  font-weight: 500;
  color: var(--primary-color);
  margin-left: 10px;
}

.interview-questions-not-found-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 20px;
  font-weight: 500;
  color: #ccc;
}

.st-cls-interview-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

/* main css */

.st-cls-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.st-cls-page-nav-menu {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-size: 14px;
  color: var(--tertiary-color);
  margin-top: -5px;
}

.st-cls-page-nav-menu-home:hover {
  color: var(--primary-color);
  cursor: pointer;
}

.st-cls-page-nav-menu-interview-preperation:hover {
  color: var(--primary-color);
  cursor: pointer;
}

.st-cls-header {
  display: flex;
  justify-content: space-between;
  align-items: end;
  margin-bottom: 10px;
  margin-top: 10px;
}

.st-cls-header-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 20px;
  color: var(--primary-color);
  font-weight: bold;
  padding-bottom: 10px;
}

.st-cls-body {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
}

.st-cls-body-last-updated {
  font-size: 16px;
  color: var(--tertiary-color);
}

.st-cls-section {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 5px;
  height: 70vh;
  width: 100%;
  overflow-y: scroll;
}
