.register-page {
    padding-top: 5%;
    background-color: #eef6fc;
  }
  
  .form-input {
    height: 40px;
    box-shadow: none;
    color: #f94400;
    font-size: 14.5px;
  }
  
  .select-item-css {
    width: 70%;
    font: inherit;
    border: 1px solid #ccc;
    background: #f8f8f8;
    border-color: #510077;
    padding: 0.15rem 0.25rem;
  }
  
  .form-label {
    height: 40px;
    box-shadow: none;
    color: #1b0f47;
    font-size: 15px;
    margin-right: 5%;
  }
  
  .register-flex {
    margin-top: 1%;
    display: flex;
    flex-direction: row;
    /* border: solid red 1px; */
    justify-content: center;
    /* align-items: flex-start; */
  }
  
  .register-image-block {
    width: 35%;
    
    /* margin-top: 2%; */
    /* border: solid blue 1px; */
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-top: 8rem;
  }
  
  .register-img {
    /* margin:auto; */
    width: 90%;
    margin-top: 5%;
    margin-bottom: 20%;
  }
  .register-img1 {
    margin: auto;
    width: 60%;
    align-self: flex-end;
    margin-bottom: 20%;
  }
  .register-img2 {
    margin: auto;
    margin-right: 5%;
    width: 50%;
    align-self: flex-end;
  }
  .form-control:focus {
    border-color: #5cb85c;
  }
  .form-control,
  .btn {
    border-radius: 3px;
  }
  .register-form {
    /* width: 450px; */
    width: 50%;
  
    margin: 0 auto;
    padding: 30px 0;
    font-size: 15px;
    margin-right: 5%;
    /* border: solid black 1px; */
  }
  .register-form h2 {
    color: white;
    margin: 0 0 15px;
    position: relative;
    text-align: center;
  }
  /* .register-form h2:before, .register-form h2:after {
      content: "";
      height: 2px;
      width: 30%;
      background: #d4d4d4;
      position: absolute;
      top: 50%;
      z-index: 2;
  }	 */
  .register-form h2:before {
    left: 0;
  }
  .register-form h2:after {
    right: 0;
  }
  .register-form .hint-text {
    color: #999;
    margin-bottom: 30px;
    text-align: center;
  }
  .register-form form {
    color: #999;
    border-radius: 3px;
    margin-bottom: 15px;
    background: linear-gradient(
      to right,
      rgba(3, 75, 113, 0.9),
      rgba(249, 68, 0, 0.9)
    );
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    padding: 30px;
  }
  .register-form .form-group {
    margin-bottom: 0px;
  }
  .register-form input[type="checkbox"] {
    margin-top: 3px;
  }
  .register-form .btn {
    font-size: 16px;
    font-weight: bold;
    min-width: 140px;
    outline: none !important;
  }
  .register-form .row div:first-child {
    padding-right: 10px;
  }
  .register-form .row div:last-child {
    padding-left: 10px;
  }
  .register-form a {
    color: rgb(88, 86, 86);
    text-decoration: underline;
  }
  .register-form a:hover {
    text-decoration: none;
  }
  .register-form form a {
    color: #5cb85c;
    text-decoration: none;
  }
  .register-form form a:hover {
    text-decoration: underline;
  }
  
  .form-register-button button {
    margin-top: 3rem;
    font-weight: bold;
    border: orangered;
    background-color: white;
    color: orangered;
  }
  
  .input-box {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .text-area-div {
    margin-left: 1.4rem;
    width: 92%;
    border-color: #510077;
  }
  
  .box-div {
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    margin-top: -1rem;
    flex-direction: row;
  }
  
  .box-div select {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  
  .btn-box {
    margin-top: -1rem;
    text-align: center;
    border-color: #510077;
    border-radius: 4px;
    margin-bottom: 1rem;
  }
  
  .input-box input {
    width: 100%;
    font: inherit;
    border: 1px solid #ccc;
    border-color: #510077;
    border-radius: 4px;
    background: #f8f8f8;
    padding: 0.15rem 0.25rem;
  }
  
  .college-des-div input {
    margin-top: 1rem;
  }
  
  .college-des-div3 select {
      margin-top: 1rem;
      width: 200%;
    }
  
  .box {
    flex-direction: column;
  }
  
  .college-des-div1 input {
    width: 80%;
    margin-top: 1rem;
  }
  
  .input-icon {
    padding: 12px;
    padding-left: 0%;
    font-size: 2em !important;
    margin-top: 6px;
    margin-bottom: 16px;
    color: rgb(3, 75, 113);
  }
  
  .register1line2 {
    margin-bottom: -2rem;
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .registerline-left {
    width: 48%;
  }
  
  .registerline-left1 {
    width: 48%;
  
    margin-bottom: 2rem;
    margin-right: 2rem;
  }
  
  .registerline-right1 {
    padding-top: 0.4rem;
    width: 48%;
    margin-bottom: 2rem;
  }
  
  .registerline-right {
    width: 48%;
  }
  
  .glass-box h3{
      margin-left: 1rem;
      color: #1b0f47;
      font-weight: bolder;
      font-size: 17px;
  }
  
  .glass-box hr{
      margin-left: 1rem;
      margin-right: 2rem;
  }
  
  .custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
    width: 10%;
    color: black;
  }
  .custom-file-input::before {
    content: "Upload File";
    display: inline-block;
    padding: 6px;
    /* padding-left: 26px; */
    /* padding-right: 26px; */
    font-size: 15px;
    font-weight: 700 !important;
    border: transparent;
    border-radius: 5px;
    border-color: transparent;
    background: linear-gradient(to right, rgba(249, 68, 0), rgba(249, 68, 0));
    color: white;
    cursor: pointer;
    margin-top: 6px;
    margin-bottom: 16px;
  }
  .custom-file-input:hover::before {
    border-color: black;
  }
  .custom-file-input:active::before {
    background: linear-gradient(
      to right,
      rgba(249, 68, 0, 0.5),
      rgba(249, 68, 0, 0.5)
    );
  }
  .glass-box {
    border-radius: 10px;
    border-color: transparent;
    box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
    padding-left: 2%;
    padding-right: 2%;
    padding-top: 1%;
    padding-bottom: 0%;
    background: rgba(246, 251, 252, 0.7);
    backdrop-filter: blur(2rem);
  }
  
  @media (max-width: 768px) {
    .register-form {
      width: 90%;
    }
  }
  