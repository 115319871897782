.sk-interview-permissions-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.sk-interview-permissions-modal {
    background-color: white;
    padding: 24px;
    border-radius: 8px;
    width: 90%;
    max-width: 400px;
    text-align: center;
}

.sk-interview-permissions-modal-title {
    font-size: 20px;
    color: var(--primary-color);
    font-weight: bold;
    margin-bottom: 16px;
}

.sk-interview-permissions-modal-description {
    font-size: 18px;
    font-weight: 400;
}

.sk-interview-permissions-error {
    color: #d32f2f;
    margin: 16px 0;
    padding: 8px;
    background-color: #ffebee;
    border-radius: 4px;
}

.sk-interview-permissions-buttons {
    display: flex;
    justify-content: center;
    gap: 16px;
    margin-top: 24px;
    padding: 0 24px;
}

.sk-interview-permissions-button {
    padding: 8px 24px;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    transition: background-color 0.3s;
    font-family: inherit;
    margin: 0;
}

.sk-interview-permissions-button.primary {
    background-color: #004c73;
    color: white;
}

.sk-interview-permissions-button.primary:hover {
    background-color: #003557;
}

.sk-interview-permissions-button.secondary {
    background-color: #e0e0e0;
    color: #333;
}

.sk-interview-permissions-button.secondary:hover {
    background-color: #d5d5d5;
}
