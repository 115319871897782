.class-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.class-video {
  margin-top: 20px;
}

.class-resources,
.other-class-items {
  margin-top: 20px;
}

.class-resources-list-numbers {
  list-style-type: decimal;
  padding: 0;
  margin-right: 20px;
  color: #555;
  font-weight: bold;

}

.class-resources ul {
  list-style-type: none;
  padding: 0;
}

.class-resources li {
  margin-bottom: 10px;
}

.class-resources-list-text {
  font-weight: 500;
  color: var(--primary-color);
  margin-right: 20px;
}