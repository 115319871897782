.side-drawer {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100;
    height: 100vh;
    width: 70%;
    background: white;
    box-shadow: 0 2px 8px rgba(0,0,0,0.26);
}
.slide-in-left-enter {
    transform: translateX(-100%);
  }
  
  .slide-in-left-enter-active {
    transform: translateX(0);
    opacity: 1;
    transition: all 200ms;
  }
  
  .slide-in-left-exit {
    transform: translateX(0%);
    opacity: 1;
  }
  
  .slide-in-left-exit-active {
    transform: translateX(-100%);
    opacity: 0;
    transition: all 200ms;
  }
  
  